import moment from "moment";
import { useContext, useEffect } from "react";
import { useState } from "react";
import Helmet from "react-helmet";
import PageTransition from "../../../app/animations/page-transition";
import config from "../../../app/config";
import { WalletContext } from "../../../app/contexts/wallet-context";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";
import Countdown from "react-countdown";
import { dateToISO } from "../../../app/utilities/date";
import { tokenFormat } from "../../../app/utilities/format";

//const user = {actor:'tc1b2.wam',permission:'active'};
const GbmRewards = () => {
  const wallet = useContext(WalletContext);
  const { user, walletAccount } = wallet;

  const [global, setGlobal] = useState(null);
  const [currencyStats, setCurrencyStats] = useState(null);
  const [genesis, setGenesis] = useState(null);
  const [voters, setVoters] = useState(null);
  const [nextGenesisClaimDate, setNextGenesisClaimDate] = useState(null);
  const [nextVotersClaimDate, setNextVotersClaimDate] = useState(null);
  const [nextGenesisRewards, setNextGenesisRewards] = useState(0);
  const [nextVotersRewards, setNextVotersRewards] = useState(0);
  /* global BigInt */
  useEffect(() => {
    setNextGenesisClaimDate(
      moment().add(1, "month").format("YYYY-MM-DD HH:mm:ss")
    ); //Disable Genesis
    /*     if (genesis && genesis.rows[0] && genesis.rows[0].last_claim_time) {
      const genesisClaimDate = moment(
        dateToISO(genesis.rows[0].last_claim_time)
      )
        .add(24, "hours")
        .format("YYYY-MM-DD HH:mm:ss");

      if (
        moment(dateToISO(genesis.rows[0].last_claim_time)).unix() >
        moment("2022-06-30T23:59:59.000Z").unix()
      ) {
        setNextGenesisClaimDate(genesisClaimDate);
      } else if (
        genesisClaimDate > moment().utc().format("YYYY-MM-DD HH:mm:ss")
      ) {
        setNextGenesisClaimDate(genesisClaimDate);
      } else {
        setNextGenesisClaimDate(null);
      }

      calculateNextGenesisRewards();
    } */

    if (voters && voters.rows[0] && voters.rows[0].last_claim_time) {
      const votersClaimDate = moment(dateToISO(voters.rows[0].last_claim_time))
        .add(24, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      if (votersClaimDate > moment().utc().format("YYYY-MM-DD HH:mm:ss")) {
        setNextVotersClaimDate(votersClaimDate);
      } else {
        setNextVotersClaimDate(null);
      }
      calculateNextVotersRewards();
    }
  }, [genesis, voters, global, currencyStats, walletAccount]);

  useEffect(() => {
    BlockchainApiManager.getTableRows({
      code: "eosio",
      scope: "eosio",
      table: "global",
      lower_bound: "",
      upper_bound: "",
      limit: 1,
    }).then((response) => {
      setGlobal(response);
    });

    BlockchainApiManager.getCurrencyStats({
      code: "eosio.token",
      symbol: "WAX",
    }).then((response) => {
      setCurrencyStats(response);
    });

    if (user) {
      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: user.actor, //user.actor
        table: "genesis",
        lower_bound: "",
        upper_bound: "",
        limit: 1,
      }).then((response) => {
        setGenesis(response);
      });

      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: "eosio",
        table: "voters",
        lower_bound: user.actor, //user.actor
        upper_bound: user.actor, //user.actor
        limit: 1,
      }).then((response) => {
        setVoters(response);
      });
    }
  }, [user]);

  const calculateNextGenesisRewards = () => {
    var rewardsPerDay = parseFloat(genesis.rows[0].balance) / 1096;
    var currentDate = moment().utc();
    var lastClaimDate = moment(dateToISO(genesis.rows[0].last_claim_time));

    var duration = moment.duration(currentDate.diff(lastClaimDate));

    var nextGenesisRewards = (rewardsPerDay / 86400) * duration.asSeconds();
    setNextGenesisRewards(nextGenesisRewards);
  };

  // GMT Converter
  let gmt_conversion = 8 * 60 * 60 * 1000;
  function stringToBigInt(value) {
    return BigInt(value.toString().split(".")[0]);
  }

  function dateToBigInt(value) {
    const date = new Date(value).getTime() - gmt_conversion;
    return BigInt(date * 1000);
  }

  function fixVoterInfo(voter_info) {
    voter_info.unpaid_voteshare = stringToBigInt(voter_info.unpaid_voteshare);
    if (typeof voter_info.unpaid_voteshare_last_updated !== "bigint") {
      voter_info.unpaid_voteshare_last_updated = dateToBigInt(
        voter_info.unpaid_voteshare_last_updated
      );
    }
    voter_info.unpaid_voteshare_change_rate = stringToBigInt(
      voter_info.unpaid_voteshare_change_rate
    );
    return voter_info;
  }
  function fixGState(_gstate) {
    _gstate.total_unpaid_voteshare = stringToBigInt(
      _gstate.total_unpaid_voteshare
    );
    _gstate.total_voteshare_change_rate = stringToBigInt(
      _gstate.total_voteshare_change_rate
    );
    _gstate.voters_bucket = stringToBigInt(_gstate.voters_bucket);
    _gstate.total_unpaid_voteshare_last_updated = dateToBigInt(
      _gstate.total_unpaid_voteshare_last_updated
    );
    _gstate.last_pervote_bucket_fill = dateToBigInt(
      _gstate.last_pervote_bucket_fill
    );
    return _gstate;
  }

  const calculateNextVotersRewards = () => {
    /*    var nextVotersRewards = global
      ? global.rows[0].voters_bucket / Math.pow(10, config.token_precision)
      : 0;
    setNextVotersRewards(nextVotersRewards); */

    let voter_info =
      walletAccount && walletAccount.account
        ? {...walletAccount.account.voter_info}
        : null;
    let _gstate = global ? {...global.rows[0]} : null;
    let token_supply = currencyStats
      ? parseFloat(currencyStats.WAX.supply)
      : null;

    if (!voter_info || !_gstate || !token_supply) return;

    let timezoneOffset = new Date().getTimezoneOffset() / 60;
    let ct = BigInt(
      (new Date().getTime() + timezoneOffset * 60 * 60 * 1000) * 1000 -
        gmt_conversion * 1000
    );
    _gstate = fixGState(_gstate);
    voter_info = fixVoterInfo(voter_info);
    const oneE6 = BigInt(Math.pow(10, 6));
    const WAX_DECIMAL_PLACES = Math.pow(10, 8);
    let multiplierSmallInt = Math.pow(10, 12);
    let multiplier = BigInt(multiplierSmallInt);
    // fill_buckets...
    const continuous_rate = 0.04879;
    const useconds_per_year = BigInt(365 * 24 * 60 * 60 * 1000000);
    const usecs_since_last_fill = ct - _gstate.last_pervote_bucket_fill;
    const new_tokens = Math.floor(
      (continuous_rate *
        token_supply *
        parseFloat((usecs_since_last_fill * multiplier) / useconds_per_year)) /
        multiplierSmallInt
    );

    const to_voters =
      (BigInt(new_tokens) * BigInt(WAX_DECIMAL_PLACES)) / BigInt(6);
    _gstate.voters_bucket += to_voters;
    _gstate.total_unpaid_voteshare +=
      (_gstate.total_voteshare_change_rate *
        (ct - _gstate.total_unpaid_voteshare_last_updated)) /
      oneE6;
    let unpaid_voteshare =
      voter_info.unpaid_voteshare +
      (voter_info.unpaid_voteshare_change_rate *
        (ct - voter_info.unpaid_voteshare_last_updated)) /
        oneE6;
    let reward = 0;
    try {
      reward =
        (_gstate.voters_bucket *
          ((multiplier * unpaid_voteshare) / _gstate.total_unpaid_voteshare)) /
        multiplier;
    } catch (e) {
      console.error("calculateRewards: " + e.toString());
    }
    if (reward > _gstate.voters_bucket) {
      reward = _gstate.voters_bucket;
    }

    reward = parseFloat(reward) / WAX_DECIMAL_PLACES;

    setNextVotersRewards(reward);
  };

  const claim = (type) => {
    var walletTransaction = {};
    walletTransaction.type = "claim";
    walletTransaction.account = {
      account: null,
      permission: "active",
    };
    walletTransaction.actions = [];

    if (type === "genesis" || type === "both") {
      walletTransaction.actions.push({
        account: "eosio",
        name: "claimgenesis",
        data: {
          claimer: user.actor,
        },
      });
    }

    if (type === "voter" || type === "both") {
      walletTransaction.actions.push({
        account: "eosio",
        name: "claimgbmvote",
        data: {
          owner: user.actor,
        },
      });
    }

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <Helmet>
        <title>GbmRewards | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="form_box active">
          <form className="forms_type active">
            <h2>Genesis and voting rewards</h2>
            {!user ? (
              <p>
                Please login to claim genesis (GBM) and voting rewards. Genesis
                rewards will be liquid and voting rewards will be staked
                automatically.
              </p>
            ) : (
              <>
                {genesis && genesis.rows.length ? (
                  <>
                    <p>
                      <strong>Genesis Balance:</strong>&nbsp;
                      {genesis.rows[0].balance}
                    </p>
                    <br />
                    <p>
                      <strong>Last Genesis Claim:</strong>&nbsp;
                      {moment(genesis.rows[0].last_claim_time).format(
                        "MMM DD YYYY, HH:mm:ss"
                      )}
                    </p>
                    <p>
                      <strong>Next Genesis Claim:</strong>&nbsp;
                      {/* {nextGenesisClaimDate
                        ? moment(nextGenesisClaimDate).format(
                            "MMM DD YYYY, HH:mm:ss"
                          )
                        : "Now"} */}
                      {/*                       {nextGenesisClaimDate ? (
                        <Countdown
                          date={
                            moment(nextGenesisClaimDate).utc().unix() * 1000
                          }
                          renderer={({
                            hours,
                            minutes,
                            seconds,
                            completed,
                          }) => {
                            if (completed) {
                              // Render a completed state
                              return "Now";
                            } else {
                              calculateNextGenesisRewards();

                              // Render a countdown
                              return (
                                <span>
                                  {hours} Hours {minutes} Minutes {seconds}{" "}
                                  Seconds
                                </span>
                              );
                            }
                          }}
                        />
                      ) : (
                        "Now"
                      )} */}
                      Not available
                    </p>
                    <p>
                      <strong>Next Genesis Rewards:</strong>&nbsp;
                      {/* {tokenFormat(
                        nextGenesisRewards,
                        config.token_precision
                      )}{" "}
                      WAX */}
                      None
                    </p>
                  </>
                ) : (
                  <p>Not a genesis account or has 0 balance.</p>
                )}

                <br />
                {voters && voters.rows.length ? (
                  <>
                    <p>
                      <strong>Last Voter Claim:</strong>&nbsp;
                      {moment(
                        voters &&
                          voters.rows[0] &&
                          voters.rows[0].last_claim_time
                      ).format("MMM DD YYYY, HH:mm:ss")}
                    </p>
                    <p>
                      <strong>Next Voter Claim:</strong>&nbsp;
                      {/* {nextVotersClaimDate
                    ? moment(nextVotersClaimDate).format(
                        "MMM DD YYYY, HH:mm:ss"
                      )
                    : "Now"} */}
                      {nextVotersClaimDate ? (
                        <Countdown
                          date={moment(nextVotersClaimDate).utc().unix() * 1000}
                          renderer={({
                            hours,
                            minutes,
                            seconds,
                            completed,
                          }) => {
                            if (completed) {
                              // Render a completed state
                              return "Now";
                            } else {
                              // Render a countdown
                              return (
                                <span>
                                  {hours} Hours {minutes} Minutes {seconds}{" "}
                                  Seconds
                                </span>
                              );
                            }
                          }}
                        />
                      ) : (
                        "Now"
                      )}
                    </p>
                    <p>
                      <strong>Next Voter Rewards:</strong>&nbsp;{" "}
                      {tokenFormat(nextVotersRewards)} WAX
                    </p>
                  </>
                ) : (
                  <p>Not voting for BPs or a proxy.</p>
                )}

                <br />
                <div className="field_row1">
                  {genesis && genesis.rows.length ? (
                    <>
                      <input
                        type="button"
                        value="Claim Genesis"
                        style={{
                          background: !nextGenesisClaimDate
                            ? "#7D70F6"
                            : "#404047",
                        }}
                        onClick={() => {
                          if (nextGenesisClaimDate) return;
                          claim("genesis");
                        }}
                      />
                    </>
                  ) : null}
                  &nbsp;
                  {voters && voters.rows.length ? (
                    <>
                      <input
                        type="button"
                        value="Claim Vote"
                        style={{
                          background: !nextVotersClaimDate
                            ? "#7D70F6"
                            : "#404047",
                        }}
                        onClick={() => {
                          if (nextVotersClaimDate) return;
                          claim("voter");
                        }}
                      />
                    </>
                  ) : null}
                  &nbsp;
                  {genesis &&
                  genesis.rows.length &&
                  voters &&
                  voters.rows.length ? (
                    <>
                      <input
                        type="button"
                        value="Claim Both"
                        style={{
                          background:
                            !nextGenesisClaimDate && !nextVotersClaimDate
                              ? "#7D70F6"
                              : "#404047",
                        }}
                        onClick={() => {
                          if (nextGenesisClaimDate && nextVotersClaimDate)
                            return;
                          claim("both");
                        }}
                      />
                    </>
                  ) : null}
                </div>
              </>
            )}
          </form>
        </div>
      </PageTransition>
    </>
  );
};

export default GbmRewards;
