import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import BlockchainApiManager from "../../app/services/blockchainApiManager";
import ClaimBids from "./components/claimbids";
import CurrentBids from "./components/currentbids";
import { tokenInputFormat } from "../../app/utilities/format";
import config from "../../app/config";
import { useContext } from "react";
import { WalletContext } from "../../app/contexts/wallet-context";
import Skeleton from "react-loading-skeleton";

const Namebids = () => {
  const [activeTab, setActiveTab] = useState("currentBids");
  const [currentBids, setCurrentBids] = useState(null);
  const [claimBids, setClaimBids] = useState(null);

  const {
    isLoading,
    isRefetching,
    error,
    data: nameBids,
    refetch,
  } = useQuery(
    [`nameBids`],
    () => {
      return new Promise(async (resolve, reject) => {
        let more = false;
        let lower_bound = "";
        let results = { rows: [] };
        do {
          const response = await BlockchainApiManager.getTableRows({
            code: "eosio",
            scope: "eosio",
            table: "namebids",
            limit: -1,
            lower_bound: lower_bound,
          });

          for (let row of response.rows) {
            results.rows.push(row);
          }

          more = response && response.more ? true : false;
          lower_bound = response && response.next_key ? response.next_key : "";
        } while (more);

        resolve(results);
      });
    },
    {}
  );

  useEffect(() => {
    if (!nameBids) return;

    let currentBidsRows = [...nameBids.rows];
    currentBidsRows = currentBidsRows.filter((row) => row.high_bid >= 0);
    currentBidsRows.sort((rowA, rowB) => rowB.high_bid - rowA.high_bid);

    setCurrentBids(currentBidsRows);

    let claimBidsRows = [...nameBids.rows];
    claimBidsRows = claimBidsRows.filter((row) => row.high_bid < 0);
    claimBidsRows.sort(
      (rowA, rowB) =>
        moment(rowB.last_bid_time).unix() - moment(rowA.last_bid_time).unix()
    );

    setClaimBids(claimBidsRows);
  }, [nameBids]);

  const wallet = useContext(WalletContext);
  const { user } = wallet;
  const [formData, setFormData] = useState({
    bidder: "",
    newname: "",
    bid: 0,
  });

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      bidder: user ? user.actor : "",
    }));
  }, [user]);

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "bidname";
    walletTransaction.account = {
      account: null,
      permission: "active",
    };
    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "bidname",
      data: {
        bidder: formData.bidder,
        newname: formData.newname,
        bid: `${tokenInputFormat(formData.bid, formData.decimals)} ${
          config.token_symbol
        }`,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  if (error) {
    return (
      <>
        <p>Error fetching</p>
      </>
    );
  }

  // if (isLoading) {
  //   return (
  //     <>
  //       <p>Loading...</p>
  //     </>
  //   );
  // }

  return (
    <>
      <div className="cont_sec tab_sec block_detail">
        <h2>
          Name Bids
          {isRefetching ? (
            <Skeleton width={50} style={{ float: "right" }} />
          ) : (
            <a
              data-tip="Refresh Bids"
              href="#"
              className="refresh_bids"
              onClick={(e) => {
                e.preventDefault();
                refetch();
              }}
            >
              <i className="fa fa-refresh" aria-hidden="true" /> Refresh
            </a>
          )}
        </h2>

        <ul className="tabbbing">
          <li className={`${activeTab === "currentBids" ? "active" : ""}`}>
            <a href="#tab1" onClick={() => setActiveTab("currentBids")}>
              Current Bids
            </a>
          </li>
          <li className={`${activeTab === "claimBids" ? "active" : ""}`}>
            <a href="#tab2" onClick={() => setActiveTab("claimBids")}>
              Need to be Claimed
            </a>
          </li>
        </ul>
        <div
          className={`tab no_scroll ${
            activeTab === "currentBids" ? "active" : null
          }`}
          id="currentBids"
        >
          {/* <p>
            Please use the{" "}
            <Link
              to={`/wallet/bidnames`}
              style={{ textDecoration: "underline" }}
            >
              Name Bids wallet feature{" "}
            </Link>
            to bid on a new name
          </p>
          <br />
          <br /> */}

          <div className="mid_sec name_bids">
            <div className="form_sec">
              {/*FormBox 4*/}
              <div className="form_box permission_form active">
                <form className="forms_type active">
                  <div className="field_row">
                    <div className="field_row_inner">
                      <div className="field_col">
                        <div className="field_row no-space">
                          <div className="field_col">
                            <label>Your Account Name</label>
                            <input
                              type="text"
                              placeholder="Bidder - Login to wallet"
                              disabled
                              value={formData.bidder}
                            />
                          </div>
                          <div className="field_col">
                            <label>Name to Bid On</label>
                            <input
                              type="text"
                              placeholder="Name to Buy"
                              value={formData.newname}
                              onChange={(event) =>
                                setFormData({
                                  ...formData,
                                  newname: event.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="field_col">
                        <div className="field_row no-space">
                          <div className="field_col">
                            <label>
                              Amount to Bid (in {config.token_symbol})
                            </label>
                            <input
                              type="number"
                              placeholder="e.g. 10000"
                              value={formData.bid}
                              min={0}
                              onChange={(event) =>
                                setFormData({
                                  ...formData,
                                  bid: event.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="field_col">
                            <label>&nbsp;</label>
                            <input
                              type="button"
                              className
                              defaultValue="Bid"
                              onClick={handleClick}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <CurrentBids currentBids={currentBids} />
        </div>
        <div
          className={`tab ${activeTab === "claimBids" ? "active" : null}`}
          id="claimBids"
        >
          <ClaimBids claimBids={claimBids} />
        </div>
      </div>
    </>
  );
};

export default Namebids;
