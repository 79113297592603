import { useState } from "react";
import Skeleton from "react-loading-skeleton";

const ImageLoader = ({ src, className,  style, alt }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded ? <Skeleton style={{height:"200px",width:"200px", ...style }}/> : null}
      <img
        style={{ ...style, display: !loaded ? "none" : "" }}
        src={src}
        className={className}
        onLoad={() => setLoaded(true)}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/no-image.png";
        }}
        alt={alt}
      />
    </>
  );
};

export default ImageLoader;
