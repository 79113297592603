import Skeleton from "react-loading-skeleton";
import { numberFormat } from "../../../../app/utilities/format";

const NftsCreated = ({statistics}) => {  

  return (
    <>
      <div className="chart_box">
        <div className="details">
          <span className="type">NFTs created</span>
          <span className="value">
          {statistics && statistics.atomicassets ? (
              numberFormat(statistics.atomicassets.assets_count)
            ) : (
              <Skeleton />
            )}
          </span>
         
        </div>
      </div>
    </>
  );
};

export default NftsCreated;
