import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PageTransition from "../../app/animations/page-transition";
import BlockchainApiManager from "../../app/services/blockchainApiManager";
import {
  bytesFormat,
  currencyFormat,
  microsecondsFormat,
  tokenFormat,
} from "../../app/utilities/format";
import NftAssets from "./components/nft-assets";
import Transactions from "./components/transactions";
import { calculateBreakDown } from "../../app/utilities/account";
import Helmet from "react-helmet";
import { createBrowserHistory } from "history";
import ResourceUtilization from "./components/resource-utilization";
import { WalletContext } from "../../app/contexts/wallet-context";
import Tokens from "./components/tokens";
import ContractActions from "./components/contract-actions";
import ContractTables from "./components/contract-tables";
import ContractAbi from "./components/contract-abi";
import Keys from "./components/keys";
import Staked from "./components/staked";
import DownloadTransfers from "./components/download-transfers";
import config from "../../app/config";
import Votes from "./components/votes";
import LocalnetManager from "../../app/services/localnetManager";

const Account = () => {
  let params = useParams();
  const location = useLocation();
  const history = createBrowserHistory();
  let navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(null);
  const wallet = useContext(WalletContext);
  const { user, tokenPrice, ramPrice } = wallet;

  useEffect(() => {
    params.account = params.account?.toLowerCase();

    if (!params.account && !user) {
      wallet.showWalletConnectModal();
    } else if (!params.account && user) {
      navigate(`/account/${user.actor}`);
    }
  }, [params, user]);

  useEffect(() => {
    const hash = location.hash.replace("#", "") || "transactions";
    let tab;
    switch (hash) {
      case "staked-from":
      case "staked-to":
        tab = "staked";
        break;
      default:
        tab = hash;
        break;
    }
    setActiveTab(tab);
  }, [location]);

  const {
    isLoading,
    error,
    data: account,
  } = useQuery(
    [`account`, params.account],
    () => BlockchainApiManager.getAccount(params.account),
    {}
  );

  const { data: delband } = useQuery(
    [`delband`, params.account],
    () =>
      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: params.account,
        table: "delband",
        limit: -1,
      }),
    {}
  );

  const { data: userres } = useQuery(
    [`userres`, params.account],
    () =>
      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: params.account,
        table: "userres",
        limit: -1,
      }),
    {}
  );

  const { data: accountAbi } = useQuery(
    [`accountAbi`, params.account],
    () => BlockchainApiManager.getAbi(params.account),
    {}
  );

  const { data: accInfo } = useQuery(
    [`accInfo`, params.account],
    () =>
      config.network_type === "localnet"
        ? null
        : BlockchainApiManager.getAccInfo(params.account),
    {}
  );

  const { data: balances } = useQuery(
    [`balances`, params.account],
    () =>
      config.network_type === "localnet"
        ? LocalnetManager.getBalances(params.account)
        : BlockchainApiManager.getBalances(params.account),
    {}
  );

  const { data: creator } = useQuery(
    [`creator`, params.account],
    () =>
      config.network_type === "localnet"
        ? null
        : BlockchainApiManager.getV2Creator(params.account),
    {}
  );

  const breakdown = calculateBreakDown({ account, delband, userres });

  if (!params.account) {
    return (
      <>
        <div className="cont_sec acc_sec">
          <p>Please login to view account.</p>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className="acc_sec_wide">
          <div className="cont_sec acc_sec">
            <h2>Account Not Found</h2>
            <p>Cannot find Account {params.account}</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{params.account} | Account - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="acc_sec_wide">
          <div className="cont_sec acc_sec">
            <h2>{account ? account.account_name : <Skeleton />}</h2>
            {/* <p>Related to your holdings on the WAX blockchain</p> */}
            {creator ? (
              <p>
                Created {moment.utc(creator.timestamp).fromNow()}&nbsp;
                {creator.creator && creator.creator !== "__self__" ? (
                  <>
                    by&nbsp;
                    <Link
                      to={`/account/${creator.creator}`}
                      style={{ color: "#7D70F6" }}
                    >
                      {creator.creator}
                    </Link>
                  </>
                ) : null}
              </p>
            ) : null}

            <div className="util_box">
              <div className="util_block">
                <div className="balance_box">
                  <ul>
                    <li>
                      <span className="asset_img">
                        <img src="/common/images/asset1.png" alt="asset1" />
                      </span>
                      <span className="name">
                        {account ? (
                          `${tokenFormat(breakdown.totalBalance)} ${
                            config.token_symbol
                          }`
                        ) : (
                          <Skeleton />
                        )}
                        <span className="sub_text">
                          {account && tokenPrice >= 0 ? (
                            `($${currencyFormat(
                              breakdown.totalBalance * tokenPrice
                            )})`
                          ) : (
                            <Skeleton />
                          )}
                          <br />
                          Total {config.token_symbol} Balance
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="sub_text">
                        {account ? (
                          `${tokenFormat(breakdown.liquidBalance)} ${
                            config.token_symbol
                          }`
                        ) : (
                          <Skeleton />
                        )}
                        <br />
                        Available {config.token_symbol} Balance
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="performance_box">
                  <ul className="performance">
                    {/* <li>
                      eosio<span>Token</span>
                    </li> */}
                    <li>
                      {account ? (
                        tokenFormat(
                          Math.max(
                            0,
                            (account.ram_quota - account.ram_usage) * ramPrice
                          )
                        )
                      ) : (
                        <Skeleton />
                      )}{" "}
                      {config.token_symbol}
                      <span>RAM Available</span>
                    </li>
                    <li>
                      {account ? (
                        tokenFormat(
                          breakdown.cpuStakedSelf + breakdown.cpuStakedToOthers
                        )
                      ) : (
                        <Skeleton />
                      )}{" "}
                      {config.token_symbol}
                      <span>CPU Staked</span>
                    </li>
                    <li>
                      {account ? (
                        tokenFormat(
                          breakdown.netStakedSelf + breakdown.netStakedToOthers
                        )
                      ) : (
                        <Skeleton />
                      )}{" "}
                      {config.token_symbol}
                      <span>NET Staked</span>
                    </li>
                    <li>
                      {account ? (
                        `${tokenFormat(breakdown.unstaking, 4)} ${
                          config.token_symbol
                        }`
                      ) : (
                        <Skeleton />
                      )}
                      <span>Refunding</span>
                    </li>
                    <li>
                      {account ? (
                        `${breakdown.stakedByOthers.toLocaleString()} ${
                          config.token_symbol
                        }`
                      ) : (
                        <Skeleton />
                      )}
                      <span>Staked by others</span>
                    </li>
                  </ul>
                </div>
                {account ? (
                  <ResourceUtilization account={account} />
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          </div>
          <div className="widget_box">
            <div className="widget">
              <div className="widget_inner">
                <span className="type">STAKED {config.token_symbol}</span>
                <h3>
                  {account ? (
                    `${tokenFormat(breakdown.staked, 4)} ${config.token_symbol}`
                  ) : (
                    <Skeleton />
                  )}
                </h3>
                <p>
                  By staking additional {config.token_symbol}, you will have
                  more CPU & NET available for your transactions.
                </p>
                <Link to="/wallet/resources" className="link_out">
                  VISIT STAKING
                </Link>
              </div>
            </div>
            {config.network_type === "mainnet" ||
            config.network_type === "testnet" ? (
              <>
                <DownloadTransfers account_name={params.account} />
              </>
            ) : null}

            <div className="widget no-border">
              <a href={`https://www.bcbrawlers.com`} target="_blank">
                <figure
                  style={{
                    background:
                      "url(/common/images/brawlers-promo.jpg) no-repeat center center",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="cont_sec tab_sec">
          <h2>Recent Activity</h2>
          <ul className="tabbbing">
            <li
              className={`${activeTab === "transactions" ? "active" : ""}`}
              onClick={() => {
                history.push({ hash: "transactions" });
                setActiveTab("transactions");
              }}
            >
              <a href="#transactions">Transactions</a>
            </li>
            {config.network_type === "mainnet" ? (
              <li
                className={`${activeTab === "nft-assets" ? "active" : ""}`}
                onClick={() => {
                  history.push({ hash: "nft-assets" });
                  setActiveTab("nft-assets");
                }}
              >
                <a href="#nft-assets">NFT Assets</a>
              </li>
            ) : null}
            <li
              className={`${activeTab === "tokens" ? "active" : ""}`}
              onClick={() => {
                history.push({ hash: "tokens" });
                setActiveTab("tokens");
              }}
            >
              <a href="#tokens">Tokens</a>
            </li>
            <li
              className={`${activeTab === "votes" ? "active" : ""}`}
              onClick={() => {
                history.push({ hash: "votes" });
                setActiveTab("votes");
              }}
            >
              <a href="#votes">Votes</a>
            </li>
            <li
              className={`${activeTab === "keys" ? "active" : ""}`}
              onClick={() => {
                history.push({ hash: "keys" });
                setActiveTab("keys");
              }}
            >
              <a href="#keys">Keys</a>
            </li>
            <li
              className={`${activeTab === "staked" ? "active" : ""}`}
              onClick={() => {
                history.push({ hash: "staked-from" });
                setActiveTab("staked");
              }}
            >
              <a href="#staked-to">Staked</a>
            </li>

            {accountAbi && accountAbi.abi ? (
              <>
                <li
                  className={`${
                    activeTab === "contract-tables" ? "active" : ""
                  }`}
                  onClick={() => {
                    history.push({ hash: "contract-tables" });
                    setActiveTab("contract-tables");
                  }}
                >
                  <a href="#contract-tables">Contract Tables</a>
                </li>
                <li
                  className={`${
                    activeTab === "contract-actions" ? "active" : ""
                  }`}
                  onClick={() => {
                    history.push({ hash: "contract-actions" });
                    setActiveTab("contract-actions");
                  }}
                >
                  <a href="#contract-actions">Contract Actions</a>
                </li>

                <li
                  className={`${activeTab === "contract-abi" ? "active" : ""}`}
                  onClick={() => {
                    history.push({ hash: "contract-abi" });
                    setActiveTab("contract-abi");
                  }}
                >
                  <a href="#contract-abi">Contract ABI</a>
                </li>
              </>
            ) : null}
          </ul>
          <div
            className={`tab transactions no_scroll  ${
              activeTab === "transactions" ? "active" : null
            }`}
            //id="transactions"
          >
            {activeTab === "transactions" ? (
              <Transactions balances={balances} />
            ) : null}
          </div>
          <div
            className={`tab nft-assets ${
              activeTab === "nft-assets" ? "active" : null
            }`}
            //id="nft-assets"
          >
            {activeTab === "nft-assets" ? <NftAssets /> : null}
          </div>
          <div
            className={`tab tokens ${activeTab === "tokens" ? "active" : null}`}
            //id="tokens"
          >
            {activeTab === "tokens" ? <Tokens balances={balances} /> : null}
          </div>
          <div
            className={`tab votes ${activeTab === "votes" ? "active" : null}`}
            //id="votes"
          >
            {activeTab === "votes" ? (
              <Votes account={account} accInfo={accInfo} />
            ) : null}
          </div>
          <div
            className={`tab listing keys ${
              activeTab === "keys" ? "active" : null
            }`}
            //id="keys"
            style={{ color: "#fff" }}
          >
            {activeTab === "keys" ? (
              <Keys account={account} accInfo={accInfo} />
            ) : null}
          </div>
          <div
            className={`tab staked ${activeTab === "staked" ? "active" : null}`}
            //id="staked"
          >
            {activeTab === "staked" ? (
              <Staked account={account} accInfo={accInfo} />
            ) : null}
          </div>
          {accountAbi && accountAbi.abi ? (
            <>
              <div
                className={`tab contract-tables ${
                  activeTab === "contract-tables" ? "active" : null
                }`}
                //id="contract-tables"
              >
                {activeTab === "contract-tables" ? (
                  <ContractTables accountAbi={accountAbi} />
                ) : null}
              </div>
              <div
                className={`tab contract-actions ${
                  activeTab === "contract-actions" ? "active" : null
                }`}
                //id="contract-actions"
              >
                {activeTab === "contract-actions" ? (
                  <ContractActions accountAbi={accountAbi} />
                ) : null}
              </div>

              <div
                className={`tab contract-abi ${
                  activeTab === "contract-abi" ? "active" : null
                }`}
                //id="contract-abi"
              >
                {activeTab === "contract-abi" ? (
                  <ContractAbi accountAbi={accountAbi} />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </PageTransition>
    </>
  );
};

export default Account;
