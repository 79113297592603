import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import BlockchainApiManager from "../../app/services/blockchainApiManager";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";

const Key = () => {
  let params = useParams();

  const {
    isLoading,
    error,
    data: keyAccounts,
  } = useQuery(
    [`key_accounts`, params.key],
    () => BlockchainApiManager.getKeyAccounts(params.key),
    { suspense: false }
  );

  return (
    <>
      <Helmet>
        <title>Key | WAX Explorer</title>
      </Helmet>

      <div className="block_detail_sec">
        <div className="cont_sec tab_sec ">
          <h2>WAX Accounts</h2>
          <p>Key: {params.key}</p>

          <table>
            <tbody>
              {isLoading ? <Skeleton /> : null}
              {keyAccounts && keyAccounts.account_names.length === 0 ? (
                <tr>
                  <td>No Accounts found</td>
                </tr>
              ) : null}
              {keyAccounts &&
                keyAccounts.account_names.map((account_name, index) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/account/${account_name}`}>
                        {account_name}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Key;
