import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import PageTransition from "../../../../app/animations/page-transition";
import { WalletContext } from "../../../../app/contexts/wallet-context";

const CancelDeferred = () => {
  const wallet = useContext(WalletContext);
  const { user, walletAccount } = wallet;
  const { account } = walletAccount;

  const [formData, setFormData] = useState({
    canceling_auth: {
      actor: "eosio",
      permission: "active",
    },
    trx_id: "",
  });

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      canceling_auth: {
        actor: user ? user.actor : "",
        permission: user ? user.permission : "",
      },
    }));
  }, [user]);

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "canceldelay";

    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "canceldelay",
      data: {
        canceling_auth: formData.canceling_auth,
        trx_id: formData.trx_id,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <Helmet>
        <title>Cancel Deferred | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <form id="cancel-deferred" className="forms_type active">
          <div className="field_row1">
            <label>
              Transaction ID:{" "}
              <span data-tip="The ID of the deferred transaction to cancel.">
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
            </label>
            <input
              type="text"              
              placeholder="Transaction ID"
              onChange={(event) =>
                setFormData({ ...formData, trx_id: event.target.value })
              }
            />
          </div>
          <div className="field_row1">
            <input
              type="button"
              defaultValue="Cancel Transaction"
              onClick={() => handleClick()}
            />
          </div>
        </form>
      </PageTransition>
    </>
  );
};

export default CancelDeferred;
