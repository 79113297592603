import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import Helmet from "react-helmet";
import PageTransition from "../../../app/animations/page-transition";
import config from "../../../app/config";
import { WalletContext } from "../../../app/contexts/wallet-context";
import { tokenFormat, tokenInputFormat } from "../../../app/utilities/format";

const Bidnames = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;
  const [formData, setFormData] = useState({
    bidder: "",
    newname: "",
    bid: 0,
  });

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      bidder: user ? user.actor : "",
    }));
  }, [user]);

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "bidname";
    walletTransaction.account = {
      account: null,
      permission: "active",
    };
    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "bidname",
      data: {
        bidder: formData.bidder,
        newname: formData.newname,
        bid: `${tokenInputFormat(formData.bid, formData.decimals)} ${
          config.token_symbol
        }`,
      },
    });
    

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <Helmet>
        <title>Name Bids | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="form_box active">
          <h2>Name Bids</h2>
          <form>
            <div className="field_row1">
              <label>Name to Bid On</label>
              <input
                type="text"
                value={formData.newname}
                onChange={(event) =>
                  setFormData({ ...formData, newname: event.target.value })
                }
              />
            </div>
            <div className="field_row1">
              <label>Amount to Bid (in {config.token_symbol})</label>
              <input
                type="number"
                value={formData.bid}
                min={0}
                onChange={(event) =>
                  setFormData({ ...formData, bid: event.target.value })
                }
              />
            </div>
            <div className="field_row1">
              <input type="button" value="Bid" onClick={handleClick} />
            </div>
          </form>
        </div>
      </PageTransition>
    </>
  );
};

export default Bidnames;
