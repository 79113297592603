import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import FetchManager from "../../app/services/fetchManager";
import { currencyFormat, tokenFormat } from "../../app/utilities/format";
import config from "../../app/config";
import { WalletContext } from "../../app/contexts/wallet-context";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import moment from "moment";
import InflationChart from "./components/inflation_chart";
import AvgInflationChart from "./components/avg_inflation_chart";

const Inflation = () => {
  const wallet = useContext(WalletContext);
  const { tokenPrice } = wallet;

  const {data: inflationData } = useQuery(
    [`inflationData`],
    () => {
      return new Promise(async (resolve, reject) => {
        const results = await FetchManager.get(
          "https://inflation-tracker.wax.io",
          {
            path: `/inflation/data`,
          }
        ).catch((err) => {
          return reject(err);
        });

        return resolve(results);
      });
    },
    {
      refetchInterval: 20 * 1000,
    }
  );

  const { data: inflationStat } = useQuery(
    [`inflationStat`],
    () => {
      return new Promise(async (resolve, reject) => {
        const results = await FetchManager.get(
          "https://inflation-tracker.wax.io",
          {
            path: `/inflation/stat`,
          }
        ).catch((err) => {
          return reject(err);
        });

        return resolve(results);
      });
    },
    {
      refetchInterval: 20 * 1000,
    }
  );

  const { data: inflationStatHistory } = useQuery(
    [`inflationStatHistory`, inflationData],
    () => {
      if (!inflationData) return;

      let to_block = inflationData[0].block_num;
      let from_block = to_block - 2 * 60 * 60 * 24;

      return new Promise(async (resolve, reject) => {
        const results = await FetchManager.get(
          "https://inflation-tracker.wax.io",
          {
            path: `/inflation/stat`,
            params: { from_block: from_block, to_block: to_block },
          }
        ).catch((err) => {
          return reject(err);
        });

        return resolve(results);
      });
    },
    {
      refetchInterval: 20 * 1000,
    }
  );

  return (
    <>
      <Helmet>
        <title>Inflation - WAX Explorer</title>
        <script type="module" src="https://unpkg.com/@splinetool/viewer/build/spline-viewer.js"></script>
      </Helmet>

      {/*WAX watch Sec*/}
      <div className="watch_sec">
        <div className="text">
          <h1>Watch as WAXP gets burned</h1>
          <p>
            In total{" "}
            {(inflationStat &&
              tokenFormat(
                inflationStat.total_burn / Math.pow(10, config.token_precision),
                2
              )) || <Skeleton />}{" "}
            WAXP have been burned up to now.
          </p>
          <p>
            All data below is tracked after powerup launch on the WAX blockchain
          </p>
        </div>
        <div className="videobg">
           <img src="common/images/WAXP@5x-1x1.png" alt="Wax Logo" /> 
          {/* Spline Viewer Element */}
          {/*  <spline-viewer url="https://prod.spline.design/dsU27M-RqYLaWC4L/scene.splinecode" />  */}
        </div>
      </div>
      {/*WAX watch Sec*/}
      {/*Meter chart Sec*/}
      <div className="meterchart_sec">
        <div className="stats">
          <div className="stat-left">
            <p>
              Total issue{" "}
              <strong>
                {(inflationStat &&
                  tokenFormat(
                    inflationStat.total_issue /
                      Math.pow(10, config.token_precision),
                    2
                  ))}{" "}
                WAXP
              </strong>
            </p>
            <p>
              Total fee{" "}
              <strong>
                {" "}
                {(inflationStat &&
                  tokenFormat(
                    inflationStat.total_fee /
                      Math.pow(10, config.token_precision),
                    2
                  )) }{" "}
                WAXP
              </strong>{" "}
              <em>
                $
                {(inflationStat &&
                  currencyFormat(
                    (inflationStat.total_fee /
                      Math.pow(10, config.token_precision)) *
                      tokenPrice
                  )) }
              </em>
            </p>
          </div>
          <div className="stat-mid">
            <p>
              Total Burn
              <strong>
                {(inflationStat &&
                  tokenFormat(
                    inflationStat.total_burn /
                      Math.pow(10, config.token_precision),
                    2
                  )) }{" "}
                WAXP
              </strong>{" "}
              <em>
                $
                {(inflationStat &&
                  currencyFormat(
                    (inflationStat.total_burn /
                      Math.pow(10, config.token_precision)) *
                      tokenPrice
                  )) }{" "}
              </em>
            </p>
            <p>
              Burn rate (24h){" "}
              <strong>
                {(inflationStatHistory &&
                  tokenFormat(
                    inflationStatHistory.total_burn /
                      Math.pow(10, config.token_precision),
                    2
                  )) }{" "}
                WAXP
              </strong>{" "}
              <em>
                ${" "}
                {(inflationStatHistory &&
                  currencyFormat(
                    (inflationStatHistory.total_burn /
                      Math.pow(10, config.token_precision)) *
                      tokenPrice
                  )) }{" "}
                
              </em>
            </p>
          </div>
          <div className="stat-right">
            <p>
              Average inflation{" "}
              <strong>
                {(inflationStat &&
                  tokenFormat(
                    inflationStat.avg_inflation / Math.pow(10, 4),
                    2
                  )) }
                %
              </strong>
            </p>
          </div>
        </div>
        <div className="graphbg">
          <div className="chartleft">
            <InflationChart inflationData={inflationData} />
            {/* <div id="chartcontainer" /> */}
          </div>
          <div className="imgright">
            <AvgInflationChart inflationStat={inflationStat} />
          </div>
        </div>
      </div>
      {/*Meterchart Sec*/}
      {/*chartdatatabl Sec*/}
      <div className="chartdatatabl_sec">
        <div className="tablebg">
          <table rowSpan={0} colSpan={0}>
            <tbody>
              <tr>
                <th>Block</th>
                <th className="col2">Fee (WAXP)</th>
                <th className="col3">Issue (WAXP)</th>
                <th className="col4">Burn (WAXP)</th>
                <th className="col5">Supply (WAXP)</th>
                <th className="col6">Inflation (%)</th>
                <th className="col7">Age</th>
              </tr>
              {inflationData &&
                inflationData.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link to={`/block/${data.block_num}`}>
                          {Number(data.block_num).toLocaleString()}
                        </Link>
                      </td>
                      <td>
                        {tokenFormat(
                          data.fee / Math.pow(10, config.token_precision),
                          4
                        )}
                      </td>
                      <td>
                        {tokenFormat(
                          data.issue / Math.pow(10, config.token_precision),
                          4
                        )}
                      </td>
                      <td>
                        {tokenFormat(
                          data.burn / Math.pow(10, config.token_precision),
                          4
                        )}
                      </td>
                      <td>
                        {" "}
                        {tokenFormat(
                          data.supply / Math.pow(10, config.token_precision),
                          4
                        )}
                      </td>
                      <td>
                        {tokenFormat(
                          data.inflation / Math.pow(10, 4),
                          4
                        )}
                      </td>
                      <td>{moment.utc(data.timestamp).fromNow()}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {/*chartdatatabl Sec*/}
    </>
  );
};

export default Inflation;
