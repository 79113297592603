const ReactJsonTheme = {
  base00: "#0d0c2d",
  base01: "#383830",
  base02: "#49483e",
  base03: "#75715e",
  base04: "#a59f85",
  base05: "#f8f8f2",
  base06: "#f5f4f1",
  base07: "#f9f8f5",
  base08: "#f92672",
  base09: "#7166e0",
  base0A: "#f4bf75",
  base0B: "#7166e0",
  base0C: "#7166e0",
  base0D: "#7166e0",
  base0E: "#7166e0",
  base0F: "#7166e0",
};

export default ReactJsonTheme;
