import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";
import { Link } from "react-router-dom";
import RequestedApprovals from "./components/requested-approvals";
import Actions from "./components/actions";
import { WalletContext } from "../../../app/contexts/wallet-context";
import { Api, JsonRpc } from "eosjs";
import { JsSignatureProvider } from "eosjs/dist/eosjs-jssig";
import config from "../../../app/config";
import PageTransition from "../../../app/animations/page-transition";
import Helmet from "react-helmet";
import { convertToBoolean } from "../../../app/utilities/utility";

const Msig = () => {
  const wallet = useContext(WalletContext);
  const { transactionAlert, setTransactionAlert } = wallet;
  const [formData, setFormData] = useState({
    proposer: "",
    proposal_name: generateProposalName(6),
    requested: [{ actor: "", permission: "" }],
    actions: [
      // {
      //   account: "",
      //   name: "",
      //   authorization: [{ actor: "", permission: "" }],
      //   data: {},
      // },
    ],
    settings: {
      enabled: false, //Toggle show hide
      delay_sec: 0,
      expiration: moment().utc().add(7, "days").format().split("Z")[0],
      max_cpu_usage_ms: 0,
      max_net_usage_words: 0,
      ref_block_num: "",
      ref_block_prefix: "",
    },
  });
  const { user, multisig } = wallet;

  function generateProposalName(length) {
    const characters = "abcdefghijklmnopqrstuvwxyz";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  useEffect(() => {
    let timeoutTimer = setTimeout(() => {
      if (multisig.mode && multisig.walletTransaction) {
        multisig.walletTransaction.requested_approvals &&
          multisig.walletTransaction.requested_approvals.forEach(function (
            requested_approval,
            index
          ) {
            if (formData.requested[index]) {
              formData.requested[index] = {
                actor: requested_approval.level.actor,
                permission: requested_approval.level.permission,
              };
            } else {
              formData.requested.push({
                actor: requested_approval.level.actor,
                permission: requested_approval.level.permission,
              });
            }
          });

        multisig.walletTransaction.actions.forEach(function (action) {
          //action.authorization = [{ actor: "", permission: "" }];
          formData.actions.push(action);
        });

        setFormData((formData) => ({ ...formData }));
      }
    }, 500);
    return () => {
      clearTimeout(timeoutTimer);
    };
  }, []);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      proposer: user ? user.actor : "",
      requested: [
        {
          actor: user ? user.actor : "",
          permission: user ? user.permission : "",
        },
      ],
    }));
  }, [user]);

  const { data: chaininfo } = useQuery(
    [`chaininfo`, formData.settings.expiration],
    () => BlockchainApiManager.getInfo(),
    {}
  );

  useEffect(() => {
    if (chaininfo && chaininfo.last_irreversible_block_id) {
      var result = refBlock(chaininfo.last_irreversible_block_id);

      setFormData((formData) => ({
        ...formData,
        settings: {
          ...formData.settings,
          ref_block_num: result.ref_block_num,
          ref_block_prefix: result.ref_block_prefix,
        },
      }));
    }
  }, [chaininfo]);

  // useEffect(() => {
  //   BlockchainApiManager.getInfo().then((chaininfo) => {
  //     if (chaininfo && chaininfo.last_irreversible_block_id) {
  //       var result = refBlock(chaininfo.last_irreversible_block_id);

  //       setFormData({
  //         ...formData,
  //         settings: {
  //           ...formData.settings,
  //           ref_block_num: result.ref_block_num,
  //           ref_block_prefix: result.ref_block_prefix,
  //         },
  //       });
  //     }
  //   });
  // }, []);

  const refBlock = (block_id) => {
    var block_num = block_id.substring(0, 8);
    var block_prefix = block_id.substring(16, 16 + 8);

    var ref_block_num = parseInt(block_num, 16);
    var str = "";
    for (var i = 0; i < block_prefix.length; i = i + 2) {
      var mid = block_prefix
        .substring(i, i + 2)
        .split("")
        .reverse()
        .join("");
      str += mid;
    }
    str = str.split("").reverse().join("");
    var ref_block_prefix = parseInt(str, 16);
    var obj = {
      ref_block_prefix: ref_block_prefix,
      ref_block_num: ref_block_num & 0xffff,
    };
    return obj;
  };

  const proposeMsig = async () => {
    try {
      const signatureProvider = new JsSignatureProvider([]);
      const rpc = new JsonRpc(config.endpoints.default); //required to read blockchain state
      const api = new Api({ rpc, signatureProvider }); //required to submit transactions

      //Latest addition to handle boolean issue start
      formData.actions.forEach((action, index) => {
        action.actionStruct &&
          action.actionStruct.fields.forEach((field, index) => {
            var value;
            try {
              value = JSON.parse(action.data[field.name]);
            } catch (e) {
              value = action.data[field.name];
            }

            if (field.type === "bool") {
              value = convertToBoolean(action.data[field.name]);
            }

            action.data[field.name] = typeof value !== "undefined" ? value : "";
          });
      });
      //Latest addition to handle boolean issue end

      const serialized_actions = await api.serializeActions(formData.actions);

      var msigTransaction = {};
      msigTransaction.delay_sec = formData.settings.delay_sec;
      msigTransaction.actions = [];

      msigTransaction.actions.push({
        account: "eosio.msig",
        name: "propose",
        data: {
          proposer: formData.proposer,
          proposal_name: formData.proposal_name,
          requested: formData.requested,
          trx: {
            actions: serialized_actions,
            delay_sec: formData.settings.delay_sec,
            expiration: formData.settings.expiration,
            max_cpu_usage_ms: formData.settings.max_cpu_usage_ms,
            max_net_usage_words: formData.settings.max_net_usage_words,
            ref_block_num: formData.settings.ref_block_num,
            ref_block_prefix: formData.settings.ref_block_prefix,
            context_free_actions: [],
            transaction_extensions: [],
          },
        },
      });

      wallet.submitTransaction(msigTransaction);
    } catch (err) {
      setTransactionAlert({
        type: "error",
        message: err.message,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>MSIG | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="form_box msig_form active">
          <h2>MSIG Propose</h2>
          <form>
            <Actions formData={formData} setFormData={setFormData} />

            <h2>Proposal Info</h2>
            <div className="field_row1">
              <label>Proposer</label>
              <input
                type="text"
                value={formData.proposer}
                onChange={(event) =>
                  setFormData((formData) => ({
                    ...formData,
                    proposer: event.target.value,
                  }))
                }
              />
            </div>
            <div className="field_row1">
              <label>Proposal Name</label>
              <input
                type="text"
                value={formData.proposal_name}
                onChange={(event) =>
                  setFormData((formData) => ({
                    ...formData,
                    proposal_name: event.target.value,
                  }))
                }
              />
            </div>
            <RequestedApprovals formData={formData} setFormData={setFormData} />

            <div className="field_row1 short_space">
              <label>
                Transaction Settings
                <input
                  type="button"
                  className="side_btn toggle_settings"
                  value={`${formData.settings.enabled ? "Hide" : "Show"}`}
                  onClick={() => {
                    formData.settings.enabled = formData.settings.enabled
                      ? false
                      : true;
                    setFormData({ ...formData });
                  }}
                />
              </label>
            </div>
            {formData.settings.enabled ? (
              <div
                className="trans_set"
                id="trans_set"
                style={{ display: "block" }}
              >
                <div className="field_row1">
                  <label>max_net_usage_words</label>
                  <input
                    type="text"
                    value={formData.settings.max_net_usage_words}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        settings: {
                          ...formData.settings,
                          max_net_usage_words: event.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="field_row1">
                  <label>max_cpu_usage_ms</label>
                  <input
                    type="text"
                    value={formData.settings.max_cpu_usage_ms}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        settings: {
                          ...formData.settings,
                          max_cpu_usage_ms: event.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="field_row1">
                  <label>delay_sec</label>
                  <input
                    type="text"
                    value={formData.settings.delay_sec}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        settings: {
                          ...formData.settings,
                          delay_sec: event.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="field_row1">
                  <label>expiration</label>
                  <input
                    type="text"
                    value={formData.settings.expiration}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        settings: {
                          ...formData.settings,
                          expiration: event.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="field_row1">
                  <label>ref_block_num</label>
                  <input
                    type="text"
                    value={formData.settings.ref_block_num}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        settings: {
                          ...formData.settings,
                          ref_block_num: event.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="field_row1">
                  <label>ref_block_prefix</label>
                  <input
                    type="text"
                    value={formData.settings.ref_block_prefix}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        settings: {
                          ...formData.settings,
                          ref_block_prefix: event.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="field_row1">
                  <label>context_free_actions</label>
                  <input
                    type="text"
                    value={formData.settings.context_free_actions}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        settings: {
                          ...formData.settings,
                          context_free_actions: event.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="field_row1">
                  <label>transaction_extensions</label>
                  <input
                    type="text"
                    value={formData.settings.transaction_extensions}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        settings: {
                          ...formData.settings,
                          transaction_extensions: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            ) : null}

            <div className="field_row1">
              <input type="button" value="PROPOSE" onClick={proposeMsig} />
            </div>
          </form>

          {transactionAlert &&
          transactionAlert.type === "success" &&
          transactionAlert.walletTransaction.actions &&
          transactionAlert.walletTransaction.actions[0].account ===
            "eosio.msig" &&
          transactionAlert.walletTransaction.actions[0].name === "propose" ? (
            <>
              <div className="alert alert-success active">
                <p>
                  Your new MSIG is successfully created, open{" "}
                  <a
                    href={`/msig/${transactionAlert.walletTransaction.actions[0].data.proposer}/${transactionAlert.walletTransaction.actions[0].data.proposal_name}`}
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    {
                      transactionAlert.walletTransaction.actions[0].data
                        .proposal_name
                    }
                  </a>
                </p>
              </div>
            </>
          ) : null}
        </div>
      </PageTransition>
    </>
  );
};

export default Msig;
