import { createBrowserHistory } from "history";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link, useLocation, useParams } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";
import config from "../../../app/config";
import ApiManager from "../../../app/services/apiManager";
import { numberFormat, tokenFormat } from "../../../app/utilities/format";
import PriceChart from "./components/price-chart";
import TopHolders from "./components/top-holders";

const TokensDetail = () => {
  let params = useParams();
  const location = useLocation();
  const history = createBrowserHistory();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const tab = location.hash.replace("#", "") || "topHolders";
    setActiveTab(tab);
  }, [location]);

  const { error, data: token } = useQuery(
    [`tokens_detail`, params.key],
    () => ApiManager.getToken(params.key),
    {}
  );

  const { data: holdersCount } = useQuery(
    [`tokens_holdersCount`, params.key],
    () => ApiManager.getTokenHoldersCount(params.key),
    {}
  );

  if (error) {
    return (
      <>
        <div className="token_detail_sec">
          <div className="cont_sec acc_sec">
            <h2>Token Not Found</h2>
            <p>Cannot find Token {params.key}</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <PageTransition>
        {/*My Acc Sec*/}
        <div className="token_detail_sec">
          <div className="cont_sec acc_sec">
            <div className="token_head_row">
              <div className="token_head_col token_head_col1">
                <span className="asset_img">
                  {token ? (
                    <img
                      src={`${
                        token.metadata.logo
                          ? `${config.imagekit.default_url_endpoint}/tr:w-100/${token.metadata.logo}`
                          : `/no-image.png`
                      }`}
                      alt=""
                    />
                  ) : (
                    <Skeleton />
                  )}
                </span>
                <div className="token_head">
                  <h2>{token ? token.symbol : <Skeleton />}</h2>
                  <p>
                    {token ? (
                      <Link to={`/account/${token.account}`}>
                        {token.account}
                      </Link>
                    ) : (
                      <Skeleton />
                    )}
                  </p>
                </div>
              </div>
              <div className="token_head_col token_head_col2">
                <div className="balance_box">
                  <ul>
                    <li>
                      {token ? (
                        <>
                          <span className="name">
                            ${tokenFormat(token.marketdata.price_usd, 6)}
                            <span className="sub_text">USD</span>
                            <span
                              className={`${
                                token.marketdata.change_24hr < 0
                                  ? "red"
                                  : "green"
                              }`}
                            >
                              ({token.marketdata.change_24hr}%)
                            </span>{" "}
                            <br />
                            {token.symbol !== config.token_symbol ? (
                              <span className="sub_text">
                                {tokenFormat(token.marketdata.price)} {config.token_symbol}
                              </span>
                            ) : null}
                          </span>
                        </>
                      ) : (
                        <Skeleton />
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="util_box">
              <div className="assets">
                <div className="assets_inner">
                  <table>
                    <tbody>
                      <tr>
                        <th>Market Cap</th>
                        <td>
                          {token ? (
                            <>
                              ${tokenFormat(token.marketdata.marketcap_usd, 0)}
                              &nbsp; USD
                            </>
                          ) : (
                            <Skeleton />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Daily Volume</th>
                        <td>
                          {token ? (
                            <>
                              $
                              {tokenFormat(token.marketdata.volume_24hr_usd, 2)}
                              &nbsp; USD
                            </>
                          ) : (
                            <Skeleton />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th />
                        <td>
                          {token ? (
                            <>
                              {tokenFormat(token.marketdata.volume_24hr, 2)}
                              &nbsp;{token.symbol}
                            </>
                          ) : (
                            <Skeleton />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="assets">
                <div className="assets_inner">
                  <table>
                    <tbody>
                      <tr>
                        <th>Issued Supply</th>
                        <td>
                          {token ? (
                            <>
                              {tokenFormat(token.supply.circulating, 4)}&nbsp;
                              {token.symbol}
                            </>
                          ) : (
                            <Skeleton />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Max Supply</th>
                        <td>
                          {token ? (
                            <>
                              {tokenFormat(token.supply.max, 0)}&nbsp;
                              {token.symbol}
                            </>
                          ) : (
                            <Skeleton />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Token Holders</th>
                        <td>
                          {holdersCount ? (
                            <>{numberFormat(holdersCount, 0)}</>
                          ) : (
                            <Skeleton />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*My Acc Sec*/}
        {/*Tabbing Sec*/}
        <div className="cont_sec tab_sec token_sec">
          <h2>
            <span className="icon_box">
              <i className="fa-solid fa-money-bill-trend-up" />
            </span>
            Markets
          </h2>
          <div className="tab no_scroll active">
            <div className="table_row">
              <div className="table_row_inner">
                <table>
                  <tbody>
                    <tr>
                      <th>#</th>
                      <th>Exchange</th>
                      <th>Pair</th>
                      <th>Volume (24h)</th>
                      <th>Price</th>
                      <th>Volume (%)</th>
                    </tr>
                    {token && token.pairs.length === 0 ? (
                      <tr>
                        <td colSpan={6}>
                          No markets for {token.symbol} listed on WAX Block.
                        </td>
                      </tr>
                    ) : null}
                    {token &&
                      token.pairs.map((pair) => (
                        <tr>
                          <td>1</td>
                          <td>
                            <span className="asset_img">
                              <span>
                                <img
                                  src="/exchanges/alcor.png"
                                  alt={pair.exchange}
                                />
                              </span>
                              <a
                                href="https://www.alcor.exchange/markets"
                                target="_blank"
                              >
                                {pair.exchange}
                              </a>
                            </span>
                          </td>
                          <td>{pair.id}</td>
                          <td>
                            ${tokenFormat(pair.quote.volume_base_24hr_usd, 2)}{" "}
                            USD
                            <span className="small_cont">
                              {tokenFormat(pair.quote.volume_base_24hr, 2)}
                              &nbsp;{token.symbol}
                            </span>
                            <span className="small_cont">
                              {tokenFormat(pair.quote.volume_quote_24hr, 2)}{" "}
                              {pair.pair_quote}
                            </span>
                          </td>
                          <td>
                            ${tokenFormat(pair.quote.price_usd, 6)} USD
                            <span className="small_cont">
                              {tokenFormat(pair.quote.price, 6)}{" "}
                              {pair.pair_quote}
                            </span>
                          </td>
                          <td>100 %</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/*Tabbing Sec*/}
        {/*Tabbing Sec*/}
        <div className="cont_sec tab_sec token_sec">
          <h2>
            <span className="icon_box">
              <i className="fa-solid fa-arrows-up-down-left-right" />
            </span>
            Token Information
          </h2>
          <ul className="tabbbing">
            <li
              className={`${activeTab === "topHolders" ? "active" : ""}`}
              onClick={() => {
                history.push({ hash: "topHolders" });
                setActiveTab("topHolders");
              }}
            >
              <a href="#topHolders">Top Holders</a>
            </li>
            {/* <li
              className={`${activeTab === "charts" ? "active" : ""}`}
              onClick={() => {
                history.push({ hash: "charts" });
                setActiveTab("charts");
              }}
            >
              <a href="#charts">Charts</a>
            </li> */}
          </ul>

          <div
            className={`tab topHolders no_scroll  ${
              activeTab === "topHolders" ? "active" : null
            }`}
            //id="topHolders"
          >
            {/* {activeTab === "topHolders" ? ( */}
            <TopHolders token={token} />
            {/* ) : null} */}
          </div>

          <div
            className={`tab charts no_scroll  ${
              activeTab === "charts" ? "active" : null
            }`}
            //id="charts"
          >
            {/* {activeTab === "charts" ?  */}
            <PriceChart key={params.key} />
            {/* : null} */}
          </div>
        </div>
        {/*Tabbing Sec*/}
      </PageTransition>
    </>
  );
};

export default TokensDetail;
