import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";
import config from "../../../app/config";
import { WalletContext } from "../../../app/contexts/wallet-context";
import { tokenFormat, tokenInputFormat } from "../../../app/utilities/format";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";

const Powerup = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;
  const { account, delband, userres } = wallet.walletAccount;
  const [powupState, setPowupState] = useState(null);

  const [formData, setFormData] = useState({
    payer: "",
    receiver: "",
    cpu_amount: "",
    net_amount: "",
    cpu_fee: 0,
    net_fee: 0,
    fee: 0,
  });

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      payer: user ? user.actor : "",
      receiver: user ? user.actor : "",
    }));
  }, [user]);

  useEffect(() => {
    BlockchainApiManager.getTableRows({
      code: "eosio",
      table: "powup.state",
      lower_bound: "",
      upper_bound: "",
      limit: 1,
    }).then((response) => {
      setPowupState(response.rows[0]);
    });
  }, []);

  const priceIntegralDelta = function (
    state,
    start_utilization,
    end_utilization
  ) {
    var coefficient =
      (parseFloat(state.max_price) - parseFloat(state.min_price)) /
      state.exponent;
    var start_u = start_utilization / state.weight;
    var end_u = end_utilization / state.weight;

    return (
      parseFloat(state.min_price) * end_u -
      parseFloat(state.min_price) * start_u +
      coefficient * Math.pow(end_u, state.exponent) -
      coefficient * Math.pow(start_u, state.exponent)
    );
  };

  const priceFunction = function (state, utilization) {
    var price = parseFloat(state.min_price);
    var new_exponent = state.exponent - 1.0;
    if (new_exponent <= 0.0) {
      return parseFloat(state.max_price);
    } else {
      price +=
        (parseFloat(state.max_price) - parseFloat(state.min_price)) *
        Math.pow(utilization / state.weight, new_exponent);
    }

    return price;
  };

  const calcPowerupFee = (state, utilization_increase) => {
    var fee = 0;
    var start_utilization = parseFloat(state.utilization);
    var end_utilization =
      parseFloat(state.utilization) + parseFloat(utilization_increase);

    if (start_utilization < state.adjusted_utilization) {
      fee +=
        (priceFunction(state, state.adjusted_utilization) *
          Math.min(
            utilization_increase,
            state.adjusted_utilization - start_utilization
          )) /
        state.weight;
      start_utilization = state.adjusted_utilization;
    }

    if (start_utilization < end_utilization) {
      fee += priceIntegralDelta(state, start_utilization, end_utilization);
    }

    if (powupState && fee > 0 && fee < powupState.min_powerup_fee) {
      fee = powupState.min_powerup_fee;
    }

    return fee;
  };

  useEffect(() => {
    if (!powupState) return;
    var powerup_frac = 1000000000000000;

    const cpu_fee = parseFloat(
      calcPowerupFee(
        powupState.cpu,
        formData.cpu_amount * Math.pow(10, config.token_precision)
      )
    ).toFixed(config.token_precision);

    const net_fee = parseFloat(
      calcPowerupFee(
        powupState.net,
        formData.net_amount * Math.pow(10, config.token_precision)
      )
    ).toFixed(config.token_precision);

    // const net_fee =
    //   calcPowerupFee(powupState.net, formData.net_amount) *
    //   Math.pow(10, config.token_precision);

    const fee = (parseFloat(cpu_fee) + parseFloat(net_fee)).toFixed(
      config.token_precision
    );

    const cpu_frac = parseInt(
      Math.round(
        (formData.cpu_amount *
          Math.pow(10, config.token_precision) *
          powerup_frac) /
          powupState.cpu.weight
      )
    );
    const net_frac = parseInt(
      Math.round(
        (formData.net_amount *
          Math.pow(10, config.token_precision) *
          powerup_frac) /
          powupState.net.weight
      )
    );

    const max_payment = parseFloat(
      parseFloat(fee) + (parseFloat(fee) * 50) / 100
    );

    setFormData((formData) => ({
      ...formData,
      cpu_fee: cpu_fee,
      net_fee: net_fee,
      fee: fee,
      days: powupState.powerup_days,
      max_payment: max_payment,
      cpu_frac: cpu_frac,
      net_frac: net_frac,
    }));
  }, [formData.cpu_amount, formData.net_amount]);

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "powerup";
    // walletTransaction.account = {
    //   account: null,
    //   permission: "active",
    // };
    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "powerup",
      data: {
        payer: formData.payer,
        receiver: formData.receiver,
        days: formData.days,
        cpu_frac: formData.cpu_frac,
        net_frac: formData.net_frac,
        max_payment: `${tokenInputFormat(formData.max_payment)} ${
          config.token_symbol
        }`,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <Helmet>
        <title>Powerup | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="form_box active">
          <h2>Powerup</h2>
          <form>
            <div className="field_row1">
              <label>Receiver</label>
              <input
                type="text"
                placeholder="Reciever"
                value={formData.receiver}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    receiver: event.target.value.toLowerCase(),
                  })
                }
              />
            </div>
            <div className="field_row1 short_space">
              <label>Amount of CPU to Powerup (in {config.token_symbol})</label>
              <input
                type="number"
                value={formData.cpu_amount}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    cpu_amount: event.target.value,
                  })
                }
                placeholder={`Amount in ${config.token_symbol} to pay for rent`}
              />
            </div>

            <div className="field_row1 short_space">
              <label>Amount of NET to Powerup (in {config.token_symbol})</label>
              <input
                type="number"
                value={formData.net_amount}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    net_amount: event.target.value,
                  })
                }
                placeholder={`Amount in ${config.token_symbol} to pay for rent`}
              />
            </div>

            <p>
              Total Fee:
              <br />+ {formData.cpu_fee} CPU
              <br />+ {formData.net_fee} NET
              <br />≈ {formData.fee} {config.token_symbol}
            </p>
            <br />

            <div className="field_row1">
              <input
                type="button"
                onClick={() => handleClick()}
                value={`Powerup for 1 day`}
                disabled={formData.fee > 0 ? false : true}
                style={{ opacity: formData.fee > 0 ? null : 0.5 }}
              />
            </div>
          </form>
        </div>
      </PageTransition>
    </>
  );
};

export default Powerup;
