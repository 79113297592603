import config from "../config";
import FetchManager from "./fetchManager";

const ApiManager = {
  getTokenPrice() {
    return new Promise(async (resolve, reject) => {
      const tokenPrice = await FetchManager.get(config.api.url, {
        path: `${config.api.path}/token/price`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(tokenPrice);
    });
  },
  getProducers() {
    return new Promise(async (resolve, reject) => {
      const producers = await FetchManager.get(config.api.url, {
        path: `${config.api.path}/producers`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(producers);
    });
  },
  getProxies() {
    return new Promise(async (resolve, reject) => {
      const proxies = await FetchManager.get(config.api.url, {
        path: `${config.api.path}/proxies`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(proxies);
    });
  },
  getTokens() {
    return new Promise(async (resolve, reject) => {
      const tokens = await FetchManager.get(config.api.url, {
        path: `${config.api.path}/tokens`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(tokens);
    });
  },
  getTokensStats() {
    return new Promise(async (resolve, reject) => {
      const tokensStats = await FetchManager.get(config.api.url, {
        path: `${config.api.path}/tokens/stats`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(tokensStats);
    });
  },
  getToken(key) {
    return new Promise(async (resolve, reject) => {
      const token = await FetchManager.get(config.api.url, {
        path: `${config.api.path}/tokens/${key}`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(token);
    });
  },
  getTokenHoldersCount(key) {
    return new Promise(async (resolve, reject) => {
      const holdersCount = await FetchManager.get(config.api.url, {
        path: `${config.api.path}/tokens/holdersCount/${key}`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(holdersCount);
    });
  },
  getTokenTopHolders(key) {
    return new Promise(async (resolve, reject) => {
      const topHolders = await FetchManager.get(config.api.url, {
        path: `${config.api.path}/tokens/topHolders/${key}`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(topHolders);
    });
  },
};

export default ApiManager;
