import React, { createContext, useEffect, useState } from "react";

import io from "socket.io-client";
import config from "../config";

const SocketContext = createContext();

const SocketContextProvider = ({ children }) => {
  let [socket, setSocket] = useState(null);
  useEffect(() => {
    //////////No socket for localnet//////////
    if (config.network_type === "localnet") return;
    //////////No socket for localnet//////////

    socketInitializer();

    return () => {
      socket.disconnect();
    };
  }, []);

  const socketInitializer = () => {
    socket = io(config.socket.url, {
      path: config.socket.path,
    });
    socket.on("connect", () => {
      setSocket(socket);
    });
    socket.on("disconnect", () => {
      setSocket(socket);
    });
    setSocket(socket);
  };

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export { SocketContext, SocketContextProvider };
