import { UAL } from "universal-authenticator-library";
import { Wax } from "@eosdacio/ual-wax";
import config from "../../config";

const UalWaxService = {
  provider: "ual-wax",
  ual: null,
  init: function (endpoint) {
    var url = new URL(endpoint || "https://wax.greymass.com");
    var chains = [
      {
        chainId:
          config.chain_id ||
          "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
        rpcEndpoints: [
          {
            protocol: url.protocol.slice(0, -1),
            host: url.host,
            port: 443,
          },
        ],
      },
    ];

    this.ual = new UAL(chains, "waxblok.io", [
      new Wax(chains, {
        returnTempAccounts: true,
      }),
    ]);

    var ualAuthenticator = this.ual.authenticators[0];
    ualAuthenticator.init();
  },
  connect: async function () {
    const _self = this;
    return new Promise(function (resolve, reject) {
      if (_self.ual) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  },
  isAutoLoginAvailable() {
    var ualAuthenticator = this.ual.authenticators[0];
    return ualAuthenticator.wax.isAutoLoginAvailable();
  },
  login() {
    var ualAuthenticator = this.ual.authenticators[0];
    return ualAuthenticator.login();
  },
  transaction(walletTransaction) {
    var actions = walletTransaction.actions;
    var account = walletTransaction.account;

    var authorization = [
        {
            actor: account.account,
            permission: account.permission
        }
    ];

    actions.forEach(function (action) {
        //Check if owner permission needed
        if (action.name === 'updateauth' && action.data.permission === 'owner') {
            authorization = [
                {
                    actor: account.account,
                    permission: 'owner'
                }
            ];
        }
        action.authorization = (action.authorization)?action.authorization:authorization;  
    }); 

    var ualAuthenticator = this.ual.authenticators[0];   
    console.log(ualAuthenticator)

    var ualWaxApi = ualAuthenticator.wax.api;

    return ualWaxApi.transact(
      {
        actions: actions,
      },
      {
        broadcast: true,
        blocksBehind: 3,
        expireSeconds: 60 * 3,
      }
    );
  },
};

export default UalWaxService;
