import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { WalletContext } from "../../app/contexts/wallet-context";
import Search from "./search";
import ManageAccounts from "./manage-accounts";
import config from "../../app/config";
import { createBrowserHistory } from "history";
import usePrevious from "../../app/hooks/usePrevious";

const Header = () => {
  const wallet = useContext(WalletContext);
  const { user, multisig, globalQueryParams } = wallet;

  const history = createBrowserHistory();
  let navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState("home");
  let location = useLocation();
  let prevLocation = usePrevious(location);
  const [searchParams, setSearchParams] = useSearchParams();
  const prevSearchParams = usePrevious(searchParams);

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === "/") {
      setActiveMenu("home");
    } else if (pathname.startsWith("/account")) {
      setActiveMenu("account");
    } else if (pathname.startsWith("/wallet")) {
      setActiveMenu("wallet");
    } else if (pathname.startsWith("/tokens")) {
      setActiveMenu("tokens");
    } else if (
      pathname.startsWith("/chain") ||
      pathname.startsWith("/protocol-features") ||
      pathname.startsWith("/vote") ||
      pathname.startsWith("/namebids")
    ) {
      setActiveMenu("more");
    }
  }, [location]);

  const toggleMenu = () => {
    setTimeout(() => {
      if (window.jQuery(".toggle_menu").is(":visible")) {
        window.jQuery("header .toggle_menu").toggleClass("active");
        window.jQuery("header nav").slideToggle();
      }
    }, 500);
  };

  //Query params on navigate only for localnet
  useEffect(() => {
    if (config.network_type !== "localnet") return;

    history.replace({
      search: `?${createSearchParams(globalQueryParams).toString()}`,
    });
  }, [location]);

  return (
    <>
      <header>
        <div className="wrapper">
          <Link to="/" className="header_logo">
            {(() => {
              switch (config.network_type) {
                case "mainnet":
                default:
                  return (
                    <img
                      src="/common/images/wax-block-header-logo.png"
                      alt="Wax-block Logo"
                    />
                  );
                case "testnet":
                  return (
                    <img
                      src="/common/images/wax-testnet-block-header-logo.png"
                      alt="Wax-block Logo"
                    />
                  );
                case "localnet":
                  return (
                    <img
                      src="/common/images/wax-localnet-block-header-logo.png"
                      alt="Wax-block Logo"
                    />
                  );
              }
            })()}
            {/*  {config.network_type === "mainnet" ? (
              <img
                src="/common/images/wax-block-header-logo.png"
                alt="Wax-block Logo"
              />
            ) : (
              <img
                src="/common/images/wax-testnet-block-header-logo.png"
                alt="Wax-block Logo"
              />
            )} */}
          </Link>
          <nav>
            <ul className="site_menu">
              <li className={`${activeMenu === "home" ? "active" : ""}`}>
                <Link to="/" onClick={toggleMenu}>
                  Home
                </Link>
              </li>
              <li className={`${activeMenu === "account" ? "active" : ""}`}>
                <Link
                  to={`/account/${user ? user.actor : ""}`}
                  onClick={toggleMenu}
                >
                  Account
                </Link>
              </li>
              <li className={`${activeMenu === "wallet" ? "active" : ""}`}>
                <Link to="/wallet" onClick={toggleMenu}>
                  Wallet
                </Link>
              </li>
              {/* {config.network_type === "mainnet" ? (
                <li className={`${activeMenu === "tokens" ? "active" : ""}`}>
                  <Link to="/tokens" onClick={toggleMenu}>
                    Tokens
                  </Link>
                </li>
              ) : null} */}
              {/* <li>
                <a href="#">
                  dApps
                </a>
              </li> */}
              <li>
                <span
                  className={`expand_menu ${
                    activeMenu === "more" ? "active" : ""
                  }`}
                >
                  More
                </span>
                <div className="sub_menu">
                  <div className="sub_menu_inner">
                    <ul>
                      <li>
                        <h3>Statistics</h3>
                      </li>
                      <li>
                        <Link to="/chain" onClick={toggleMenu}>
                          Chain
                        </Link>
                      </li>
                      <li>
                        <Link to="/protocol-features" onClick={toggleMenu}>
                          Protocol Features
                        </Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <h3>Validators</h3>
                      </li>
                      <li>
                        <Link to="/vote" onClick={toggleMenu}>
                          Vote
                        </Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <h3>Accounts</h3>
                      </li>
                      <li>
                        <Link to="/namebids" onClick={toggleMenu}>
                          Name Bids
                        </Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <h3>Misc</h3>
                      </li>
                      <li>
                        <Link to="/tokens">Tokens</Link>
                      </li>
                      {config.network_type === "mainnet" ? (
                        <li>
                          <Link to="/inflation">Inflation</Link>
                        </li>
                      ) : null}
                    </ul>
                    {/* <ul>
                      <li>
                        <h3>Contract</h3>
                      </li>
                      <li>
                        <Link to="/contract">
                          Tables + Actions
                        </Link>
                      </li>
                      <li>
                        <Link to="/msigs" >
                          All Multisigs
                        </Link>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </li>
            </ul>
          </nav>
          <a href="#" className="toggle_menu">
            <span />
          </a>
          <ManageAccounts />
          {/* <a href="#" className="notifications">
            <img
              src="/common/images/notify-icon.png"
              alt="notifications-icon"
            />
          </a> */}
          {multisig.mode === true ? (
            <a href="#" className="notifications">
              <img
                src="/common/images/multisig-icon.png"
                alt="notifications-icon"
                title="MSIG mode is ON"
              />
            </a>
          ) : null}

          <Search />
        </div>
      </header>
    </>
  );
};

export default Header;
