import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";
import config from "../../../app/config";
import ApiManager from "../../../app/services/apiManager";
import {
  currencyFormat,
  numberFormat,
  shortNumberFormat,
  tokenFormat,
} from "../../../app/utilities/format";

const TokensList = () => {
  const { data: tokensStats } = useQuery(
    [`tokens_stats`],
    () => ApiManager.getTokensStats(),
    {}
  );
  
  const initialFilters = {
    search: "",
  };
  const [filters, setFilters] = useState(initialFilters);
  const [currentItems, setCurrentItems] = useState([]);

  const { data: tokens } = useQuery(
    [`tokens`],
    () => ApiManager.getTokens(),
    {}
  );

  const filterToken = (token) => {
    if (filters.search) {
      var text = "";
      var items = [];

      items = [token.symbol, token.account, token.metadata.name];

      text = items.join(" ");

      if (text.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
        return false;
      }
    }

    return true;
  };

  const itemsPerPage = 100;
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (!tokens) return;
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //setCurrentItems(proxies.slice(itemOffset, endOffset));
    const filteredTokens = tokens.filter(filterToken);
    setCurrentItems(() => filteredTokens.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredTokens.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, tokens, filters]);

  // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % proxies.length;

  //   setItemOffset(newOffset);
  // };
  const handlePageClick = (event) => {
    let page = event.selected;
    setPage(page);
    const newOffset = (page * itemsPerPage) % tokens.length;

    setItemOffset(newOffset);
  };

  const currentItemsSet1 = currentItems.filter(
    (token) => token.marketdata.volume_24hr_usd >= 1000
  );
  
  const currentItemsSet2 = currentItems.filter(
    (token) => token.marketdata.volume_24hr_usd < 1000
  );
  

  return (
    <>
      <PageTransition>
        {/*My Acc Sec*/}
        <div className="token_detail">
          <div className="cont_sec acc_sec">
            <div className="util_box">
              <div className="util_block">
                <div className="performance_box">
                  <ul className="performance">
                    <li>
                      <span className="icon_box">
                        <i className="fa-solid fa-circle-dollar-to-slot" />
                      </span>
                      {tokensStats ? (
                        <>${tokenFormat(tokensStats.token_price_usd, 3)}</>
                      ) : (
                        <Skeleton />
                      )}

                      <span>WAX PRICE</span>
                    </li>
                    <li>
                      <span className="icon_box">
                        <i className="fa-solid fa-layer-group" />
                      </span>
                      {tokensStats ? (
                        <> ${shortNumberFormat(tokensStats.market_cap_usd)}</>
                      ) : (
                        <Skeleton />
                      )}

                      <span>WAX MARKETCAP</span>
                    </li>
                    <li>
                      <span className="icon_box">
                        <i className="fa-solid fa-hand-holding-dollar" />
                      </span>
                      {tokensStats ? (
                        <>
                          {" "}
                          $
                          {shortNumberFormat(tokensStats.tokens_market_cap_usd)}
                        </>
                      ) : (
                        <Skeleton />
                      )}
                      <span>TOKENS MARKETCAP</span>
                    </li>
                    <li>
                      <span className="icon_box">
                        <i className="fa-solid fa-wallet" />
                      </span>
                      {tokensStats ? (
                        <>
                          {" "}
                          $
                          {shortNumberFormat(
                            tokensStats.token_tokens_market_cap_usd
                          )}
                        </>
                      ) : (
                        <Skeleton />
                      )}

                      <span>WAX + TOKENS MARKETCAP</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*My Acc Sec*/}
        {/*Tabbing Sec*/}
        <div className="cont_sec tab_sec token_sec">
          <h2>
            <span className="icon_box">
              <i className="fa-solid fa-hand-holding-dollar" />
            </span>
            WAX Tokens
          </h2>
          <div className="token_search">
            <input
              type="search"
              placeholder="Search token symbol, name or contract..."
              aria-label="Search"
              value={filters.search}
              onChange={(el) => {
                setFilters((filters) => ({
                  ...filters,
                  search: el.target.value,
                }));
              }}
            />
          </div>
          <div className="tab no_scroll active">
            <div className="table_row">
              <div className="table_row_inner">
                <table>
                  <tbody>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Marketcap (USD)</th>
                      <th>Price (USD)</th>
                      <th>Change (24h)</th>
                      <th>Volume (USD)</th>
                      <th>Issued Supply</th>
                      <th>Contract</th>
                      <th>Precision</th>
                    </tr>
                    {!tokens &&
                      Array(5)
                        .fill(0)
                        .map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        ))}
                    {currentItemsSet1.map((token, index) => (
                      <tr key={index}>
                        <td>{token.rank}</td>
                        <td>
                          <span className="asset_img">
                            <span>
                              <img
                                src={`${
                                  token.metadata.logo
                                    ? `${config.imagekit.default_url_endpoint}/tr:w-50/${token.metadata.logo}`
                                    : `/no-image.png`
                                }`}
                                alt=""
                                style={{ width: "30px" }}
                              />
                            </span>
                            <Link
                              to={`/tokens/${token.key}`}                             
                            >
                              {token.metadata.name === token.symbol
                                ? `${token.symbol}`
                                : `${token.metadata.name} (${token.symbol})`}
                            </Link>
                          </span>
                        </td>
                        <td>
                          {tokenFormat(token.marketdata.marketcap_usd, 0)}
                        </td>
                        <td>
                          {currencyFormat(
                            token.marketdata.price_usd,
                            2
                          )}
                        </td>
                        <td>
                          <span
                            className={`${
                              token.marketdata.change_24hr < 0 ? "red" : "green"
                            }`}
                          >
                            {token.marketdata.change_24hr}%
                          </span>
                        </td>
                        <td>
                          {shortNumberFormat(token.marketdata.volume_24hr_usd)}
                        </td>
                        <td>
                          {numberFormat(token.supply.circulating)}{" "}
                          {token.symbol}
                        </td>
                        <td>
                          <Link to={`/account/${token.account}`}>
                            {token.account}
                          </Link>
                        </td>
                        <td>{token.supply.precision}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {currentItemsSet1.length > 0 && currentItemsSet2.length > 0 ? (
                  <h3>
                    Tokens with less than $1,000 24-hour volume (High Risk):
                  </h3>
                ) : null}

                <table>
                  <tbody>
                    <tr style={{ opacity: 0, lineHeight: 0, height: 0 }}>
                      <th style={{ paddingBottom: 0 }}>#</th>
                      <th style={{ paddingBottom: 0 }}>Name</th>
                      <th style={{ paddingBottom: 0 }}>Marketcap (USD)</th>
                      <th style={{ paddingBottom: 0 }}>Price (USD)</th>
                      <th style={{ paddingBottom: 0 }}>Change (24h)</th>
                      <th style={{ paddingBottom: 0 }}>Volume (USD)</th>
                      <th style={{ paddingBottom: 0 }}>Issued Supply</th>
                      <th style={{ paddingBottom: 0 }}>Contract</th>
                      <th style={{ paddingBottom: 0 }}>Precision</th>
                    </tr>
                    {currentItemsSet2.map((token, index) => (
                      <tr key={index}>
                        <td>{token.rank}</td>
                        <td>
                          <span className="asset_img">
                            <span>
                              <img
                                src={`${
                                  token.metadata.logo
                                    ? `${config.imagekit.default_url_endpoint}/tr:w-50/${token.metadata.logo}`
                                    : `/no-image.png`
                                }`}
                                alt=""
                                style={{ width: "30px" }}
                              />
                            </span>
                            <Link
                              to={`/tokens/${token.key}`}                             
                            >
                              {token.metadata.name === token.symbol
                                ? `${token.symbol}`
                                : `${token.metadata.name} (${token.symbol})`}
                            </Link>
                          </span>
                        </td>
                        <td>
                          {tokenFormat(token.marketdata.marketcap_usd, 0)}
                        </td>
                        <td>
                          {currencyFormat(
                            token.marketdata.price_usd,
                            2
                          )}
                        </td>
                        <td>
                          <span
                            className={`${
                              token.marketdata.change_24hr < 0 ? "red" : "green"
                            }`}
                          >
                            {token.marketdata.change_24hr}%
                          </span>
                        </td>
                        <td>
                          {shortNumberFormat(token.marketdata.volume_24hr_usd)}
                        </td>
                        <td>
                          {numberFormat(token.supply.circulating)}{" "}
                          {token.symbol}
                        </td>
                        <td>
                          <Link to={`/account/${token.account}`}>
                            {token.account}
                          </Link>
                        </td>
                        <td>{token.supply.precision}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/*Tabbing Sec*/}
        <ReactPaginate
          onPageChange={handlePageClick}
          previousLabel="<"
          nextLabel=">"
          pageRangeDisplayed={5}
          pageCount={pageCount}
          forcePage={page}
          className={"pagination"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
          renderOnZeroPageCount={null}
        />
      </PageTransition>
    </>
  );
};

export default TokensList;
