import { useEffect, useState } from "react";
import config from "../../../../../app/config";
import moment from "moment";
import { Link } from "react-router-dom";
import { tokenFormat } from "../../../../../app/utilities/format";
import Skeleton from "react-loading-skeleton";
import { momentformat } from "../../../../../app/utilities/date";

const ClaimBids = ({ claimBids }) => {
  //const [claimBids, setClaimBids] = useState([]);

  // useEffect(() => {
  //   if (!nameBids) return;

  //   let rows = nameBids.rows;
  //   rows = rows.filter((row) => row.high_bid < 0);
  //   rows.sort((rowA, rowB) => moment(rowB.last_bid_time).unix() - moment(rowA.last_bid_time).unix());

  //   setClaimBids(rows);
  // }, [nameBids]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Bidder</th>
            <th>Amount ({config.token_symbol})</th>
            <th>Bid Time</th>
          </tr>
        </thead>
        <tbody>
          {!claimBids &&
            Array(5)
              .fill(0)
              .map((item, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              ))}
          {claimBids &&
            claimBids.map((claimBid, index) => (
              <tr key={index}>
                <td>
                  {/* <Link to={`/account/${claimBid.newname}`}> */}
                  {claimBid.newname}
                  {/* </Link> */}
                </td>
                <td>
                  <Link to={`/account/${claimBid.high_bidder}`}>
                    {claimBid.high_bidder}
                  </Link>
                </td>
                <td>
                  {tokenFormat(
                    Math.abs(claimBid.high_bid) /
                      Math.pow(10, config.token_precision)
                  )}
                </td>
                <td>
                  {/* {moment(claimBid.last_bid_time).format(
                    "MMM DD YYYY, HH:mm:ss"
                  )} */}
                  {momentformat(
                    claimBid.last_bid_time,
                    "MMM DD YYYY, HH:mm:ss"
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default ClaimBids;
