import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import ApiManager from "../../../../app/services/apiManager";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highcharts3d from "highcharts/highcharts-3d";
import Skeleton from "react-loading-skeleton";

Highcharts3d(Highcharts);

const TopHolders = ({ token }) => {
  let params = useParams();
  const chartComponent = useRef(null);
  const [seriesData, setSeriesData] = useState([]);

  const { data: topHolders } = useQuery(
    [`tokens_topHolders`, params.key],
    () => ApiManager.getTokenTopHolders(params.key),
    {}
  );

  useEffect(() => {
    chartComponent.current.chart.showLoading();
    if (!token || !topHolders) return;

    var seriesData = [];
    topHolders.map((holder, key) => {
      if (key <= 100) {
        var percentage = parseFloat(
          ((holder[1] / token.supply.circulating) * 100).toLocaleString(
            undefined,
            {
              maximumFractionDigits: 2,
            }
          )
        );
        if (key === 0) {
          seriesData.push({
            name: holder[0],
            y: percentage,
            selected: true,
            sliced: true,
          });
        } else {
          seriesData.push({ name: holder[0], y: percentage });
        }
      }
    });

    setSeriesData(seriesData);
    chartComponent.current.chart.hideLoading();
  }, [token, topHolders]);

  const options = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45,
      },
      styledMode: false,
      allowPointSelect: true,
      height: 350,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    exporting: false,
    credits: {
      enabled: false,
    },
    tooltip: {
      hideDelay: 3000,
      useHTML: true,
      followPointer: false,
      style: {
        padding: 0,
        pointerEvents: "auto",
      },
      formatter: function () {
        var ret =
          '<a href="/account/' +
          this.point.name +
          '" style="color:inherit"><b>' +
          this.point.name +
          "</b></a><br>";
        ret +=
          '<span style="color:' +
          this.point.color +
          '">\u25CF</span> ' +
          this.series.name +
          ": ";
        ret += "<b>" + Highcharts.numberFormat(this.point.y, 2) + "</b>";
        return ret;
      },
    },
    plotOptions: {
      pie: {
        innerSize: 110,
        depth: 50,
        allowPointSelect: true,
        cursor: "pointer",
        size: "100%",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y:,.2f} %",
          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black",
          },
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        name: "Tokens",
        data: seriesData,
      },
    ],
  };

  return (
    <>
      <div className="top_holders">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponent}
        />
      </div>
      <h2>
        <span className="icon_box">
          <i className="fa-solid fa-arrows-up-down-left-right" />
        </span>
        Top {topHolders ? topHolders.length : null}{" "}
        {token ? token.symbol : null} Holders
      </h2>
      <div className="table_row">
        <div className="table_row_inner">
          <table>
            <tbody>
              <tr>
                <th>Rank</th>
                <th>Account Name</th>
                <th>{token ? token.symbol : null} Owned</th>
                <th>Value</th>
                <th>% of issued supply</th>
              </tr>
              {!topHolders ? (
                <>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : null}

              {topHolders &&
                topHolders.map((holder, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Link to={`/account/${holder[0]}`}>{holder[0]}</Link>
                    </td>
                    <td>
                      {parseFloat(holder[1]).toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                      })}
                    </td>
                    <td>
                      $
                      {token
                        ? parseFloat(
                            holder[1] * token.marketdata.price_usd
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : 0}
                    </td>
                    <td>
                      {token
                        ? (
                            (holder[1] / token.supply.circulating) *
                            100
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        : 0}
                      %
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TopHolders;
