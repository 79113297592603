import { motion } from "framer-motion";
const animationConfiguration = {
  initial: { scaleY: 0 },
  animate: { scaleY: 1 },
  exit: { scaleY: 0 },
};

// const onAnimationComplete = () => {
//   alert('fff')
// }

const PageTransition = ({ children }) => {
  return (
    <motion.div
      variants={animationConfiguration}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.5 }}      
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;
