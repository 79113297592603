import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";
import config from "../../../app/config";
import { WalletContext } from "../../../app/contexts/wallet-context";
import ApiManager from "../../../app/services/apiManager";
import LocalnetManager from "../../../app/services/localnetManager";
import { calculateVotingPower } from "../../../app/utilities/account";
import { tokenFormat } from "../../../app/utilities/format";
import {
  randomNoImage,
  randomNoImageByName,
} from "../../../app/utilities/random";
//import producersData from "../../../dummy-data/producers.json";

const Producers = () => {
  const wallet = useContext(WalletContext);
  const { user, walletAccount, setTransactionAlert, transactionAlert } = wallet;
  //const [producers, setProducers] = useState(producersData);
  const { account } = walletAccount;
  const [voteProducers, setVoteProducers] = useState([]);

  const {
    //  isLoading,
    // error,
    data: producers,
    refetch,
  } = useQuery(
    [`producers`],
    () =>
      config.network_type === "localnet"
        ? LocalnetManager.getProducers()
        : ApiManager.getProducers(),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refetch();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    setVoteProducers(
      wallet.walletAccount?.account?.voter_info?.producers || []
    );
  }, [wallet.walletAccount]);

  useEffect(() => {
    if (voteProducers.length > 30) {
      setTransactionAlert({
        type: "warning",
        message: "Please select a maximum of 30 producers to vote",
      });
    } else {
      setTransactionAlert(null);
    }
  }, [voteProducers]);

  const addVoteProducer = (producer_owner) => {
    setVoteProducers((voteProducers) => [...voteProducers, producer_owner]); // or push
  };

  const removeVoteProducer = (producer_owner) => {
    setVoteProducers((voteProducers) =>
      voteProducers.filter((voteProducer) => producer_owner !== voteProducer)
    );
  };

  const handleClick = () => {
    let voteProducersSorted = voteProducers.sort();
    console.log(voteProducersSorted);

    var walletTransaction = {};
    walletTransaction.type = "voteproducer";

    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "voteproducer",
      data: {
        voter: user ? user.actor : "",
        proxy: "",
        producers: voteProducersSorted,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  const votingPower = calculateVotingPower({ account });

  return (
    <>
      <Helmet>
        <title>Vote Producers | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div id="producers" className="forms_type active">
          <h3>
            Selected Producers ({voteProducers.length}/30)
            <input
              type="button"
              className="side_btn"
              value="VOTE"
              onClick={() => handleClick()}
            />
            <span class="select-ct">({voteProducers.length} Selected)</span>
          </h3>
          {/* {walletAccount && walletAccount.account ? (
            <>
              <p>
                <strong>Vote Strength:</strong>{" "}
                {(
                  (votingPower.voting_power_decay / votingPower.voting_power) *
                  100
                ).toFixed(2)}
                % ({tokenFormat(votingPower.voting_power_decay)} /{" "}
                {tokenFormat(votingPower.voting_power)} WAX)
              </p>
            </>
          ) : null}

          <br /> */}
          <div className="votes">
            {voteProducers.map((voteProducer, index) => {
              return (
                <span className="vote_name" data-vote="vote1" key={index}>
                  {voteProducer}
                  <span
                    className="close_vote"
                    onClick={() => removeVoteProducer(voteProducer)}
                  >
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                </span>
              );
            })}
          </div>

          <div className="producers_inner">
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Rank</th>
                  <th>Account</th>
                  <th>Total Votes</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {!producers &&
                  Array(5)
                    .fill(0)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    ))}
                {producers &&
                  producers.map((producer, index) => (
                    <tr key={index}>
                      <td>
                        <span
                          className={`checkbox ${
                            voteProducers.includes(producer.owner)
                              ? "active"
                              : ""
                          }`}
                          onClick={(event) => {
                            if (voteProducers.includes(producer.owner)) {
                              removeVoteProducer(producer.owner);
                            } else {
                              addVoteProducer(producer.owner);
                            }
                          }}
                        ></span>
                      </td>
                      <td>{producer.rank}</td>
                      <td>
                        <img
                          src={`${
                            producer.logo
                              ? `${config.imagekit.default_url_endpoint}/tr:w-50/${producer.logo}`
                              : `/${randomNoImageByName(producer.owner)}`
                          }`}
                          alt=""
                          // width={30}
                          // height={30}
                          style={{ width: "30px" }}
                        />
                        <Link
                          to={`/account/${producer.owner}`}
                          style={{ position: "relative", left: "10px" }}
                        >
                          {producer.owner}
                        </Link>
                      </td>
                      <td>{producer.actual_votes.toLocaleString()}</td>
                      <td>{producer.country_name || producer.location_name}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </PageTransition>
    </>
  );
};

export default Producers;
