import { useContext } from "react";
import { WalletContext } from "../../../app/contexts/wallet-context";
import { calculateBreakDown } from "../../../app/utilities/account";
import {
  bytesFormat,
  currencyFormat,
  microsecondsFormat,
  tokenFormat,
} from "../../../app/utilities/format";
import ResourceUtilization from "./resource-utilization";
import moment from "moment";
import { momentformat } from "../../../app/utilities/date";
import config from "../../../app/config";

const AccountSummary = () => {
  const wallet = useContext(WalletContext);
  const { tokenPrice } = wallet;
  const { account, delband, userres } = wallet.walletAccount;

  if (!account) return;

  const breakdown = calculateBreakDown({ account, delband, userres });

  return (
    <>
      <div className="wallet_sec">
        <div className="cont_sec acc_sec">
          <div className="util_box">
            <div className="util_block">
              <div className="left_desc">
                <div className="heading_sec">
                  <h2>My Wallet</h2>
                  <div className="block_id">
                    <h3>
                      {account.account_name}
                      <span>
                        Created:{" "}
                        {momentformat(account.created, "MMM DD YYYY, HH:mm:ss")}
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="performance_box">
                  <ul className="performance">
                    <li>
                      {breakdown.totalBalance.toLocaleString()} {config.token_symbol} &nbsp;
                      {tokenPrice
                        ? `($${currencyFormat(
                            breakdown.totalBalance * tokenPrice
                          )})`
                        : null}
                      <span>Total {config.token_symbol} balance</span>
                    </li>
                    <li>
                      {parseFloat(breakdown.liquidBalance).toLocaleString()} {config.token_symbol}
                      <span>Available {config.token_symbol} balance</span>
                    </li>
                    <li>
                      {tokenFormat(
                        breakdown.cpuStakedSelf + breakdown.cpuStakedToOthers
                      )}{" "}
                      {config.token_symbol}<span>CPU Staked</span>
                    </li>
                    <li>
                      {tokenFormat(
                        breakdown.netStakedSelf + breakdown.netStakedToOthers
                      )}{" "}
                      {config.token_symbol}<span>NET Staked</span>
                    </li>
                    <li>
                      {breakdown.stakedByOthers.toLocaleString()} {config.token_symbol}
                      <span>Staked by others</span>
                    </li>
                    <li>
                      {tokenFormat(breakdown.unstaking, 4)} {config.token_symbol}
                      <span>Refunding</span>
                    </li>
                  </ul>
                </div>
              </div>
              <ResourceUtilization account={account} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSummary;
