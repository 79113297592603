import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../../../app/config";
import moment from "moment";
import { tokenFormat } from "../../../../../app/utilities/format";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import { momentformat } from "../../../../../app/utilities/date";

const CurrentBids = ({ currentBids }) => {
  const [currentItems, setCurrentItems] = useState([]);
  //const [currentBids, setCurrentBids] = useState([]);

  // useEffect(() => {
  //   if (!nameBids) return;

  //   let rows = nameBids.rows;
  //   rows = rows.filter((row) => row.high_bid >= 0);
  //   rows.sort((rowA, rowB) => rowB.high_bid - rowA.high_bid);

  //   setCurrentBids(rows);
  // }, [nameBids]);

  const itemsPerPage = 20000;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (!currentBids) return;
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //setCurrentItems(currentBids.slice(itemOffset, endOffset));
    setCurrentItems((currentItems) => [
      ...currentItems,
      ...currentBids.slice(itemOffset, endOffset),
    ]);
    setPageCount(Math.ceil(currentBids.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, currentBids]);

  // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % currentBids.length;

  //   setItemOffset(newOffset);
  // };
  const handlePageClick = (page) => {
    setPage(page);
    const newOffset = ((page - 1) * itemsPerPage) % currentBids.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Bidder</th>
            <th>Amount ({config.token_symbol})</th>
            <th>Bid Time</th>
          </tr>
        </thead>
        <tbody>
          {!currentBids &&
            Array(5)
              .fill(0)
              .map((item, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              ))}
          {currentItems &&
            currentItems.map((currentBid, index) => (
              <tr key={index}>
                <td>
                  {/* <Link to={`/account/${currentBid.newname}`}> */}
                  {currentBid.newname}
                  {/* </Link> */}
                </td>
                <td>
                  <Link to={`/account/${currentBid.high_bidder}`}>
                    {currentBid.high_bidder}
                  </Link>
                </td>
                <td>
                  {" "}
                  {tokenFormat(
                    currentBid.high_bid / Math.pow(10, config.token_precision)
                  )}
                </td>
                <td>
                  {" "}
                  {/* {moment(currentBid.last_bid_time).format(
                    "MMM DD YYYY, HH:mm:ss"
                  )} */}
                  {momentformat(
                    currentBid.last_bid_time,
                    "MMM DD YYYY, HH:mm:ss"
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {page + 1 <= pageCount ? (
        <div style={{ textAlign: "center" }}>
          <a
            href="#"
            className="more_btn"
            onClick={(e) => {
              e.preventDefault();
              handlePageClick(page + 1);
            }}
          >
            view more
          </a>
        </div>
      ) : null}
      {/* {currentBids ? (
        <ReactPaginate
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          className={"pagination"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      ) : null} */}
    </>
  );
};

export default CurrentBids;
