import moment from "moment";

const dateToISO = (dateString) => {
  if (!dateString) {
    return;
  }
  // append 'Z' to the date string to indicate UTC time if the timezone isn't already specified
  if (dateString.indexOf("Z") === -1 && dateString.indexOf("+") === -1) {
    dateString += "Z";
  }

  dateString = moment(dateString).toISOString();
  return dateString;
};

const momentformat = (dateString, format, utc = false) => {
  if (utc) {
    return moment.utc(dateString).format(format);
  }

  return moment.utc(dateString).local().format(format);
};

export { dateToISO, momentformat };
