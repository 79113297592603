import WaxcloudService from "../../app/services/wallet/waxcloud";
import AnchorLinkService from "../../app/services/wallet/anchor-link";
//import WalletService from "../../app/services/wallet";
import { useContext, useEffect, useState } from "react";
import { WalletContext } from "../../app/contexts/wallet-context";
import { Link, useNavigate } from "react-router-dom";
import Jdenticon from "react-jdenticon";

const ManageAccounts = () => {
  const wallet = useContext(WalletContext);
  let { user, users, multisig, setMultisig, removeUser } = wallet;
  let navigate = useNavigate();

  const [searchUser, setSearchUser] = useState("");

  //user = {actor:'dddddd'}

  useEffect(() => {
    window.jQuery(document).mouseup(function (e) {
      if (
        e.target.id !== "user_box_logged_in" &&
        window.jQuery("header .user_box .logout").is(":visible")
      ) {
        window.jQuery("header .user_box .logout").toggle();
      }
    });
  }, []);

  return (
    <>
      <div className={`user_box ${user ? "logged_in" : ""}`}>
        {user ? (
          <a href="#" className="user">
            {/* <span
              style={{
                background:
                  "url(/common/images/user-icon.png) no-repeat center center",
              }}
            >
              {user.actor.substring(0, 2)}
            </span> */}
            <span style={{ margin: "-12px 0 0" }}>
              <Jdenticon size="25" value={user.actor} />
            </span>

            {user.actor}
          </a>
        ) : (
          <a
            href="#login-box"
            className="explore login"
            onClick={(e) => {
              e.preventDefault();
              if (users.length) {
                window.jQuery("header .user_box .user_detail").slideToggle();
              } else {
                wallet.showWalletConnectModal();
              }
            }}
          >
            Login
          </a>
        )}
        {/* <a href="#" class="logout">Logout</a>   */}
        <div className="user_detail">
          {user ? (
            <>
              <div className="user_row">
                <figure>
                  <Jdenticon size="100" value={user.actor} />

                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={100}
                    height={100}
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      fill="#acdce3"
                      d="M32.5 18.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M53.5 18.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M53.5 81.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M32.5 81.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M11.5 39.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M74.5 39.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M74.5 60.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M11.5 60.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0"
                    />
                    <path
                      fill="#59bac7"
                      d="M11.5 18.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M74.5 18.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M74.5 81.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M11.5 81.5a7,7 0 1,1 14,0a7,7 0 1,1 -14,0M29 29L50 29L50 50L29 50ZM36 48L48 48L48 36L36 36ZM71 29L71 50L50 50L50 29ZM52 36L52 48L64 48L64 36ZM71 71L50 71L50 50L71 50ZM64 52L52 52L52 64L64 64ZM29 71L29 50L50 50L50 71ZM48 64L48 52L36 52L36 64Z"
                    />
                  </svg> */}
                </figure>
                <div className="user_desc">
                  <h3>{user.actor}</h3>
                  <span className="acc_state">
                    {user.permission}
                    <span className="acc_state_icon">
                      {(() => {
                        switch (user.provider) {
                          case "waxcloud":
                          case "ual-wax":
                            return (
                              <img
                                src="/common/images/mycloudwallet.png"
                                alt="Icon Image"
                              />
                            );
                            break;
                          case "anchor-link":
                          case "ual-anchor":
                            return (
                              <img
                                src="/common/images/anchor.svg"
                                alt="Icon Image"
                              />
                            );
                            break;
                          default:
                            break;
                        }
                      })()}
                      {/* {
                        {
                          waxcloud: (
                            <img
                              src="/common/images/mycloudwallet.png"
                              alt="Icon Image"
                            />
                          ),
                          "anchor-link": (
                            <img
                              src="/common/images/anchor.svg"
                              alt="Icon Image"
                            />
                          ),
                        }[user.provider]
                      } */}
                    </span>
                  </span>
                  <Link to={`/account/${user.actor}`} className="acc_btn">
                    View Account
                  </Link>
                </div>
              </div>
              <div className="multisig_row">
                <span className="text">Multisig Mode</span>
                <span
                  className={`slide_btn ${multisig.mode ? "active" : ""}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setMultisig((multisig) => ({
                      ...multisig,
                      mode: multisig.mode ? false : true,
                      walletTransaction: null,
                    }));
                  }}
                />
              </div>
            </>
          ) : null}
          <div className="acc_row">
            <span className="text">Accounts ({users.length})</span>
            <span className="search_btn" />
          </div>
          <ul className="user_chain_row">
            {/* <li>
              <span className="chain_cnt">2</span>
              <span className="chain_icon">
                <img src="/common/images/asset1.png" alt="Icon Image" />
              </span>
            </li> */}
            <li>
              <span className="chain_cnt">
                {users.filter((user) => user.provider === "waxcloud").length}
              </span>
              <span className="chain_icon">
                <img src="/common/images/mycloudwallet.png" alt="Icon Image" />
              </span>
            </li>
            <li>
              <span className="chain_cnt">
                {users.filter((user) => user.provider === "anchor-link").length}
              </span>
              <span className="chain_icon">
                <img src="/common/images/anchor.svg" alt="Icon Image" />
              </span>
            </li>
            {/* <li title="UAL WAX">
              <span className="chain_cnt">
                {users.filter((user) => user.provider === "ual-wax").length}
              </span>
              <span className="chain_icon">
                <img src="/common/images/mycloudwallet.png" alt="Icon Image" />
              </span>
            </li>
            <li title="UAL Anchor">
              <span className="chain_cnt">
                {users.filter((user) => user.provider === "ual-anchor").length}
              </span>
              <span className="chain_icon">
                <img src="/common/images/anchor.svg" alt="Icon Image" />
              </span>
            </li> */}
          </ul>
          <div className="login_search_row">
            <input
              id="loginSearch"
              type="text"
              placeholder="Search accounts"
              onChange={(e) => setSearchUser(e.target.value)}
            />
          </div>
          <div className="login_acc_row">
            <ul>
              {users
                .filter((user) => user.actor.includes(searchUser))
                .map((user, index) => (
                  <li>
                    <h3>
                      <span className="acc_icon">
                        {(() => {
                          switch (user.provider) {
                            case "waxcloud":
                              return (
                                <img
                                  src="/common/images/mycloudwallet.png"
                                  alt="Icon Image"
                                />
                              );
                              break;
                            case "anchor-link":
                              return (
                                <img
                                  src="/common/images/anchor.svg"
                                  alt="Icon Image"
                                />
                              );
                              break;
                            case "ual-wax":
                              return (
                                <img
                                  src="/common/images/mycloudwallet.png"
                                  alt="Icon Image"
                                  title="UAL WAX"
                                />
                              );
                              break;
                            case "ual-anchor":
                              return (
                                <img
                                  src="/common/images/anchor.svg"
                                  alt="Icon Image"
                                  title="UAL Anchor"
                                />
                              );
                              break;
                            default:
                              break;
                          }
                        })()}
                        {/* {
                          {
                            waxcloud: (
                              <img
                                src="/common/images/mycloudwallet.png"
                                alt="Icon Image"
                              />
                            ),
                            "anchor-link": (
                              <img
                                src="/common/images/anchor.svg"
                                alt="Icon Image"
                              />
                            ),
                          }[user.provider]
                        } */}
                      </span>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          //wallet.login(user);
                          wallet.switchUser(user);
                        }}
                        title={`Switch to the account ${user.actor}`}
                      >
                        {user.actor} ({user.permission})
                      </a>
                    </h3>
                    <div className="acc_actions">
                      <Link
                        to={`/account/${user.actor}`}
                        title={`Open account page for ${user.actor}`}
                      >
                        <span className="action_icon">
                          <i className="fa-regular fa-circle-right" />
                        </span>
                      </Link>
                      <a
                        href="#"
                        className="action_icon"
                        onClick={(e) => {
                          e.preventDefault();
                          removeUser(user);
                        }}
                        title={`Remove account ${user.actor} from this list`}
                      >
                        <i className="fa-regular fa-trash-can" />
                      </a>
                    </div>
                  </li>
                ))}
              {/* <li>
                <h3>
                  <span className="acc_icon">
                    <img src="/common/images/anchor.svg" alt="Icon Image" />
                  </span>
                  eosio (active)
                </h3>
                <div className="acc_actions">
                  <span className="action_icon">
                    <i className="fa-regular fa-circle-right" />
                  </span>
                  <a href="#" className="action_icon">
                    <i className="fa-regular fa-trash-can" />
                  </a>
                </div>
              </li> */}
              {/* <li>eosio (burn)</li> */}
              {/* <li>
                <h3>
                  <span className="acc_icon">
                    <img src="/common/images/anchor.svg" alt="Icon Image" />
                  </span>
                  eosio (burn)
                </h3>
                <div className="acc_actions">
                  <span className="action_icon">
                    <i className="fa-regular fa-circle-right" />
                  </span>
                  <a href="#" className="action_icon">
                    <i className="fa-regular fa-trash-can" />
                  </a>
                </div>
              </li> */}
            </ul>
          </div>
          <div className="profile_btn_row">
            <a
              href="#"
              className="action_button"
              onClick={(e) => {
                e.preventDefault();
                wallet.showWalletConnectModal();
                window.$("header .user_box .user_detail:visible").slideToggle();
              }}
            >
              Add Account
            </a>
            {user ? (
              <a
                href="#"
                className="action_button"
                onClick={(e) => {
                  e.preventDefault();
                  wallet.logout();
                }}
              >
                Log Out
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );

  // return (
  //   <>
  //     <div className={`user_box ${user ? "logged_in" : ""}`}>
  //       {user ? (
  //         <>
  //           <a
  //             href="#"
  //             //to={`/account/${user.actor}`}
  //             className="user"
  //             //style={{ display: "block" }}
  //             id="user_box_logged_in"
  //             // onClick={(e) => {
  //             //   e.preventDefault();
  //             //   window.jQuery("header .user_box .logout").toggle();
  //             //   navigate(`/account/${user.actor}`);
  //             // }}
  //           >
  //             <span
  //               style={{
  //                 background:
  //                   "url(/common/images/user-icon.png) no-repeat center center",
  //               }}
  //             >
  //               {user.actor.substring(0, 2)}
  //             </span>
  //             {user.actor}
  //           </a>
  //           <a
  //             href="#"
  //             className="logout"
  //             onClick={(e) => {
  //               e.preventDefault();
  //               setMultisig((multisig) => ({
  //                 ...multisig,
  //                 mode: multisig.mode ? false : true,
  //                 walletTransaction: null,
  //               }));
  //             }}
  //           >
  //             {multisig.mode ? "Turn off MSIG" : "Turn on MSIG"}
  //           </a>
  //           <a
  //             href="#"
  //             className="logout"
  //             onClick={(e) => {
  //               e.preventDefault();
  //               wallet.logout();
  //             }}
  //             style={{ top: "64px" }}
  //           >
  //             Logout
  //           </a>
  //         </>
  //       ) : (
  //         <>
  //           <a
  //             href="#login-box"
  //             className="explore login"
  //             onClick={(e) => {
  //               e.preventDefault();
  //               wallet.showWalletConnectModal();
  //             }}
  //           >
  //             Login
  //           </a>
  //         </>
  //       )}
  //     </div>
  //   </>
  // );
};

export default ManageAccounts;
