import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";
import ImageLoader from "../../../app/components/image-loader";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";
import { numberFormat } from "../../../app/utilities/format";
import {
  getAssetId,
  getType,
  getAuthor,
  getCollectionName,
  getImage,
  getName,
  getVideo,
} from "../../../app/utilities/nft";

const NftAssets = () => {
  let params = useParams();
  const [authors, setAuthors] = useState([]);
  const [collections, setCollections] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [filterAuthors, setFilterAuthors] = useState([]);
  const [filterCollections, setFilterCollections] = useState([]);
  const [filteredNfts, setFilteredNfts] = useState([]);

  const {
    isLoading,
    error,
    data: nfts,
  } = useQuery(
    [`nfts`, params.account],
    () => BlockchainApiManager.getNfts(params.account),
    {}
  );

  useEffect(() => {
    if (!nfts) return;

    let authors = [];
    let collections = [];
    nfts.data.forEach((nft) => {
      var newAuthor =
        nft.contract === "atomicassets" ? nft.collection.author : nft.author;
      var author = authors.find((author) => author === newAuthor);
      if (!author) {
        authors.push(newAuthor);
      }

      var newCollection =
        nft.contract === "atomicassets"
          ? nft.collection.collection_name
          : nft.category;
      var collection = collections.find(
        (collection) => collection === newCollection
      );
      if (!collection) {
        collections.push(newCollection);
      }
    });

    setAuthors(authors);
    setCollections(collections);
  }, [nfts]);

  useEffect(() => {
    if (!nfts) return;

    const newFilteredNfts = nfts.data.filter((nft) => {
      var author =
        nft.contract === "atomicassets" ? nft.collection.author : nft.author;
      var collection =
        nft.contract === "atomicassets"
          ? nft.collection.collection_name
          : nft.category;
      return (
        (filterAuthors.length ? filterAuthors.includes(author) : true) &&
        (filterCollections.length
          ? filterCollections.includes(collection)
          : true)
      );
    });
    //console.log('newFilteredNfts',newFilteredNfts)
    setFilteredNfts(newFilteredNfts);
  }, [nfts, filterAuthors, filterCollections]);

  const itemsPerPage = 20;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (!nfts) return;

    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    //setCurrentItems(filteredNfts.slice(itemOffset, endOffset));
    setCurrentItems((currentItems) => [
      ...currentItems,
      ...filteredNfts.slice(itemOffset, endOffset),
    ]);
    setPageCount(Math.ceil(filteredNfts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredNfts]);

  // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % filteredNfts.length;

  //   setItemOffset(newOffset);
  // };

  const handlePageClick = (page) => {
    setPage(page);
    const newOffset = ((page - 1) * itemsPerPage) % filteredNfts.length;
    // console.log(
    //   `User requested page number ${page}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <>
      {isLoading ? <p>Loading...</p> : null}

      {error ? <p>Error fetching...</p> : null}

      {nfts ? (
        <>
          <h3>
            Showing {numberFormat(filteredNfts.length)} /{" "}
            {numberFormat(nfts.total)} NFTs
          </h3>
          {filterAuthors.length ? (
            <>
              <h3>Filtered authors</h3>
              <p>{filterAuthors.join(", ")}</p>
            </>
          ) : null}

          <h3>Filter by Author</h3>
          {authors.map((author, index) => (
            <a
              className={`more_btn ${
                filterAuthors.includes(author) ? "active" : ""
              }`}
              style={{
                marginBottom: "10px",
                padding: "10px",
                width: "auto",
                cursor: "pointer",
                marginRight: "10px",
                fontSize: "12px",
                lineHeight: "14px",
                background: filterAuthors.includes(author)
                  ? "#7D70F6"
                  : "#404047",
              }}
              key={index}
              onClick={() => {
                setFilterAuthors((filterAuthors) => {
                  if (filterAuthors.includes(author)) {
                    return filterAuthors.filter(
                      (filterAuthor) => filterAuthor !== author
                    );
                  }

                  return [...filterAuthors, author];
                });
                setPage(1);
                setItemOffset(0);
                setCurrentItems([]);
              }}
            >
              {author}
            </a>
          ))}

          {filterCollections.length ? (
            <>
              <h3>Filtered collections</h3>
              <p>{filterCollections.join(", ")}</p>
            </>
          ) : null}
          <h3>Filter by Collection</h3>
          {collections.map((collection, index) => (
            <a
              className={`more_btn ${
                filterCollections.includes(collection) ? "active" : ""
              }`}
              style={{
                marginBottom: "10px",
                padding: "10px",
                width: "auto",
                cursor: "pointer",
                marginRight: "10px",
                fontSize: "12px",
                lineHeight: "14px",
                background: filterCollections.includes(collection)
                  ? "#7D70F6"
                  : "#404047",
              }}
              key={index}
              onClick={() => {
                setFilterCollections((filterCollections) => {
                  if (filterCollections.includes(collection)) {
                    return filterCollections.filter(
                      (filterCollection) => filterCollection !== collection
                    );
                  }

                  return [...filterCollections, collection];
                });
                setPage(1);
                setItemOffset(0);
                setCurrentItems([]);
              }}
            >
              {collection}
            </a>
          ))}

          <div className="assets_tiles">
            {currentItems.map((nft, index) => (
              <div className="assets_tile" key={index}>
                <figure
                  style={{
                    height: "250px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {getImage(nft) ? (
                    <>
                      <ImageLoader
                        src={getImage(nft)}
                        style={{
                          maxHeight: "250px",
                          maxWidth: "250px" /*verticalAlign: "unset"*/,
                        }}
                        className="card-img-top"
                      />
                    </>
                  ) : null}
                  {!getImage(nft) && getVideo(nft) ? (
                    <video
                      controls
                      style={{
                        maxHeight: "250px",
                        maxWidth: "250px" /*width: "100%"*/,
                      }}
                      className="card-img-top"
                    >
                      <source src={getVideo(nft)} type="video/mp4" />
                    </video>
                  ) : null}
                  {!getImage(nft) && !getVideo(nft) ? (
                    <img
                      src={`/no-image.png`}
                      alt=""
                      style={{
                        maxHeight: "250px",
                        maxWidth: "250px" /*verticalAlign: "unset"*/,
                      }}
                      className="card-img-top"
                    />
                  ) : null}
                </figure>
                <div className="text_row">
                  <h3>
                    <span>
                      {/* <Link
                        to={`/nft/${getType(nft)}/${getAssetId(nft)}?account=${
                          params.account
                        }`}
                      > */}
                      {getName(nft)}
                      {/* </Link> */}
                    </span>
                  </h3>
                  {/* <div className="bids">
                    <span className="step step1">Dead Horses</span>                   
                  </div> */}
                </div>
                <div className="text_row">
                  <h3>
                    <span>ID: {getAssetId(nft)}</span>
                  </h3>
                </div>
                <div className="text_row">
                  <h3>
                    <span> Collection: {getCollectionName(nft)}</span>
                  </h3>
                </div>
                <div className="text_row">
                  <h3>
                    <span>
                      Author:{" "}
                      <Link to={`/account/${getAuthor(nft)}`}>
                        {getAuthor(nft)}
                      </Link>
                    </span>
                  </h3>
                </div>
                {/* <div className="more_block">
                  <a href="#">
                    <img src="/common/images/more-dots.png" alt="more-dots" />
                  </a>
                </div> */}
              </div>
            ))}
          </div>
          {page + 1 <= pageCount ? (
            <div style={{ textAlign: "center" }}>
              <a
                href="#"
                className="more_btn"
                onClick={(e) => {
                  e.preventDefault();
                  handlePageClick(page + 1);
                }}
              >
                view more
              </a>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default NftAssets;
