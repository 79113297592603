import PageTransition from "../../app/animations/page-transition";
import {  Outlet } from "react-router-dom";

const Tokens = () => {
  return (
    <>
      <PageTransition>
      <Outlet />
      </PageTransition>
    </>
  );
};

export default Tokens;
