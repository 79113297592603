import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";
import { useEffect } from "react";

import { useState } from "react";
import config from "../../../app/config";

const InflationChart = ({ inflationData }) => {
  const chartRef = useRef(null);
  const [options, setOptions] = useState({
    chart: {
      height: 260,
      borderColor: "#26264c",
      borderWidth: 0,
      backgroundColor: "#0a0928",
      type: "line",
    },

    title: {
      text: "",
    },

    yAxis: {
      
      //tickInterval: 10,
      gridLineWidth: 1,
      gridLineColor: "#26264c",
      labels: {
        style: {
          color: "#fff",
          fontSize: "0.6em",
        },
      },
      title: {
        text: "Base fee (WAXP)",
      },
    },

    xAxis: {
      lineColor: "#26264c",
      gridLineWidth: 1,
      gridLineColor: "#26264c",
      labels: {
        style: {
          color: "#fff",
          fontSize: "0.6em",
        },
      },
      accessibility: {
        rangeDescription: "Range: 18717500 to 18718600",
      },
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        //pointStart: 18717500,
        //pointInterval: 100,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "",
        type: "spline",
        color: "#9f97d0",
        lineWidth: 1.5,
        showInLegend: false,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        data: [],
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (!inflationData) return;

    let data = [...inflationData].reverse().map((item) => ({
      x: parseInt(item.block_num),
      y: item.fee / Math.pow(10, config.token_precision),
    }));

    chartRef.current.chart.series[0].setData(data);
    chartRef.current.chart.redraw();
  }, [inflationData]);

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </>
  );
};

export default InflationChart;
