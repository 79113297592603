import config from "../config";
import FetchManager from "./fetchManager";

const BlockchainApiManager = {
  getInfo() {
    return new Promise(async (resolve, reject) => {
      const chaininfo = await FetchManager.get(
        { ...config.endpoints.state },
        {
          path: "/v1/chain/get_info",
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(chaininfo);
    });
  },
  getBlock(blockId) {
    return new Promise(async (resolve, reject) => {
      const block = await FetchManager.post(
        { ...config.endpoints.block },
        {
          path: "/v1/chain/get_block",
          data: { block_num_or_id: blockId },
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(block);
    });
  },
  getTransaction(txnId) {
    return new Promise(async (resolve, reject) => {
      const transaction = await FetchManager.post(
        { ...config.endpoints.transaction },
        {
          path: "/v1/history/get_transaction",
          data: { id: txnId, block_num_hint: 0 },
        }
      ).catch((err) => {
        return reject(err);
      });

      if (transaction.error) {
        return reject("Transaction not found");
      }

      return resolve(transaction);
    });
  },
  getAbi(account_name) {
    return new Promise(async (resolve, reject) => {
      const block = await FetchManager.post(
        { ...config.endpoints.state },
        {
          path: "/v1/chain/get_abi",
          data: { account_name: account_name },
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(block);
    });
  },
  getAccount(account_name) {
    return new Promise(async (resolve, reject) => {
      const block = await FetchManager.post(
        { ...config.endpoints.state },
        {
          path: "/v1/chain/get_account",
          data: { account_name: account_name },
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(block);
    });
  },
  getActions(params) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.post(
        { ...config.endpoints.history },
        {
          path: "/v1/history/get_actions",
          data: Object.assign(
            {
              account_name: "",
              offset: -100,
              pos: -1,
            },
            params
          ),
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getTableRows(params) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.post(
        { ...config.endpoints.history },
        {
          path: "/v1/chain/get_table_rows",
          data: Object.assign(
            {
              code: "",
              scope: "",
              table: "",
              lower_bound: "",
              upper_bound: "",
              index_position: 1,
              key_type: "i64",
              limit: -1,
              reverse: false,
              json: true,
              show_payer: false,
            },
            params
          ),
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getTableByScope(params) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.post(
        { ...config.endpoints.state },
        {
          path: "/v1/chain/get_table_by_scope",
          data: Object.assign(
            {
              code: "",
              table: "",
              lower_bound: "",
              upper_bound: "",
              limit: 5,
            },
            params
          ),
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getKeyAccounts(key) {
    return new Promise(async (resolve, reject) => {
      const keyAccounts = await FetchManager.post(
        { ...config.endpoints.state },
        {
          path: "/v1/history/get_key_accounts",
          data: { public_key: key },
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(keyAccounts);
    });
  },
  getBalances(account_name) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(config.endpoints.lightapi, {
        path: `/api/balances/${config.network}/${account_name}`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getCurrencyStats(params) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.post(
        { ...config.endpoints.state },
        {
          path: "/v1/chain/get_currency_stats",
          data: Object.assign(
            {
              code: "",
              symbol: "",
              json: true,
            },
            params
          ),
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getCurrencyBalance(params) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.post(
        { ...config.endpoints.state },
        {
          path: "/v1/chain/get_currency_balance",
          data: Object.assign(
            {
              account: "",
              code: "",
              symbol: "",
            },
            params
          ),
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getActivatedProtocolFeatures(params) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.post(
        { ...config.endpoints.state },
        {
          path: "/v1/chain/get_activated_protocol_features",
          data: Object.assign(
            {
              limit: 50,
              reverse: false,
              search_by_block_num: false,
            },
            params
          ),
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getAccInfo(account_name) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(config.endpoints.lightapi, {
        path: `/api/accinfo/${config.network}/${account_name}`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getTopStakeHolders() {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(config.endpoints.lightapi, {
        path: `/api/topstake/${config.network}/500`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getTopHolders() {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(config.endpoints.lightapi, {
        path: `/api/topholders/${config.network}/eosio.token/${config.token_symbol}/500`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getTopRamHolders() {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(config.endpoints.lightapi, {
        path: `/api/topram/${config.network}/500`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getNfts(account_name, params) {
    return new Promise(async (resolve, reject) => {
      let nfts = { total: 0, data: [] };
      let moreAtomicassets = 0;
      let paramsAtomicassets = {
        page: 1,
        limit: 1000,
        sort: "asset_id",
        order: "desc",
      };
      do {
        const atomicassets = await BlockchainApiManager.getAtomicAssets(
          account_name,
          {
            page: 1,
            limit: 1000,
            sort: "asset_id",
            order: "desc",
            ...paramsAtomicassets,
          }
        ).catch((err) => {
          return reject(err);
        });

        if (atomicassets && atomicassets.data) {
          nfts.total = nfts.total + atomicassets.data.length;
          nfts.data = [...nfts.data, ...atomicassets.data];
        }

        moreAtomicassets =
          atomicassets &&
          atomicassets.data &&
          atomicassets.data.length &&
          atomicassets.data.length === paramsAtomicassets.limit
            ? 1
            : 0;

        if (moreAtomicassets) paramsAtomicassets.page++;
      } while (moreAtomicassets === 1);

      const simpleassets = await BlockchainApiManager.getSimpleAssets(
        account_name,
        {
          page: 1,
          limit: 1000,
          sortField: "assetId",
          sortOrder: "asc",
        }
      ).catch((err) => {
        return reject(err);
      });

      if (simpleassets && simpleassets.results) {
        nfts.total = nfts.total + simpleassets.extra.total;
        nfts.data = [...nfts.data, ...simpleassets.results];
      }

      return resolve(nfts);
    });
  },
  getSimpleAssets(account_name, params) {
    const queryParams = Object.assign(
      {
        page: 1,
        limit: 1000,
        sortField: "assetId",
        sortOrder: "asc",
      },
      params
    );

    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(
        config.endpoints.nfts.simpleassets,
        {
          path: `/v1/user/${account_name}/assets?page=1&limit=1000&sortField=assetId&sortOrder=asc`,
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getAtomicAssets(account_name, params) {
    const queryParams = Object.assign(
      {
        owner: account_name,
        page: 1,
        limit: 1000,
        sort: "asset_id",
        order: "desc",
      },
      params
    );

    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(
        config.endpoints.nfts.atomicassets,
        {
          path: `/atomicassets/v1/assets?owner=${account_name}&page=${queryParams.page}&limit=${queryParams.limit}&order=${queryParams.order}&sort=${queryParams.sort}`,
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getNft(assetType, assetId) {
    return new Promise(async (resolve, reject) => {
      let nft;
      switch (assetType) {
        case "atomicassets":
          const atomicasset = await BlockchainApiManager.getAtomicAsset(
            assetId
          ).catch((err) => {
            return reject(err);
          });
          nft = atomicasset.data;
          break;
        case "simpleassets":
          const simpleasset = await BlockchainApiManager.getSimpleAsset(
            assetId
          ).catch((err) => {
            return reject(err);
          });
          nft = simpleasset;
          break;
        default:
          nft = null;
          break;
      }

      return resolve(nft);
    });
  },
  getSimpleAsset(assetId) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(
        config.endpoints.nfts.simpleassets,
        {
          path: `/v1/assets/${assetId}`,
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getAtomicAsset(assetId) {
    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(
        config.endpoints.nfts.atomicassets,
        {
          path: `/atomicassets/v1/assets/${assetId}`,
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  /////////////////////V2 Calls Start//////////////////
  getV2Transaction(txnId) {
    return new Promise(async (resolve, reject) => {
      const transaction = await FetchManager.get(
        { ...config.endpoints.transactionV2 },
        {
          path: `/v2/history/get_transaction?id=${txnId}`,
        }
      ).catch((err) => {
        return reject(err);
      });

      return resolve(transaction);
    });
  },
  getV2Actions(account_name, params) {
    let queryParams = Object.assign(
      {
        account: account_name,
        limit: 1000,
        sort: "desc",
        skip: 0,
        after: null,
        before: null,
        filter: null,
      },
      params
    );

    //Remove null values
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key] === null) {
        delete queryParams[key];
      }
    });

    const queryString = Object.keys(queryParams)
      .map((key) => key + "=" + queryParams[key])
      .join("&");

    return new Promise(async (resolve, reject) => {
      const results = await FetchManager.get(config.endpoints.historyV2, {
        path: `/v2/history/get_actions?${queryString}`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getV2Creator(account_name) {
    return new Promise(async function (resolve, reject) {
      const results = await FetchManager.get(config.endpoints.historyV2, {
        path: `/v2/history/get_creator?account=${account_name}`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(results);
    });
  },
  getV2Tokens(account_name) {
    return new Promise(async (resolve, reject) => {
      const tokens = await FetchManager.get(config.endpoints.historyV2, {
        path: `/v2/state/get_tokens?account=${account_name}`,
      }).catch((err) => {
        return reject(err);
      });

      return resolve(tokens);
    });
  },
  /////////////////////V2 Calls End//////////////////
};

export default BlockchainApiManager;
