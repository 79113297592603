import config_testnet from "./config_testnet";
import config_localnet from "./config_localnet";

export let config = {
  app_name: "waxblock.io",
  network: "wax",
  network_type: "mainnet",
  chain_id: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  token_symbol: "WAX",
  token_precision: 8,
  endpoints: {
    default: "https://api.wax.alohaeos.com",
    sign: "https://wax.greymass.com",
    state: {           
      "https://api.wax.bountyblok.io": 100,
      "https://wax.cryptolions.io": 100,
      "https://api-wax.eosauthority.com": 20,
      "https://wax.eosphere.io": 80,
      //"https://api.wax.alohaeos.com": 100,  //does not support get_table_by_scope all results
      "https://api.wax.liquidstudios.io": 100,
      "https://waxapi.ledgerwise.io": 100,   
      "https://api.waxsweden.org": 100,      
      //"https://wax.greymass.com": 1,        //does not support get_table_by_scope all results
      "https://wax.eu.eosamsterdam.net": 100,      
    },
    block: {
      "https://api-wax.eosarabia.net": 90,
      "https://api.wax.alohaeos.com": 100,
      "https://wax.dapplica.io": 100,      
      "https://hyperion.wax.tgg.gg": 100,      
      "https://history-wax-mainnet.wecan.dev": 100,
      "https://api.wax.liquidstudios.io": 100,      
      "https://waxapi.ledgerwise.io": 100,   
      "https://api.waxsweden.org": 100,      
      "https://wax.greymass.com": 100,      
      "https://wax.eu.eosamsterdam.net": 100, 
    },
    transaction: { //temporary change for consistent API results
      //"https://wax.eosphere.io": 1,
      //"https://api.wax.alohaeos.com": 1,
      //"https://wax.dapplica.io": 1,      
      //"https://hyperion.wax.tgg.gg": 1,    
      //"https://history-wax-mainnet.wecan.dev": 1,      
      //"https://api.wax.liquidstudios.io": 1,      
      //"https://waxapi.ledgerwise.io": 1,   
      "https://api.waxsweden.org": 1,      
      "https://wax.greymass.com": 100000,      //100000 
      "https://wax.eu.eosamsterdam.net": 1,
    },
    history: { //temporary change for consistent API results
      //"https://wax.eosphere.io": 1,
      //"https://api.wax.alohaeos.com": 1,
      //"https://wax.dapplica.io": 1,      
      //"https://hyperion.wax.tgg.gg": 1,    
      //"https://history-wax-mainnet.wecan.dev": 1,      
      //"https://api.wax.liquidstudios.io": 1,      
      //"https://waxapi.ledgerwise.io": 1,   
      "https://api.waxsweden.org": 1,      
      "https://wax.greymass.com": 100000,      //100000 
      "https://wax.eu.eosamsterdam.net": 1,

    },
    transactionV2: {
      "https://wax.eosphere.io": 100,
      "https://api.wax.alohaeos.com": 100,
      "https://wax.dapplica.io": 100,      
      "https://hyperion.wax.tgg.gg": 100,    
      "https://history-wax-mainnet.wecan.dev": 100,      
      "https://api.wax.liquidstudios.io": 100,      
      "https://waxapi.ledgerwise.io": 100,   
      "https://api.waxsweden.org": 100,      
      // "https://wax.greymass.com": 100,      //Does not support v2
      "https://wax.eu.eosamsterdam.net": 100,
    },
    historyV2: {
      "https://wax.eosrio.io": 100000,
      "https://wax.eosphere.io": 1,
      "https://api.wax.alohaeos.com": 1,
      "https://wax.dapplica.io": 1,     
      "https://hyperion.wax.tgg.gg": 1,           
      "https://history-wax-mainnet.wecan.dev": 1,      
      "https://api.wax.liquidstudios.io": 1,      
      "https://waxapi.ledgerwise.io": 1,   
      "https://api.waxsweden.org": 1,
      // "https://wax.greymass.com": 100,      //Does not support v2      
      "https://wax.eu.eosamsterdam.net": 1,
    },    
    lightapi: {
      "https://wax.light-api.net": 100,
    },
    nfts: {
      simpleassets: "https://wax.api.simpleassets.io",
      atomicassets: "https://wax.api.atomicassets.io",
    },
  },
  imagekit: {
    default_url_endpoint: "https://ik.imagekit.io/waxblock",
  },
  socket: {
    url: process.env.REACT_APP_SOCKET_URL || "http://127.0.0.1:8080",
    path: "/socket",
  },
  api: {
    url: process.env.REACT_APP_API_URL || "http://127.0.0.1:8080",
    path: "/api/v1",
  },
  anchorLink: {
    app_name: "waxblock.io",
  },
};

//Switch config based network
if (
  process.env.REACT_APP_NETWORK_TYPE === "testnet" ||
  process.env.REACT_APP_CF_NETWORK_TYPE === "testnet"
) {
  config = { ...config, ...config_testnet };
}else if (
  process.env.REACT_APP_NETWORK_TYPE === "localnet" ||
  process.env.REACT_APP_CF_NETWORK_TYPE === "localnet"
) {
  config = { ...config, ...config_localnet };
}

export default config;
