import { UAL } from "universal-authenticator-library";
import { Anchor } from "ual-anchor";
import config from "../../config";

const UalAnchorService = {
  provider: "ual-anchor",
  ual: null,
  init: function (endpoint) {
    var url = new URL(endpoint || "https://wax.greymass.com");
    var chains = [
      {
        chainId:
          config.chain_id ||
          "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
        rpcEndpoints: [
          {
            protocol: url.protocol.slice(0, -1),
            host: url.host,
            port: 443,
          },
        ],
      },
    ];

    this.ual = new UAL(chains, "waxblok.io", [
      new Anchor(chains, {
        appName: config.anchorLink.app_name,
      }),
    ]);

    var ualAuthenticator = this.ual.authenticators[0];
    ualAuthenticator.init();
  },
  connect: async function () {
    const _self = this;
    return new Promise(function (resolve, reject) {
      if (_self.ual) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  },
  login() {
    var ualAuthenticator = this.ual.authenticators[0];
    return ualAuthenticator.login();
  },
  restoreSession() {
    var ualAuthenticator = this.ual.authenticators[0];
    return ualAuthenticator.link.restoreSession(ualAuthenticator.appName);
  },
  listSessions() {
    var ualAuthenticator = this.ual.authenticators[0];
    return ualAuthenticator.link.listSessions(ualAuthenticator.appName);
  },
  transaction(walletTransaction) {
    var actions = walletTransaction.actions;
    var account = walletTransaction.account;

    var authorization = [
      {
        actor: account.account,
        permission: account.permission,
      },
    ];

    actions.forEach(function (action) {
      //Check if owner permission needed
      if (action.name === "updateauth" && action.data.permission === "owner") {
        authorization = [
          {
            actor: account.account,
            permission: "owner",
          },
        ];
      }
      action.authorization = action.authorization
        ? action.authorization
        : authorization;
    });

    var ualAuthenticator = this.ual.authenticators[0];

    var ualAnchorLink = ualAuthenticator.link;

    return ualAnchorLink.transact(
      {
        actions: actions,
      },
      {
        broadcast: true,
        blocksBehind: 3,
        expireSeconds: 60 * 3,
      }
    );
  },
};

export default UalAnchorService;
