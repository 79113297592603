import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import moment from "moment";
import PageTransition from "../../../../app/animations/page-transition";
import { WalletContext } from "../../../../app/contexts/wallet-context";
import { momentformat } from "../../../../app/utilities/date";

const Refund = () => {
  const wallet = useContext(WalletContext);
  const { user, walletAccount } = wallet;
  const { account } = walletAccount;
  const [refundEnabled, setRefundEnabled] = useState(false);
  const [timer, setTimer] = useState();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(moment().unix());
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (account && account.refund_request) {
      if (
        moment.utc().unix() <
        moment.utc(account.refund_request.request_time)
          .add(72, "hours")
          .unix()
      ) {
        setRefundEnabled(false);
      } else {
        setRefundEnabled(true);
      }
    }
  }, [account, timer]);

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "refund";
    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "refund",
      data: {
        owner: user ? user.actor : null,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <Helmet>
        <title>Refund | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        {user && account ? (
          <>
            {account.refund_request ? (
              <>
                <table>
                  <tbody>
                    <tr>
                      <td>Refund Request:</td>
                      <td>
                        {/* {moment(account.refund_request.request_time).format(
                          "MMM DD YYYY, HH:mm:ss"
                        )} */}
                        {momentformat(
                          account.refund_request.request_time,
                          "MMM DD YYYY, HH:mm:ss"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>CPU Amount:</td>
                      <td>{account.refund_request.cpu_amount}</td>
                    </tr>
                    <tr>
                      <td>NET Amount:</td>
                      <td>{account.refund_request.net_amount}</td>
                    </tr>
                    <tr>
                      <td>Expected Refund:</td>
                      <td>
                        {/* {moment(account.refund_request.request_time)
                          .add(72, "hours")
                          .format("MMM DD YYYY, HH:mm:ss")} */}
                        {momentformat(
                          moment
                            .utc(account.refund_request.request_time)
                            .add(72, "hours")
                            .toISOString(),
                          "MMM DD YYYY, HH:mm:ss"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Time Left</td>
                      <td>
                        {refundEnabled
                          ? "Refund available!"
                          : moment.utc(account.refund_request.request_time)
                              .add(72, "hours")
                              .fromNow()}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <input
                  type="button"
                  value={`Refund`}
                  style={{
                    background: refundEnabled ? "#7D70F6" : "#404047",
                  }}
                  onClick={() => {
                    if (!refundEnabled) return;

                    handleClick();
                  }}
                />
              </>
            ) : (
              <p>No outstanding refund request for {user.actor}</p>
            )}
          </>
        ) : (
          <>
            <p>Please login to process manual refund.</p>
          </>
        )}
      </PageTransition>
    </>
  );
};

export default Refund;
