import { useState } from "react";
import { Helmet } from "react-helmet";
import PageTransition from "../../../../app/animations/page-transition";
import { Numeric} from "eosjs";

const FormatKeys = () => {
  const [formData, setFormData] = useState({
    public_key: "",
    private_key: "",
  });

  const formatkeys = async (type) => {
    switch (type) {
      case "public":
        var public_key = formData.public_key;
        var key = Numeric.stringToPublicKey(public_key);
        var format_key;
        if (public_key.startsWith("PUB_K1")) {
          format_key = Numeric.publicKeyToLegacyString(key);
        } else if (public_key.startsWith("EOS")) {
          format_key = Numeric.publicKeyToString(key);
        }

        if (format_key)
          setFormData((formData) => ({
            ...formData,
            public_key: format_key,
          }));
        break;
      case "private":
        var private_key = formData.private_key;
        var key = Numeric.stringToPrivateKey(private_key);
        var format_key;
        if (private_key.startsWith("PVT_K1")) {
          format_key = Numeric.privateKeyToLegacyString(key);
        } else {
          format_key = Numeric.privateKeyToString(key);
        }

        if (format_key)
          setFormData((formData) => ({
            ...formData,
            private_key: format_key,
          }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>Format Keys | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <form id="format-keys" className="forms_type active">
          <div className="field_row1">
            <label>Public Key (PUB_K1) to Old Format:</label>
            <input
              type="text"
              placeholder="New Public Key (PUB_K1)"
              value={formData.public_key}
              onChange={(event) =>
                setFormData({ ...formData, public_key: event.target.value })
              }
            />
          </div>
          <div className="field_row1">
            <input
              type="button"
              defaultValue="Format"
              onClick={() => formatkeys("public")}
            />
          </div>
          <div className="field_row1">
            <label>Private Key (PVT_K1) to Old Format:</label>
            <input
              type="text"
              placeholder="New Private Key (PVT_K1)"
              value={formData.private_key}
              onChange={(event) =>
                setFormData({ ...formData, private_key: event.target.value })
              }
            />
          </div>
          <div className="field_row1">
            <input
              type="button"
              defaultValue="Format"
              onClick={() => formatkeys("private")}
            />
          </div>
          {/* <div className="field_row1">
            <label>Mnemonic to Private Key:</label>
            <input type="text" placeholder="New Private Key (PVT_K1)" />
          </div>
          <div className="field_row1">
            <input
              type="button"
              defaultValue="Format"
              onClick={() => formatkeys()}
            />
          </div> */}
        </form>
      </PageTransition>
    </>
  );
};

export default FormatKeys;
