import * as waxjs from "@waxio/waxjs/dist";

const WaxcloudService = {
  provider: "waxcloud",
  wax: null,
  init(endpoint) {
    this.wax = new waxjs.WaxJS({
      rpcEndpoint: endpoint || "https://wax.greymass.com",
      returnTempAccounts: false
    });
  },
  connect() {
    const _self = this;
    return new Promise(function (resolve, reject) {
      if (_self.wax) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  },
  isAutoLoginAvailable() {
    return this.wax.isAutoLoginAvailable();
  },
  login() {
    return this.wax.login();
  },
  transaction(walletTransaction) {
    var actions = walletTransaction.actions;
    var account = walletTransaction.account;

    var authorization = [
        {
            actor: account.account,
            permission: account.permission
        }
    ];

    actions.forEach(function (action) {
        //Check if owner permission needed
        if (action.name === 'updateauth' && action.data.permission === 'owner') {
            authorization = [
                {
                    actor: account.account,
                    permission: 'owner'
                }
            ];
        }
        action.authorization = (action.authorization)?action.authorization:authorization;  
    }); 

    var waxApi = this.wax.api;

    return waxApi.transact(
      {
        actions: actions,
      },
      {
        broadcast: true,
        blocksBehind: 3,
        expireSeconds: 60 * 3,
      }
    );
  },
};

export default WaxcloudService;
