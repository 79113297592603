import { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { WalletContext } from "../../app/contexts/wallet-context";
import AccountSummary from "./components/account-summary";
import PageTransition from "../../app/animations/page-transition";
import config from "../../app/config";

const Wallet = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;

  const [activeMenu, setActiveMenu] = useState("transfer");
  let location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === "/wallet/transfer") {
      setActiveMenu("transfer");
    } else if (pathname.startsWith("/wallet/resources")) {
      setActiveMenu("resources");
    } else if (pathname.startsWith("/wallet/ram")) {
      setActiveMenu("ram");
    } else if (pathname.startsWith("/wallet/permissions")) {
      setActiveMenu("permissions");
    } else if (pathname.startsWith("/wallet/msig")) {
      setActiveMenu("msig");
    } else if (pathname.startsWith("/wallet/vote")) {
      setActiveMenu("vote");
    } else if (pathname.startsWith("/wallet/bidnames")) {
      setActiveMenu("bidnames");
    } else if (pathname.startsWith("/wallet/gbm-rewards")) {
      setActiveMenu("gbm-rewards");
    } else if (pathname.startsWith("/wallet/utilities")) {
      setActiveMenu("utilities");
    } else if (pathname.startsWith("/wallet/create-account")) {
      setActiveMenu("create-account");
    } else if (pathname.startsWith("/wallet/powerup")) {
      setActiveMenu("powerup");
    }
  }, [location]);

  return (
    <>
      <PageTransition>
        <AccountSummary />
        <div className="mid_sec">
          <div className="side_menu">
            <ul>
              <li className={`${activeMenu === "transfer" ? "active" : ""}`}>
                <Link to="/wallet/transfer">Transfer Tokens</Link>
              </li>
              <li className={`${activeMenu === "resources" ? "active" : ""}`}>
                <Link to="/wallet/resources">Manage CPU / NET</Link>
              </li>
              <li className={`${activeMenu === "ram" ? "active" : ""}`}>
                <Link to="/wallet/ram">Buy / Sell RAM</Link>
              </li>
              <li
                className={`${activeMenu === "create-account" ? "active" : ""}`}
              >
                <Link to="/wallet/create-account">Create Account</Link>
              </li>
              <li className={`${activeMenu === "permissions" ? "active" : ""}`}>
                <Link to="/wallet/permissions">
                  Manage keys and permissions
                </Link>
              </li>
              <li className={`${activeMenu === "msig" ? "active" : ""}`}>
                <Link to="/wallet/msig">MSIG</Link>
              </li>
              <li className={`${activeMenu === "vote" ? "active" : ""}`}>
                <Link to="/wallet/vote">Vote</Link>
              </li>
              {config.network_type === "mainnet" ||
              config.network_type === "testnet" ? (
                <li className={`${activeMenu === "bidnames" ? "active" : ""}`}>
                  <Link to="/wallet/bidnames">Name Bids</Link>
                </li>
              ) : null}
              {config.network_type === "mainnet" ? (
                <li
                  className={`${activeMenu === "gbm-rewards" ? "active" : ""}`}
                >
                  <Link to="/wallet/gbm-rewards">Claim Rewards</Link>
                </li>
              ) : null}
              <li
                className={`utilities ${
                  activeMenu === "utilities" ? "active" : ""
                }`}
              >
                <Link to="/wallet/utilities">Utilities</Link>
              </li>
              {config.network_type === "mainnet" ||
              config.network_type === "testnet" ? (
                <li
                  className={`powerup ${
                    activeMenu === "powerup" ? "active" : ""
                  }`}
                >
                  <Link to="/wallet/powerup">Powerup</Link>
                </li>
              ) : null}
            </ul>
          </div>
          <div className="form_sec">
            <Outlet />
          </div>
        </div>
      </PageTransition>
    </>
  );
};

export default Wallet;
