import config from "../config";

const numberFormat = (number) => {
  number = Number(number).toLocaleString(undefined);

  return number;
};

const tokenFormat = (token, precision) => {
  precision =
    typeof precision === "undefined" ? config.token_precision : precision;

  token = Number(parseFloat(token));
  token = token.toFixed(precision);

  token = Number(token).toLocaleString(undefined, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return token;
};

const tokenInputFormat = (token, precision) => {
  precision =
    typeof precision === "undefined" ? config.token_precision : precision;

  token = Number(parseFloat(token));
  token = token.toFixed(precision);

  token = Number(token).toLocaleString('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
  token = token.replace(/,/g,''); 

  return token;
};

const currencyFormat = (value, precision) => {
  precision = typeof precision === "undefined" ? 2 : precision;

  value = Number(value);
  value = value.toFixed(precision);

  value = Number(value).toLocaleString(undefined, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return value;
};

const bytesFormat = (bytes, precision) => {
  if (bytes < 0) return "∞ Bytes";

  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return "-";
  if (typeof precision === "undefined") precision = 1;
  var units = ["Bytes", "KB", "MB", "GB", "TB", "PB"],
    number = Math.floor(Math.log(bytes) / Math.log(1024));
  if (String(number).toLowerCase() === "-infinity") {
    number = 0;
  }
  return (
    (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
    " " +
    units[number]
  );
};

const microsecondsFormat = (microseconds, precision) => {
  if (microseconds < 0) return "∞ ms";

  var hours = (microseconds / (60 * 60 * 1000 * 1000)).toFixed(precision);
  var minutes = (microseconds / (60 * 1000 * 1000)).toFixed(precision);
  var seconds = (microseconds / (1000 * 1000)).toFixed(precision);
  var milliseconds = (microseconds / 1000).toFixed(precision);

  if (hours >= 1) {
    return hours + " " + "h";
  } else if (minutes >= 1) {
    return minutes + " " + "m";
  } else if (seconds >= 1) {
    return seconds + " " + "s";
  } else if (milliseconds > 0) {
    return milliseconds + " " + "ms";
  } else {
    return milliseconds + " " + "ms";
  }
};

const shortNumberFormat = (number) => {
  let abs = Math.abs(number);
  if (abs >= Math.pow(10, 12)) {
    // trillion
    number = (number / Math.pow(10, 12)).toFixed(2) + "T";
  } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
    // billion
    number = (number / Math.pow(10, 9)).toFixed(2) + "B";
  } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
    // million
    number = (number / Math.pow(10, 6)).toFixed(2) + "M";
  } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) {
    // thousand
    number = (number / Math.pow(10, 3)).toFixed(2) + "K";
  }else{
    number = number.toFixed(2);
  }

  return number;
};

export {
  numberFormat,
  tokenFormat,
  tokenInputFormat,
  currencyFormat,
  bytesFormat,
  microsecondsFormat,
  shortNumberFormat,
};
