import WaxcloudService from "./waxcloud";
import AnchorLinkService from "./anchor-link";
import UalWaxService from "./ual-wax";
import config from "../../config";
import UalAnchorService from "./ual-anchor";
// import { useContext } from "react";
// import { WalletContext } from "../../contexts/wallet-context";

const WalletService = {
  serviceProvider: null,
  walletTransaction: null,
  initProvider(provider) {
    switch (provider) {
      case WaxcloudService.provider:
        WaxcloudService.init(
          config.endpoints.sign || "https://wax.greymass.com"
        );
        this.serviceProvider = WaxcloudService;
        break;
      case AnchorLinkService.provider:
        AnchorLinkService.init(
          config.endpoints.sign || "https://wax.greymass.com"
        );
        this.serviceProvider = AnchorLinkService;
        break;
      case UalWaxService.provider:
        UalWaxService.init(config.endpoints.sign || "https://wax.greymass.com");
        this.serviceProvider = UalWaxService;
        break;
      case UalAnchorService.provider:
        UalAnchorService.init(
          config.endpoints.sign || "https://wax.greymass.com"
        );
        this.serviceProvider = UalAnchorService;
        break;
      default:
        break;
    }
  },
};

export default WalletService;
