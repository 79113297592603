const AdContainer = () => {
  return (
    <>
      <div className="ad_container">
        <div className="ad">
          <span id="ct_cikCXfFdoAE" />
        </div>
      </div>
    </>
  );
};

export default AdContainer;
