const CarbonNeutral = () => {
  return (
    <>
      <div className="chart_box">
        <div className="details">
          <span className="type">100% carbon neutral</span>
          <span className="value">211 tonnes of CO2</span>
          <span className="val_change">offset this year</span>
        </div>
      </div>
    </>
  );
};

export default CarbonNeutral;
