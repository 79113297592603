import ReactJson from "react-json-view";
import ReactJsonTheme from "../../../app/themes/react-json-theme";

const ContractAbi = ({accountAbi}) => {
  return <>
    <ReactJson src={accountAbi} 
    //theme="monokai" 
    theme={ReactJsonTheme}
    style={{padding:'15px'}}/>
  </>;
};

export default ContractAbi;
