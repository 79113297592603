import WaxcloudService from "../../app/services/wallet/waxcloud";
import AnchorLinkService from "../../app/services/wallet/anchor-link";
import UalWaxService from "../../app/services/wallet/ual-wax";
import UalAnchorService from "../../app/services/wallet/ual-anchor";
import { useContext } from "react";
import { WalletContext } from "../../app/contexts/wallet-context";
import config from "../../app/config";

const Modals = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;

  return (
    <>
      {/* {!user ? ( */}
      <>
        <div className="login_box" id="login-box" data-title="login-box">
          <div className="login_box_inner">
            <div className="v_box">
              <h3>Connect to Wallet</h3>
              <ul>
                {config.network_type === "mainnet" ? (
                  <li>
                    <button
                      type="button"
                      onClick={() =>
                        wallet.connectWallet(WaxcloudService.provider)
                      }
                    >
                      My Cloud Wallet
                    </button>
                  </li>
                ) : null}

                <li>
                  <button
                    type="button"
                    onClick={() =>
                      wallet.connectWallet(AnchorLinkService.provider)
                    }
                  >
                    Anchor
                  </button>
                </li>
              </ul>
              {/* <ul>
                <li>
                  <button
                    type="button"
                    onClick={() => wallet.connectWallet(UalWaxService.provider)}
                  >
                    UAL - My Cloud Wallet
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() =>
                      wallet.connectWallet(UalAnchorService.provider)
                    }
                  >
                    UAL - Anchor
                  </button>
                </li>
              </ul> */}
              <p>
                <a href="https://wallet.wax.io/" target="_blank">
                  Don't have wallet?
                </a>
              </p>
              <span
                className="login_close"
                data-title="login-box"
                onClick={wallet.hideWalletConnectModal}
              >
                <img src="/common/images/close-dark.png" alt="" />
              </span>
            </div>
          </div>
        </div>
      </>
      {/* ) : null} */}
    </>
  );
};

export default Modals;
