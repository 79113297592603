import { useDeferredValue, useEffect, useState } from "react";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import { createBrowserHistory } from "history";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";

const SearchList = ({
  accountAbi,
  items,
  setTableParams,
  tableParams,
  showItems,
}) => {
  const deferredItems = useDeferredValue(items, { timeoutMs: 0 });

  const intialItems = [{ scope: accountAbi.account_name }];

  if (!showItems) return;

  return (
    <>
      <div className="autocomplete-items">
        {tableParams.scope === "" && deferredItems.length === 0 ? (
          <>
            {intialItems.map((item, index) => (
              <div
                key={index}
                onMouseDown={() => {
                  setTableParams({
                    ...tableParams,
                    scope: item.scope,
                  });
                }}
              >
                {item.scope}
              </div>
            ))}
          </>
        ) : null}
        {deferredItems.map((item, index) => (
          <div
            key={index}
            onMouseDown={() => {
              setTableParams({
                ...tableParams,
                scope: item.scope,
              });
            }}
          >
            {item.scope}
          </div>
        ))}
      </div>
    </>
  );
};

const ContractTables = ({ accountAbi }) => {
  const history = createBrowserHistory();
  const [searchParams] = useSearchParams();
  const [activeTable, setActiveTable] = useState(
    accountAbi.abi.tables[0] ? accountAbi.abi.tables[0] : null
  );
  const [tableParams, setTableParams] = useState({
    code: accountAbi.account_name,
    scope: accountAbi.account_name,
    table: accountAbi.abi.tables[0]?.name,
    lower_bound: "",
    upper_bound: "",
    limit: 10,
    reverse: false,
  });
  const [tableRows, setTableRows] = useState({});

  useEffect(() => {
    if (searchParams.get("table")) {
      const table = accountAbi.abi.tables.find(
        (table) => table.name === searchParams.get("table")
      );
      setActiveTable(table);
    }

    setTableParams({
      ...tableParams,
      code: searchParams.get("code") || tableParams.code,
      scope: searchParams.get("scope") || tableParams.scope,
      table: searchParams.get("table") || tableParams.table,
      lower_bound: searchParams.get("lower_bound") || tableParams.lower_bound,
      upper_bound: searchParams.get("upper_bound") || tableParams.upper_bound,
      limit: searchParams.get("limit") || tableParams.limit,
    });
  }, []);

  useEffect(() => {
    loadTableRows();
  }, [tableParams.table]);

  const loadTableRows = (more = false) => {
    history.push({
      search: `?${createSearchParams(tableParams).toString()}`,
      hash: "contract-tables",
    });

    BlockchainApiManager.getTableRows({
      ...tableParams,
      lower_bound: more ? tableRows.next_key : tableParams.lower_bound,
    }).then((results) => {
      if (more) {
        setTableRows({
          more: results.more,
          next_key: results.next_key,
          rows: [...tableRows.rows, ...results.rows],
        });
      } else {
        setTableRows(results);
      }
    });
  };

  const [items, setItems] = useState([]);
  const [showItems, setShowItems] = useState(false);
  const [currentFocus, setCurrentFocus] = useState(-1);

  useEffect(() => {
    //if (tableParams.scope === "") return;

    let search_scope = tableParams.scope.toLowerCase();

    const timeoutId = setTimeout(() => {
      BlockchainApiManager.getTableByScope({
        code: tableParams.code,
        table: tableParams.table,
        lower_bound: search_scope,
        upper_bound: search_scope.padEnd(12, "z"),
        limit: 10,
      }).then((res) => {
        setItems(res.rows);
      });
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [tableParams.scope]);

  const handleBlur = (event) => {
    setShowItems(false);
  };

  const handleFocus = () => {
    setShowItems(true);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 40) {
      /*If the arrow DOWN key is pressed,
      increase the currentFocus variable:*/
      setCurrentFocus((currentFocus) => currentFocus + 1);
      /*and and make the current item more visible:*/
    } else if (e.keyCode === 38) {
      //up
      /*If the arrow UP key is pressed,
      decrease the currentFocus variable:*/
      setCurrentFocus((currentFocus) => currentFocus - 1);
      /*and and make the current item more visible:*/
    } else if (e.keyCode === 13) {
      /*If the ENTER key is pressed, prevent the form from being submitted,*/
      e.preventDefault();
      if (currentFocus > -1) {
        /*and simulate a click on the "active" item:*/
        //if (x) x[currentFocus].click();
        const scope = window
          .jQuery(
            window
              .jQuery(
                "#dropdownContractTablesScopeSearch ~ .autocomplete-items"
              )
              .children()[currentFocus]
          )
          .text();

        setTableParams({
          ...tableParams,
          scope: scope,
        });
        setCurrentFocus(-1);
        setShowItems(false);
      }
    }
  };

  useEffect(() => {
    const itemsLength = window
      .jQuery("#dropdownContractTablesScopeSearch ~ .autocomplete-items")
      .children().length;

    window
      .jQuery("#dropdownContractTablesScopeSearch ~ .autocomplete-items div")
      .removeClass("autocomplete-active");

    if (currentFocus >= itemsLength) setCurrentFocus(0);
    if (currentFocus < 0) setCurrentFocus(itemsLength - 1);

    window
      .jQuery(
        window
          .jQuery("#dropdownContractTablesScopeSearch ~ .autocomplete-items")
          .children()[currentFocus]
      )
      .addClass("autocomplete-active");
  }, [currentFocus]);

  return (
    <>
      <h3>Select Table</h3>
      {accountAbi.abi.tables.map((table, index) => (
        <a
          className={`more_btn ${
            activeTable?.name === table.name ? "active" : ""
          }`}
          style={{
            marginBottom: "10px",
            padding: "10px",
            width: "auto",
            cursor: "pointer",
            marginRight: "10px",
            fontSize: "12px",
            lineHeight: "14px",
            background:
              activeTable?.name === table.name ? "#7D70F6" : "#404047",
            textTransform: "none",
          }}
          key={index}
          onClick={() => {
            setActiveTable(table);
            setTableParams({
              ...tableParams,
              table: table.name,
              scope: accountAbi.account_name,
            });
          }}
        >
          {table.name}
        </a>
      ))}
      <div className="mid_sec">
        <div className="form_sec" style={{ width: "100%" }}>
          <div className="form_box  active">
            <h3>Enter Parameters</h3>
            <form>
              <div className="field_row">
                <div className="field_col">
                  <div className="autocomplete" style={{ width: "100%" }}>
                    <label>Scope</label>
                    <input
                      id="dropdownContractTablesScopeSearch"
                      type="text"
                      name="dropdownContractTablesScopeSearch"
                      placeholder="Scope"
                      autoComplete="off"
                      value={tableParams.scope}
                      onChange={(event) =>
                        setTableParams({
                          ...tableParams,
                          scope: event.target.value,
                        })
                      }
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onKeyDown={handleKeyDown}
                    />
                    <SearchList
                      accountAbi={accountAbi}
                      items={items}
                      setTableParams={setTableParams}
                      tableParams={tableParams}
                      showItems={showItems}
                    />
                  </div>
                </div>
                <div className="field_col">
                  <label>Lower Bound</label>
                  <input
                    type="text"
                    placeholder="Lower Bound"
                    value={tableParams.lower_bound}
                    onChange={(event) =>
                      setTableParams({
                        ...tableParams,
                        lower_bound: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="field_row">
                <div className="field_col">
                  <label>Upper Bound</label>
                  <input
                    type="text"
                    placeholder="Upper Bound"
                    value={tableParams.upper_bound}
                    onChange={(event) =>
                      setTableParams({
                        ...tableParams,
                        upper_bound: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="field_col">
                  <label>Limit</label>
                  <input
                    type="text"
                    placeholder="Limit"
                    value={tableParams.limit}
                    onChange={(event) =>
                      setTableParams({
                        ...tableParams,
                        limit: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="field_row">
                <div className="field_col">
                  <div
                    className="checkbox"
                    //style={{ display: "inline-flex" }}
                    onClick={(event) =>
                      setTableParams({
                        ...tableParams,
                        reverse: !tableParams.reverse,
                      })
                    }
                  >
                    <input
                      type="checkbox"
                      name="transfer"
                      className="check_input"
                      //id="transfer-stake"
                      //style={{ marginTop: "-10px" }}
                      checked={tableParams.reverse}
                    />
                    &nbsp;
                    <label
                      class="check_label"
                      //for="transfer-stake"
                      style={{ cursor: "pointer" }}
                    >
                      Reverse
                    </label>
                  </div>
                </div>
              </div>
              <div className="field_row">
                <div className="field_col ">
                  <input
                    type="button"
                    value="Refresh"
                    onClick={() => loadTableRows()}
                  />
                </div>
              </div>
            </form>
            <h3>Table Result</h3>
            {tableRows && tableRows.rows && tableRows.rows.length ? (
              <>
                <div className="table-wrapper" style={{ overflowX: "auto" }}>
                  <table className="large">
                    <thead>
                      <tr>
                        <th>#</th>
                        {Object.keys(tableRows.rows[0]).map(
                          (header, headerIndex) => (
                            <th key={headerIndex}>{header}</th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tableRows.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>{rowIndex + 1}</td>
                          {Object.keys(row).map((rowKey, rowKeyIndex) => (
                            <td key={rowKeyIndex}>
                              {typeof row[rowKey] === "object"
                                ? JSON.stringify(row[rowKey])
                                : row[rowKey]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {tableRows.more ? (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <a
                      href="#"
                      className="more_btn"
                      onClick={(e) => {
                        e.preventDefault();
                        loadTableRows(true);
                      }}
                    >
                      view more
                    </a>
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <p>No Data</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractTables;
