import { Helmet } from "react-helmet";
import PageTransition from "../../../../app/animations/page-transition";
import { Numeric } from "eosjs";
import { generateKeyPair } from "eosjs/dist/eosjs-key-conversions";
import * as buffer from "buffer";
import copy from "copy-to-clipboard";
import { useState } from "react";
import { useEffect } from "react";
window.Buffer = buffer.Buffer;

const Keys = () => {
  const [formData, setFormData] = useState({
    public_key: "",
    private_key: "",
  });

  const generateNewKeys = () => {
    const keyPair = generateKeyPair(Numeric.KeyType.k1, {
      secureEnv: true,
    });

    setFormData({
      ...formData,
      public_key: keyPair.publicKey.toString("hex"),
      private_key: keyPair.privateKey.toString("hex"),
    });
  };

  const copyToClipboard = (type) => {
    switch (type) {
      case "public":
        copy(formData.public_key);
        break;
      case "private":
        copy(formData.private_key);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    generateNewKeys();
  }, []);

  return (
    <>
      <Helmet>
        <title>Keys | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <form id="keys" className="forms_type active">
          <div className="field_row1">
            <label>Public Key:</label>
            <div className="clipboard">
              <input type="text" value={formData.public_key} />
              <button
                className="copy_btn"
                type="button"
                onClick={() => copyToClipboard("public")}
              >
                <i className="fa fa-clipboard" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="field_row1">
            <label>Private Key:</label>
            <div className="clipboard">
              <input type="text" value={formData.private_key} />
              <button
                className="copy_btn"
                type="button"
                onClick={() => copyToClipboard("private")}
              >
                <i className="fa fa-clipboard" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="field_row1">
            <span className="alert">
              <i className="fa fa-exclamation-triangle" aria-hidden="true" />
              Please save and safely store your private keys.
            </span>
          </div>
          <div className="field_row1">
            <input
              type="button"
              defaultValue="Generate New Keys"
              onClick={() => generateNewKeys()}
            />
          </div>
        </form>
      </PageTransition>
    </>
  );
};

export default Keys;
