import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import BlockchainApiManager from "../../../../app/services/blockchainApiManager";
import ClaimBids from "./namebids/claimbids";
import CurrentBids from "./namebids/currentbids";

const Namebids = () => {
  const [activeTab, setActiveTab] = useState("currentBids");
  const [currentBids, setCurrentBids] = useState(null);
  const [claimBids, setClaimBids] = useState(null);

  const {
    isLoading,
    error,
    data: nameBids,
  } = useQuery(
    [`nameBids`],
    () => {
      return new Promise(async (resolve, reject) => {
        let more = false;
        let lower_bound = "";
        let results = { rows:[]};
        do {
          const response = await BlockchainApiManager.getTableRows({
            code: "eosio",
            scope: "eosio",
            table: "namebids",
            limit: -1,
            lower_bound: lower_bound,
          });

          for(let row of response.rows){
            results.rows.push(row);
          }
        
          more = response && response.more ? true : false;
          lower_bound = response && response.next_key ? response.next_key : "";
        } while (more);

        resolve(results);
      });
    },
    {}
  );

  useEffect(() => {
    if (!nameBids) return;

    let currentBidsRows = [...nameBids.rows];
    currentBidsRows = currentBidsRows.filter((row) => row.high_bid >= 0);
    currentBidsRows.sort((rowA, rowB) => rowB.high_bid - rowA.high_bid);

    setCurrentBids(currentBidsRows);

    let claimBidsRows = [...nameBids.rows];
    claimBidsRows = claimBidsRows.filter((row) => row.high_bid < 0);
    claimBidsRows.sort(
      (rowA, rowB) =>
        moment(rowB.last_bid_time).unix() - moment(rowA.last_bid_time).unix()
    );

    setClaimBids(claimBidsRows);
  }, [nameBids]);

  if (error) {
    return (
      <>
        <p>Error fetching</p>
      </>
    );
  }

  // if (isLoading) {
  //   return (
  //     <>
  //       <p>Loading...</p>
  //     </>
  //   );
  // }

  return (
    <>
      <ul className="tabbbing">
        <li className={`${activeTab === "currentBids" ? "active" : ""}`}>
          <a href="#tab1" onClick={() => setActiveTab("currentBids")}>
            Current Bids
          </a>
        </li>
        <li className={`${activeTab === "claimBids" ? "active" : ""}`}>
          <a href="#tab2" onClick={() => setActiveTab("claimBids")}>
            Bids to claim
          </a>
        </li>
      </ul>
      <div
        className={`tab ${activeTab === "currentBids" ? "active" : null}`}
        id="currentBids"
      >
        <p>
          Please use the{" "}
          <Link to={`/wallet/bidnames`} style={{ textDecoration: "underline" }}>
            Name Bids wallet feature{" "}
          </Link>
          to bid on a new name
        </p>
        <br />
        <br />
        <CurrentBids currentBids={currentBids} />
      </div>
      <div
        className={`tab ${activeTab === "claimBids" ? "active" : null}`}
        id="claimBids"
      >
        <ClaimBids claimBids={claimBids} />
      </div>
    </>
  );
};

export default Namebids;
