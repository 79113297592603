import React, { createContext, useCallback, useEffect, useState } from "react";
import BlockchainApiManager from "../services/blockchainApiManager";
import WalletService from "../services/wallet";
import AnchorLinkService from "../services/wallet/anchor-link";
import WaxcloudService from "../services/wallet/waxcloud";
import moment from "moment";
import { useQuery } from "react-query";
import ApiManager from "../services/apiManager";
import FetchManager from "../services/fetchManager";
import { getShortTrxId } from "../utilities/transaction";
import config from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import UalWaxService from "../services/wallet/ual-wax";
import UalAnchorService from "../services/wallet/ual-anchor";

const WalletContext = createContext();
// const initialBalances = [
//   // {
//   //   contract: "eosio.token",
//   //   decimals: config.token_precision,
//   //   currency: config.token_symbol,
//   //   amount: "0.00000000",
//   // },
// ];
const WalletContextProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(null);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [initialBalances, setInitialBalances] = useState([]);
  const [walletAccount, setWalletAccount] = useState({
    account: null,
    userres: null,
    delband: null,
    balances: initialBalances,
    accInfo: null,
  });
  const [transactionAlert, setTransactionAlert] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [multisig, setMultisig] = useState({
    mode: false,
    walletTransaction: null,
  });
  const [globalQueryParams, setGlobalQueryParams] = useState({});
  const [rammarket, setRamMarket] = useState(null);
  const [ramPrice, setRamPrice] = useState(0);

  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setWalletAccount({
      ...walletAccount,
      balances: [
        {
          contract: "eosio.token",
          decimals: config.token_precision,
          currency: config.token_symbol,
          amount: "0.00000000",
        },
      ],
    });

    const usersData =
      JSON.parse(localStorage.getItem(`${config.app_name}-users`)) || [];
    setUsers([...usersData]);

    const userData = JSON.parse(
      localStorage.getItem(`${config.app_name}-user`)
    );
    if (userData) {
      switch (userData.provider) {
        case WaxcloudService.provider:
          //connectWallet(WaxcloudService.provider, true);
          //Instead of autoconnect set the user and continue for waxcloud
          wallet.login({
            actor: userData.actor,
            permission: userData.permission,
            provider: userData.provider,
          });
          break;
        case AnchorLinkService.provider:
          connectWallet(AnchorLinkService.provider, true);
          break;
        case UalWaxService.provider:
          // wallet.login({
          //   actor: userData.actor,
          //   permission: userData.permission,
          //   provider: userData.provider,
          // });
          connectWallet(UalWaxService.provider, true);
          break;
        case UalAnchorService.provider:
          connectWallet(UalAnchorService.provider, true);
          break;
        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (config.network_type === "localnet" && !config.chain_id) return;

    if (!user) {
      setWalletAccount({
        ...walletAccount,
        account: null,
        userres: null,
        delband: null,
        balances: initialBalances,
        accInfo: null,
      });
    } else {
      BlockchainApiManager.getAccount(user.actor).then((account) => {
        setWalletAccount((walletAccount) => ({
          ...walletAccount,
          account: account,
        }));
      });

      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: user.actor,
        table: "delband",
        limit: -1,
      }).then((response) => {
        setWalletAccount((walletAccount) => ({
          ...walletAccount,
          delband: response,
        }));
      });

      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: user.actor,
        table: "userres",
        limit: -1,
      }).then((response) => {
        setWalletAccount((walletAccount) => ({
          ...walletAccount,
          userres: response,
        }));
      });

      BlockchainApiManager.getBalances(user.actor).then((response) => {
        const balances = response.balances || null;
        setWalletAccount((walletAccount) => ({
          ...walletAccount,
          balances: balances,
        }));
      });

      BlockchainApiManager.getAccInfo(user.actor).then((response) => {
        const accInfo = response || null;
        setWalletAccount((walletAccount) => ({
          ...walletAccount,
          accInfo: accInfo,
        }));
      });
    }

    //setAccount(account)
  }, [user, refresh]);

  const login = (data) => {
    var userData = { actor: "", permission: "", provider: "" };
    userData = Object.assign(userData, data);

    setUser(userData);
    localStorage.setItem(`${config.app_name}-user`, JSON.stringify(userData));
    hideWalletConnectModal();

    var usersData =
      JSON.parse(localStorage.getItem(`${config.app_name}-users`)) || [];
    var userExists = usersData.find(
      (user) =>
        user.actor === userData.actor &&
        user.permission === userData.permission &&
        user.provider === userData.provider
    );
    if (!userExists) {
      usersData = [...usersData, userData];
      setUsers(usersData);
      localStorage.setItem(
        `${config.app_name}-users`,
        JSON.stringify(usersData)
      );
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.setItem(`${config.app_name}-user`, null);
  };

  const switchUser = async (user) => {
    var provider = user.provider;

    WalletService.initProvider(provider);
    switch (provider) {
      case AnchorLinkService.provider:
        var anchorLinkSessions =
          await WalletService.serviceProvider.listSessions();
        var anchorLinkSession = anchorLinkSessions.find(
          (anchorLinkSession) =>
            String(anchorLinkSession.auth.actor) === user.actor &&
            String(anchorLinkSession.auth.permission) === user.permission
        );

        await WalletService.serviceProvider.restoreSession(anchorLinkSession);

        break;
      default:
        break;
    }

    wallet.login(user);
  };

  const removeUser = (userData) => {
    if (
      user &&
      user.actor === userData.actor &&
      user.permission === userData.permission &&
      user.provider === userData.provider
    ) {
      logout();
    }

    const userIndex = users.findIndex(
      (user) =>
        user.actor === userData.actor &&
        user.permission === userData.permission &&
        user.provider === userData.provider
    );

    if (userIndex >= 0) {
      users.splice(userIndex, 1);

      setUsers([...users]);
      localStorage.setItem(`${config.app_name}-users`, JSON.stringify(users));
    }
  };

  const showWalletConnectModal = () => {
    window.jQuery("body,html").addClass("popup_view");
    window.jQuery("#login-box").addClass("active");
  };

  const hideWalletConnectModal = () => {
    window.jQuery("body,html").removeClass("popup_view");
    window.jQuery("#login-box").removeClass("active");
  };

  const connectWallet = useCallback(async (provider, autoConnect = false) => {
    WalletService.initProvider(provider);
    switch (provider) {
      case WaxcloudService.provider:
        var waxcloudConnected = await WalletService.serviceProvider.connect();
        if (waxcloudConnected !== true) {
          return;
        }

        var isAutoLoginAvailable = autoConnect
          ? await WalletService.serviceProvider.isAutoLoginAvailable()
          : null;

        var waxcloudUserAccount;
        if (isAutoLoginAvailable) {
          waxcloudUserAccount = WalletService.serviceProvider.wax.userAccount;
        } else {
          waxcloudUserAccount = await WalletService.serviceProvider.login();
        }
        if (!waxcloudUserAccount) {
          return;
        }

        wallet.login({
          actor: waxcloudUserAccount,
          permission: "active",
          provider: provider,
        });
        break;
      case AnchorLinkService.provider:
        var anchorLinkConnected = await WalletService.serviceProvider.connect();
        if (anchorLinkConnected !== true) {
          return;
        }

        var anchorLinkSession = autoConnect
          ? await WalletService.serviceProvider.restoreSession()
          : null;

        if (!anchorLinkSession) {
          var identity = await WalletService.serviceProvider.login();
          anchorLinkSession = identity.session;
        }

        if (!anchorLinkSession) {
          return;
        }

        WalletService.serviceProvider.session = anchorLinkSession; //Set session for new change

        wallet.login({
          actor: String(anchorLinkSession.auth.actor),
          permission: String(anchorLinkSession.auth.permission),
          provider: provider,
        });
        break;
      case UalWaxService.provider:
        var ualWaxConnected = await WalletService.serviceProvider.connect();
        if (ualWaxConnected !== true) {
          return;
        }

        var isAutoLoginAvailable = autoConnect
          ? await WalletService.serviceProvider.isAutoLoginAvailable()
          : null;

        var [ualWaxUser] = await WalletService.serviceProvider.login();

        if (!ualWaxUser) {
          return;
        }

        wallet.login({
          actor: ualWaxUser.accountName,
          permission: "active",
          provider: provider,
        });
        break;
      case UalAnchorService.provider:
        var ualAnchorConnected = await WalletService.serviceProvider.connect();
        if (ualAnchorConnected !== true) {
          return;
        }

        var ualAnchorSession = autoConnect
          ? await WalletService.serviceProvider.restoreSession()
          : null;

        if (!ualAnchorSession) {
          var [ualAnchorUser] = await WalletService.serviceProvider.login();
          ualAnchorSession = ualAnchorUser.session;
        }

        if (!ualAnchorSession) {
          return;
        }

        wallet.login({
          actor: String(ualAnchorSession.auth.actor),
          permission: String(ualAnchorSession.auth.permission),
          provider: provider,
        });
        break;
      default:
        break;
    }
  }, []);

  const submitTransaction = useCallback(
    async (walletTransaction) => {
      //Rediect to  Msig Page if ON and doing any tx
      if (multisig.mode === true && location.pathname !== "/wallet/msig") {
        walletTransaction.type = "msig";

        walletTransaction.actions.forEach(function (action) {
          action.authorization = [{ actor: "", permission: "" }];
        });

        setMultisig((multisig) => ({
          ...multisig,
          walletTransaction: Object.assign({}, walletTransaction),
        }));
        navigate("/wallet/msig");
        return;
      }

      if (!user) {
        showWalletConnectModal();
        return;
      }

      const { provider } = user;

      WalletService.initProvider(provider);
      switch (provider) {
        case WaxcloudService.provider:
          var waxcloudConnected = await WalletService.serviceProvider.connect();
          if (waxcloudConnected !== true) {
            return;
          }

          var isAutoLoginAvailable =
            await WalletService.serviceProvider.isAutoLoginAvailable();

          var waxcloudUserAccount;
          if (isAutoLoginAvailable) {
            waxcloudUserAccount = WalletService.serviceProvider.wax.userAccount;
          } else {
            waxcloudUserAccount = await WalletService.serviceProvider.login();
          }
          if (!waxcloudUserAccount) {
            return;
          }

          walletTransaction.account = {
            account: waxcloudUserAccount,
            permission: "active",
          };

          WalletService.serviceProvider
            .transaction(walletTransaction)
            .then(function (tx) {
              transactionSuccess(tx, walletTransaction);
            })
            .catch(function (error) {
              transactionError(error, walletTransaction);
            });

          break;
        case AnchorLinkService.provider:
          var anchorLinkConnected =
            await WalletService.serviceProvider.connect();
          if (anchorLinkConnected !== true) {
            return;
          }

          var anchorLinkSession =
            await WalletService.serviceProvider.restoreSession();
          if (!anchorLinkSession) {
            var identity = await WalletService.serviceProvider.login();
            anchorLinkSession = identity.session;
          }

          if (!anchorLinkSession) {
            return;
          }

          WalletService.serviceProvider.session = anchorLinkSession; //Set session for new change

          walletTransaction.account = {
            account: String(anchorLinkSession.auth.actor),
            permission: String(anchorLinkSession.auth.permission),
          };

          WalletService.serviceProvider
            .transaction(walletTransaction)
            .then(function (result) {
              const tx = { transaction_id: String(result.processed.id) };
              transactionSuccess(tx, walletTransaction);
            })
            .catch(function (error) {
              transactionError(error, walletTransaction);
            });

          break;
        case UalWaxService.provider:
          var ualWaxConnected = await WalletService.serviceProvider.connect();
          if (ualWaxConnected !== true) {
            return;
          }

          //var isAutoLoginAvailable =
          //  await WalletService.serviceProvider.isAutoLoginAvailable();
          //console.log(WalletService.serviceProvider);

          var [ualWaxUser] = await WalletService.serviceProvider.login();
          //console.log("ualWaxUser", ualWaxUser);
          if (!ualWaxUser) {
            return;
          }

          walletTransaction.account = {
            account: ualWaxUser.accountName,
            permission: "active",
          };

          WalletService.serviceProvider
            .transaction(walletTransaction)
            .then(function (tx) {
              transactionSuccess(tx, walletTransaction);
            })
            .catch(function (error) {
              transactionError(error, walletTransaction);
            });

          break;
        case UalAnchorService.provider:
          var ualAnchorConnected =
            await WalletService.serviceProvider.connect();
          if (ualAnchorConnected !== true) {
            return;
          }

          var ualAnchorSession =
            await WalletService.serviceProvider.restoreSession();

          if (!ualAnchorSession) {
            var [ualAnchorUser] = await WalletService.serviceProvider.login();
            ualAnchorSession = ualAnchorUser.session;
          }

          if (!ualAnchorSession) {
            return;
          }

          WalletService.serviceProvider.session = ualAnchorSession; //Set session for new change

          walletTransaction.account = {
            account: String(ualAnchorSession.auth.actor),
            permission: String(ualAnchorSession.auth.permission),
          };

          WalletService.serviceProvider
            .transaction(walletTransaction)
            .then(function (result) {
              const tx = { transaction_id: String(result.processed.id) };
              transactionSuccess(tx, walletTransaction);
            })
            .catch(function (error) {
              transactionError(error, walletTransaction);
            });

          break;
        default:
          break;
      }
    },
    [user, multisig, location]
  );

  const transactionSuccess = (tx, walletTransaction) => {
    let message = `<a href="/transaction/${tx.transaction_id}" target="_blank">Transaction success - ${getShortTrxId(tx.transaction_id)}...</a>`;

    //Show extra message for MSIG
    if (
      walletTransaction.actions &&
      walletTransaction.actions[0].account === "eosio.msig" &&
      walletTransaction.actions[0].name === "propose"
    ) {
      message +=
        "<br/>" +
        `<a href="/msig/${walletTransaction.actions[0].data.proposer}/${walletTransaction.actions[0].data.proposal_name}" target="_blank" style="text-decoration: underline;">Show MSIG - ${walletTransaction.actions[0].data.proposal_name}</a>`;
    }

    setTransactionAlert({
      type: "success",
      message: message,
      walletTransaction: walletTransaction,
    });
    setTimeout(() => {
      setRefresh(moment().utc().format());
    }, 1000);
  };

  const transactionError = (error, walletTransaction) => {
    console.log('transactionError:', JSON.stringify(error));
    console.log(error)
    let message = "";
    try {
      error = JSON.parse(error);
    } catch (err) { }

    let errorV2 = {};
    try {
      errorV2 = JSON.stringify(error);
      errorV2 = JSON.parse(errorV2);
    } catch (err) { }

    if (errorV2.details[0]) {
      message += "Transaction failed - " + errorV2.details[0].message;
    } else if (error.code === 500) {
      message = "Transaction failed ";
      message += "<br/>" + error.code + ": " + error.message;
      if (error.error.details[0]) {
        message += "<br/>" + error.error.details[0].message;
      }
    } else if (error.message) {
      message = "Transaction failed - " + error.message;
    } else {
      message = "Transaction failed - " + "An unknown error occurred. Please try again later.";
    }

    setTransactionAlert({
      type: "error",
      message: message,
      walletTransaction: walletTransaction,
    });
  };

  useQuery(
    [`token_price`],
    () => {
      if (config.network_type === "localnet") {
        setTokenPrice(0);
      } else {
        // FetchManager.get("https://www.binance.com", {
        //   path: "/api/v3/ticker/price?symbol=WAXPUSDT",
        // })s
        //   .then((data) => {
        //     setTokenPrice(data.price);
        //   })
        //   .catch(() => {});
        FetchManager.get("https://api.coingecko.com", {
          path: "/api/v3/simple/price?ids=WAX&vs_currencies=USD",
        })
          .then((data) => {
            setTokenPrice(data.wax.usd);
          })
          .catch(() => { });
      }
    },
    {
      refetchInterval: 30 * 60 * 1000,
    }
  );

  useQuery(
    [`rammarket`],
    () => {
      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: "eosio",
        table: "rammarket",
        lower_bound: "",
        upper_bound: "",
        limit: 1,
      }).then((response) => {
        setRamMarket(response);
        if (response && response.rows) {
          const connectorBalance = parseFloat(response.rows[0].quote.balance);
          const smartTokensOutstandingSupply = parseFloat(
            response.rows[0].base.balance
          );
          const ramPrice = connectorBalance / smartTokensOutstandingSupply;
          setRamPrice(ramPrice);
        }
      });
    },
    {}
  );

  const wallet = {
    refresh: refresh,
    user: user,
    users: users,
    walletAccount: walletAccount,
    login: login,
    logout: logout,
    switchUser: switchUser,
    showWalletConnectModal: showWalletConnectModal,
    hideWalletConnectModal: hideWalletConnectModal,
    connectWallet: connectWallet,
    submitTransaction: submitTransaction,
    transactionAlert: transactionAlert,
    setTransactionAlert: setTransactionAlert,
    multisig: multisig, //{ mode: false, walletTransaction: null }
    setMultisig: setMultisig,
    tokenPrice: tokenPrice,
    globalQueryParams: globalQueryParams,
    setGlobalQueryParams: setGlobalQueryParams,
    removeUser: removeUser,
    rammarket: rammarket,
    ramPrice: ramPrice,
  };

  return (
    <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
  );
};

export { WalletContext, WalletContextProvider };
