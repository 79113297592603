import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import ApiManager from "../../../../app/services/apiManager";
import config from "../../../../app/config";
import {
  randomNoImage,
  randomNoImageByName,
} from "../../../../app/utilities/random";
import LocalnetManager from "../../../../app/services/localnetManager";

const Producers = ({ chainInfo }) => {
  const {
    //  isLoading,
    // error,
    data: producers,
  } = useQuery(
    [`producers`],
    () =>
      config.network_type === "localnet"
        ? LocalnetManager.getProducers()
        : ApiManager.getProducers(),
    {}
  );

  useEffect(() => {}, []);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Guilds</th>
            <th>Status</th>
            <th>Total Votes (WAX)</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {!producers &&
            Array(5)
              .fill(0)
              .map((item, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              ))}
          {producers &&
            producers.map((producer, index) => (
              <tr key={index}>
                <td>{producer.rank}</td>
                <td>
                  <div style={{ display: "inline-flex" }}>
                    <img
                      src={`${
                        producer.logo
                          ? `${config.imagekit.default_url_endpoint}/tr:w-50/${producer.logo}`
                          : `/${randomNoImageByName(producer.owner)}`
                      }`}
                      alt=""
                      // width={30}
                      // height={30}
                      style={{ width: "30px" }}
                    />
                    <Link
                      to={`/account/${producer.owner}`}
                      style={{ position: "relative", left: "10px" }}
                    >
                      {producer.owner}
                    </Link>
                  </div>
                </td>
                <td>
                  {chainInfo &&
                  producer.owner === chainInfo.head_block_producer ? (
                    <span className="type">Producing</span>
                  ) : (
                    <>
                      {producer.rank <= 21 ? (
                        <span className="type">Top 21</span>
                      ) : (
                        <span className="type">Standby</span>
                      )}
                    </>
                  )}
                </td>
                <td>{producer.actual_votes.toLocaleString()}</td>
                <td>{producer.country_name || producer.location_name}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default Producers;
