import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageTransition from "../../../../app/animations/page-transition";
import { WalletContext } from "../../../../app/contexts/wallet-context";

const Advanced = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;
  const { account } = wallet.walletAccount;
  const [formData, setFormData] = useState({
    current_permission: "",
    permission: "",
    parent: "",
    threshold: 1,
    authorities: [],
    authorized_by: ""
  });

  useEffect(() => {
    if (!account) return;

    const selectedPermission = account.permissions.find(
      (permission) => permission.perm_name === formData.current_permission
    );


    const authorities = [];
    if (selectedPermission) {
      selectedPermission.required_auth.keys.forEach((authority) => {
        authorities.push({
          type: "key",
          authority: authority.key,
          weight: authority.weight,
        });
      });
      selectedPermission.required_auth.accounts.forEach((authority) => {
        authorities.push({
          type: "account",
          authority:
            authority.permission.actor + "@" + authority.permission.permission,
          weight: authority.weight,
        });
      });
      selectedPermission.required_auth.waits.forEach((authority) => {
        authorities.push({
          type: "wait",
          authority: authority.wait_sec,
          weight: authority.weight,
        });
      });
    }

    setFormData({
      ...formData,
      permission: selectedPermission ? selectedPermission.perm_name : "",
      parent: selectedPermission ? selectedPermission.parent : "active",
      threshold: selectedPermission
        ? selectedPermission.required_auth.threshold
        : 1,
      authorities: authorities,
    });
  }, [formData.current_permission]);

  const addAuthority = () => {
    setFormData({
      ...formData,
      authorities: [
        ...formData.authorities,
        { type: "key", authority: "", weight: 1 },
      ],
    });
  };

  const removeAuthority = (index) => {
    formData.authorities.splice(index, 1);
    setFormData({
      ...formData,
    });
  };

  const handleUpdateAuth = async () => {
    var walletTransaction = {};
    walletTransaction.type = "updateauth";

    walletTransaction.actions = [];

    var action = {
      account: "eosio",
      name: "updateauth",
      data: {
        account: user ? user.actor : null,
        permission: formData.permission,
        parent: formData.parent,
        auth: {
          threshold: formData.threshold,
          keys: [],
          accounts: [],
          waits: [],
        },
        authorized_by: formData.authorized_by || null
      },
    };

    formData.authorities.forEach((authority) => {
      switch (authority.type) {
        case "key":
          action.data.auth.keys.push({
            key: authority.authority,
            weight: authority.weight,
          });
          // Sort the array alphabetically based on key
          action.data.auth.keys.sort((a, b) => a.key.localeCompare(b.key));
          break;
        case "account":
          var [actor, permission] = authority.authority.split("@");
          action.data.auth.accounts.push({
            permission: { actor: actor, permission: permission },
            weight: authority.weight,
          });
          // Sort the array alphabetically based on permission.actor
          action.data.auth.accounts.sort((a, b) => a.permission.actor.localeCompare(b.permission.actor));
          break;
        case "wait":
          action.data.auth.waits.push({
            wait_sec: Number(authority.authority),
            weight: authority.weight,
          });
          break;
        default:
          break;
      }
    });

    walletTransaction.actions.push(action);


    wallet.submitTransaction(walletTransaction);
  };

  const handleDeleteAuth = (permission) => {
    var walletTransaction = {};
    walletTransaction.type = "deleteauth";

    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "deleteauth",
      data: {
        account: user ? user.actor : null,
        permission: permission,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <Helmet>
        <title>Advanced Permissions | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <form id="advanced" className="forms_type active">
          <div className="field_row">
            <div className="field_col">
              <label>Change Permission</label>
              <select
                className="cars"
                value={formData.current_permission}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    current_permission: event.target.value,
                  })
                }
              >
                <option value="">--New--</option>
                {account &&
                  account.permissions.map((permission, index) => (
                    <option key={index} value={permission.perm_name}>
                      {permission.perm_name}
                    </option>
                  ))}
              </select>
            </div>
            {!["","owner", "active"].includes(formData.current_permission) ? (
              <>
                <div className="field_col">
                  <label>&nbsp;</label>
                  <input
                    type="button"
                    className="delete_pubkey"
                    value={`Delete ${formData.current_permission}`}
                    onClick={() => {
                      handleDeleteAuth(formData.current_permission);
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="field_row no-space">
            <div className="field_col">
              <div className="field_row">
                <div className="field_col">
                  <label>Permission name</label>
                  <input
                    type="text"
                    value={formData.permission}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        permission: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="field_col">
                  <label>Parent</label>
                  <input
                    type="text"
                    value={formData.parent}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        parent: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="field_col">
              <div className="field_row">
                <div className="field_col">
                  <label>Threshold</label>
                  <input
                    type="number"
                    value={formData.threshold}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        threshold: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="field_col">
                  <label>Authorized by (optional)</label>
                  <input
                    type="text"
                    value={formData.authorized_by}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        authorized_by: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {formData.authorities.map((authority, index) => (
            <div className="field_row no-space" key={index}>
              <div className="field_row_inner del_row1">
                <div className="field_col">
                  <div className="field_row">
                    <div className="field_col">
                      <label>Type</label>
                      <select
                        className="cars"
                        value={authority.type}
                        onChange={(event) => {
                          formData.authorities[index].type = event.target.value;
                          formData.authorities[index].authority = "";
                          setFormData({
                            ...formData,
                          });
                        }}
                      >
                        <option value="key">Key</option>
                        <option value="account">Account</option>
                        <option value="wait">Wait secs</option>
                      </select>
                    </div>
                    {authority.type === "key" ? (
                      <div className="field_col">
                        <label>Public key</label>
                        <input
                          type="text"
                          value={authority.authority}
                          onChange={(event) => {
                            formData.authorities[index].authority =
                              event.target.value;
                            setFormData({
                              ...formData,
                            });
                          }}
                        />
                      </div>
                    ) : null}
                    {authority.type === "account" ? (
                      <div className="field_col">
                        <label>Actor@permission</label>
                        <input
                          type="text"
                          value={authority.authority}
                          onChange={(event) => {
                            formData.authorities[index].authority =
                              event.target.value;
                            setFormData({
                              ...formData,
                            });
                          }}
                        />
                      </div>
                    ) : null}
                    {authority.type === "wait" ? (
                      <div className="field_col">
                        <label>Time in seconds to wait</label>
                        <input
                          type="number"
                          value={authority.authority}
                          onChange={(event) => {
                            formData.authorities[index].authority =
                              event.target.value;
                            setFormData({
                              ...formData,
                            });
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="field_col">
                  <div className="field_row">
                    <div className="field_col">
                      <label>Weight</label>
                      <input 
                        type="number" 
                        value={authority.weight} 
                        onChange={(event) => {
                          formData.authorities[index].weight =
                            event.target.value;
                          setFormData({
                            ...formData,
                          });
                        }}
                      />
                    </div>
                    <div className="field_col del_col">
                      <label>&nbsp;</label>
                      <input
                        type="button"
                        className="delete_pubkey"
                        value="Delete"
                        onClick={() => {
                          removeAuthority(index);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="field_row">
            <input
              type="button"
              className="clone_perm"
              value="Add NEW +"
              onClick={() => {
                addAuthority();
              }}
            />
          </div>
          <div className="field_row1">
            <input
              type="button"
              //defaultValue="UPDATE KEYS"
              value={
                formData.current_permission !== ""
                  ? "Update permission"
                  : "Add new permission"
              }
              onClick={handleUpdateAuth}
            />
          </div>
        </form>
      </PageTransition>
    </>
  );
};

export default Advanced;
