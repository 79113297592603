export let config_localnet = {
    network: "localnet",
    network_type: "localnet",
    chain_id: null,
    endpoints: {
        default: "",
        sign: "",
        state: {

        },
        block: {

        },
        transaction: {

        },
        history: {

        },
        transactionV2: {

        },
        historyV2: {

        },
        lightapi: {

        },
        nfts: {
            simpleassets: null,
            atomicassets: null,
        },
    },
    socket: {
        url: null,
        path: null,
    },
    api: {
        url: null,
        path: null,
    },
};

export default config_localnet;
