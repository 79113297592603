import React, { useState, useDeferredValue, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlockchainApiManager from "../../app/services/blockchainApiManager";

const SearchList = ({ items, setKeywords, keywords, showItems }) => {
  const deferredItems = useDeferredValue(items, { timeoutMs: 0 });
  let navigate = useNavigate();

  const intialItems = [
    { scope: "eosio" },
    { scope: "eosio.token" },
    { scope: "eosio.vpay" },
  ];

  if (!showItems) return;

  return (
    <>
      <div className="autocomplete-items">
        {keywords === "" && deferredItems.length === 0 ? (
          // <li>
          //   <a className="dropdown-item">No records</a>
          // </li>
          <>
            {intialItems.map((item, index) => (
              <div
                key={index}
                onMouseDown={() => {
                  setKeywords(item.scope);
                  navigate(`/account/${item.scope}`);
                }}
              >
                <Link
                  className="dropdown-item"
                  to={`/account/${item.scope}`}
                  //   onMouseDown={() => {
                  //     setKeywords(item.scope);
                  //   }}
                >
                  {item.scope}
                </Link>
              </div>
            ))}
          </>
        ) : null}
        {deferredItems.map((item, index) => (
          <div
            key={index}
            onMouseDown={() => {
              setKeywords(item.scope);
              navigate(`/account/${item.scope}`);
            }}
          >
            <Link
              className="dropdown-item"
              to={`/account/${item.scope}`}
              //   onMouseDown={() => {
              //     console.log(item);
              //     setKeywords(item.scope);
              //   }}
            >
              {item.scope}
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

const Search = () => {
  let [keywords, setKeywords] = useState("");
  const [items, setItems] = useState([]);
  const [showItems, setShowItems] = useState(false);
  const [currentFocus, setCurrentFocus] = useState(-1);
  let navigate = useNavigate();

  useEffect(() => {
    if (keywords === "") return;

    let search_keywords = keywords.toLowerCase();

    const timeoutId = setTimeout(() => {
      BlockchainApiManager.getTableByScope({
        code: "eosio",
        table: "userres",
        lower_bound: search_keywords,
        upper_bound: search_keywords.padEnd(12, "z"),
        limit: 10,
      }).then((res) => {
        setItems(res.rows);
      });
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [keywords]);

  useEffect(() => {
    if (keywords.indexOf("eosio.") === -1 && items.length === 1) {
      var scope = items[0].scope;
      setKeywords("");
      navigate(`account/${scope}`);
      window.$("input#dropdownMenuSearch").blur();
      setShowItems(false);
    }
  }, [items]);

  function handleChange(e) {
    setKeywords(e.target.value);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (new RegExp("^[0-9]*$").test(keywords)) {
        //Block
        navigate(`block/${keywords}`);
      } else if (new RegExp("^([a-z0-9]){60,}$", "i").test(keywords)) {
        //Txid
        navigate(`transaction/${keywords}`);
      } else if (
        new RegExp("^EOS([a-z0-9]){50,}$", "i").test(keywords) ||
        new RegExp("^PUB([a-z0-9_]){54,}$", "i").test(keywords)
      ) {
        //Eos public key
        navigate(`key/${keywords}`);
      } else if (new RegExp("^[a-z1-5.]*$", "i").test(keywords)) {
        //Account
        navigate(`account/${keywords}`);
        // BlockchainApiManager.getTableByScope({
        //   code: "eosio",
        //   table: "userres",
        //   lower_bound: keywords,
        //   upper_bound: keywords.padEnd(12, "z"),
        //   limit: 10,
        // }).then((res) => {
        //   setItems(res.rows);
        // });
      } else {
        setItems([
          { scope: "eosio" },
          { scope: "eosio.token" },
          { scope: "eosio.vpay" },
          { scope: "eosio.msig" },
        ]);
      }
    }
  };

  const handleBlur = (event) => {
    setShowItems(false);
  };

  const handleFocus = () => {
    setShowItems(true);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 40) {
      /*If the arrow DOWN key is pressed,
      increase the currentFocus variable:*/
      setCurrentFocus((currentFocus) => currentFocus + 1);
      /*and and make the current item more visible:*/
    } else if (e.keyCode === 38) {
      //up
      /*If the arrow UP key is pressed,
      decrease the currentFocus variable:*/
      setCurrentFocus((currentFocus) => currentFocus - 1);
      /*and and make the current item more visible:*/
    } else if (e.keyCode === 13) {
      /*If the ENTER key is pressed, prevent the form from being submitted,*/
      e.preventDefault();
      if (currentFocus > -1) {
        /*and simulate a click on the "active" item:*/
        //if (x) x[currentFocus].click();
        const scope = window
          .jQuery(
            window
              .jQuery("#dropdownMenuSearch ~ .autocomplete-items")
              .children()[currentFocus]
          )
          .text();
        setKeywords(scope);
        navigate(`/account/${scope}`);
        setCurrentFocus(-1);
        setShowItems(false);
      }
    }
  };

  useEffect(() => {
    const itemsLength = window
      .jQuery("#dropdownMenuSearch ~ .autocomplete-items")
      .children().length;

    window
      .jQuery("#dropdownMenuSearch ~ .autocomplete-items div")
      .removeClass("autocomplete-active");

    if (currentFocus >= itemsLength) setCurrentFocus(0);
    if (currentFocus < 0) setCurrentFocus(itemsLength - 1);

    window
      .jQuery(
        window.jQuery("#dropdownMenuSearch ~ .autocomplete-items").children()[
          currentFocus
        ]
      )
      .addClass("autocomplete-active");
  }, [currentFocus]);

  return (
    <>
      <div className="search_box">
        <div className="autocomplete" style={{ width: "100%" }}>
          <input
            id="dropdownMenuSearch"
            type="text"
            name="dropdownMenuSearch"
            placeholder="Search for account name, block, or transaction..."
            autoComplete="off"
            value={keywords}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onKeyUp={handleKeyPress}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />
          <SearchList
            items={items}
            setKeywords={setKeywords}
            keywords={keywords}
            showItems={showItems}
          />
        </div>
        <span className="close_search" />
      </div>
      <a href="#" className="open_search" />
    </>
  );
};

export default Search;
