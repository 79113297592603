import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";

const Tokens = ({ balances }) => {
  let params = useParams();
  // const { isLoading, error, data:balances } = useQuery(
  //   [`balances`, params.account],
  //   () => BlockchainApiManager.getBalances(params.account),
  //   {}
  // );

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Contract</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {!balances &&
            Array(5)
              .fill(0)
              .map((item, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              ))}
          {balances && balances.balances.length === 0 ? (
            <tr>
              <td colSpan={2}>No tokens in this account.</td>
            </tr>
          ) : null}
          {balances &&
            balances.balances &&
            [...balances.balances]
              .sort((a, b) => (a.currency > b.currency ? 1 : -1))
              .filter((balance) => balance.amount > 0)
              .map((balance, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/account/${balance.contract}`}>
                      {balance.contract}
                    </Link>
                  </td>
                  <td>
                    {balance.amount}&nbsp;{balance.currency}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
};

export default Tokens;
