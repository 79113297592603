import { useContext, useEffect, useRef, useState } from "react";
import { WalletContext } from "../../../app/contexts/wallet-context";
import PageTransition from "../../../app/animations/page-transition";
import config from "../../../app/config";
import { tokenFormat, tokenInputFormat } from "../../../app/utilities/format";
import Helmet from "react-helmet";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";
import { getPrecision } from "../../../app/utilities/utility";

const Transfer = () => {
  const wallet = useContext(WalletContext);
  const { user, walletAccount, refresh } = wallet;
  //const { balances } = wallet.walletAccount;
  const [balances, setBalances] = useState([]);
  const [formData, setFormData] = useState({
    from: "",
    to: "",
    quantity: 0,
    memo: "",
    custom_enabled: false,
    symbol: "", //config.token_symbol
    contract: "", //"eosio.token"
    decimals: "", //config.token_precision,
  });

  const selectizeRef = useRef();

  useEffect(() => {
    selectizeRef.current = window.$("#select-search").selectize({
      sortField: "text",
    });
  }, []);

  useEffect(() => {
    setFormData((formData) => ({ ...formData, from: user ? user.actor : "" }));
  }, [user]);

  useEffect(() => {
    if (formData.from === "") return;

    const timeoutId = setTimeout(() => {
      BlockchainApiManager.getBalances(formData.from).then((response) => {
        const balances = response.balances || [];
        setBalances(balances);
      });
    }, 400);

    return () => clearTimeout(timeoutId);
  }, [formData.from, refresh]);

  useEffect(() => {
    let balance = balances.find(
      (balance) =>
        balance.contract === "eosio.token" &&
        balance.currency === config.token_symbol
    );
    if (balance) {
      setFormData((formData) => ({
        ...formData,
        contract: balance.contract,
        symbol: balance.currency,
        decimals: balance.decimals,
      }));
    } else if (!balance && balances.length > 0) {
      balance = balances[0];
      setFormData((formData) => ({
        ...formData,
        contract: balance.contract,
        symbol: balance.currency,
        decimals: balance.decimals,
      }));
    } else if (!balance && balances.length === 0) {
      setFormData((formData) => ({
        ...formData,
        contract: "",
        symbol: "",
        decimals: "",
      }));
    }

    selectizeRef.current[0].selectize.destroy();
    var options = [];
    for (var item of [...balances]
      .sort((a, b) => (a.currency > b.currency ? 1 : -1))
      .filter((balance) => balance.amount > 0)) {
      options.push({
        value: `${item.contract}:${item.currency}`,
        text: `${item.amount} ${item.currency}`,
      });
    }

    selectizeRef.current = window.$("#select-search").selectize({
      sortField: "text",
      options: options,
      create: false,
      onChange: function () {
        // console.log(selectizeRef.current[0].selectize.getValue());
        var value = selectizeRef.current[0].selectize.getValue();

        const contract = value.split(":")[0];
        const currency = value.split(":")[1];

        const balance = balances.find(
          (balance) =>
            balance.contract === contract && balance.currency === currency
        );

        setFormData((formData) => ({
          ...formData,
          contract: balance?.contract,
          symbol: balance?.currency,
          decimals: balance?.decimals,
        }));
      },
    });

    if (formData.contract && formData.symbol) {
      selectizeRef.current[0].selectize.setValue(
        `${formData.contract}:${formData.symbol}`
      );
    }
    //console.log(options);
    //selectizeRef.current[0].selectize.refreshOptions();
  }, [balances]);

  useEffect(() => {
    if (formData.custom_enabled) {
      const balance = balances.find(
        (balance) =>
          balance.contract === formData.contract &&
          balance.currency === formData.symbol
      );
      if (!balance) {
        BlockchainApiManager.getCurrencyStats({
          code: formData.contract,
          symbol: formData.symbol,
        }).then((response) => {
          if (response && response[formData.symbol]) {
            var precision = getPrecision(response[formData.symbol].supply);

            setFormData((formData) => ({
              ...formData,
              decimals: precision,
            }));
          }
        });
      }
    }
  }, [formData.custom_enabled, formData.contract, formData.symbol]);

  const handleFullBalance = () => {
    const balance = balances.find(
      (balance) =>
        balance.contract === formData.contract &&
        balance.currency === formData.symbol
    );

    setFormData((formData) => ({ ...formData, quantity: balance.amount }));
  };

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "transfer";
    walletTransaction.account = {
      account: null,
      permission: "active",
    };
    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: formData.contract,
      name: "transfer",
      data: {
        from: formData.from,
        to: formData.to,
        quantity: `${tokenInputFormat(formData.quantity, formData.decimals)} ${
          formData.symbol
        }`,
        memo: formData.memo,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <Helmet>
        <title>Transfer Tokens | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="form_box tranfer_form active">
          <h2>Transfer Tokens</h2>
          <form>
            <div className="field_row">
              <div className="field_col">
                <label>From</label>
                <input
                  type="text"
                  placeholder="From"
                  value={formData.from}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      from: event.target.value.toLowerCase(),
                    })
                  }
                />
              </div>
              <div className="field_col">
                <label>To</label>
                <input
                  type="text"
                  placeholder="To"
                  value={formData.to}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      to: event.target.value.toLowerCase(),
                    })
                  }
                />
              </div>
            </div>
            <div className="field_row bottom_align">
              <div className="field_col">
                <label>Quantity</label>
                <input
                  type="number"
                  placeholder={0}
                  value={formData.quantity}
                  onChange={(event) =>
                    setFormData({ ...formData, quantity: event.target.value })
                  }
                />
                <span className="note" onClick={() => handleFullBalance()}>
                  Send entire balance
                </span>
              </div>
              <div className="field_col">
                {/* <label>To</label> */}
                <select
                  //className="cars"
                  id="select-search"
                  placeholder={`${Number(0).toFixed(config.token_precision)} ${
                    config.token_symbol
                  }`}
                  // onChange={(event) => {
                  //   const contract = event.target.value.split(":")[0];
                  //   const currency = event.target.value.split(":")[1];

                  //   const balance = balances.find(
                  //     (balance) =>
                  //       balance.contract === contract &&
                  //       balance.currency === currency
                  //   );

                  //   setFormData((formData) => ({
                  //     ...formData,
                  //     contract: balance.contract,
                  //     symbol: balance.currency,
                  //     decimals: balance.decimals,
                  //   }));
                  // }}
                  // value={`${formData.contract}:${formData.symbol}`}
                >
                  {/* {balances &&
                    [...balances]
                      .sort((a, b) => (a.currency > b.currency ? 1 : -1))
                      .filter((balance) => balance.amount > 0)
                      .map((balance, index) => (
                        <option
                          value={`${balance.contract}:${balance.currency}`}
                          key={index}
                        >
                          {balance.amount} {balance.currency}
                        </option>
                      ))} */}
                </select>
                <span
                  className="note toggle_tokens"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      custom_enabled: !formData.custom_enabled,
                    });
                  }}
                >
                  {formData.custom_enabled
                    ? "Hide custom token"
                    : "Use custom token"}
                </span>
              </div>
            </div>
            {formData.custom_enabled ? (
              <>
                <div className="custom_tokens">
                  <div className="field_row">
                    <div className="field_col">
                      <label>Symbol</label>
                      <input
                        type="text"
                        placeholder="Symbol"
                        value={formData.symbol}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            symbol: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="field_col">
                      <label>Contract</label>
                      <input
                        type="text"
                        placeholder="Contract"
                        value={formData.contract}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            contract: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className="field_row1">
              <label>Memo (optional)</label>
              <textarea
                placeholder="Memo"
                value={formData.memo}
                onChange={(event) =>
                  setFormData({ ...formData, memo: event.target.value })
                }
              />
            </div>
            <div className="field_row1">
              <input
                type="button"
                onClick={() => handleClick()}
                value={`${
                  formData.quantity > 0
                    ? `Transfer ${formData.quantity} ${formData.symbol}`
                    : `Transfer`
                }`}
              />
            </div>
          </form>
        </div>
      </PageTransition>
    </>
  );
};

export default Transfer;
