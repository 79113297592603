export let config_testnet = {
  network: "waxtest",
  network_type: "testnet",
  chain_id: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12",
  endpoints: {
    default: "https://api.waxtest.alohaeos.com",//https://api.waxtest.alohaeos.com
    sign: "https://wax-testnet.eosphere.io",//https://testnet.wax.eosdetroit.io
    state: {
      "https://wax-testnet.eosphere.io": 80,
      "https://waxtest.eosdac.io": 100,
    },
    block: {
      "https://api-testnet-wax.eosarabia.net": 90,
      // "https://api.waxtest.alohaeos.com": 100,
      "https://waxtest.eosdac.io": 100,      
    },
    transaction: {
      "https://wax-testnet.eosphere.io": 100,
      "https://api-testnet-wax.eosarabia.net": 100,
      // "https://api.waxtest.alohaeos.com": 100,
      "https://waxtest.eosdac.io": 100,      
    },
    history: {
      // "https://wax-testnet.eosphere.io": 100,
      // "https://api.waxtest.alohaeos.com": 100,
      "https://waxtest.eosdac.io": 100,
      "https://hyperion-waxtestnet.waxblock.io": 100,
    },
    transactionV2: {
      "https://wax-testnet.eosphere.io": 100,
      "https://api-testnet-wax.eosarabia.net": 100,
      // "https://api.waxtest.alohaeos.com": 100,
      "https://waxtest.eosdac.io": 100,      
    },
    historyV2: {
      "https://wax-testnet.eosphere.io": 100,
      // "https://api.waxtest.alohaeos.com": 100,
      "https://waxtest.eosdac.io": 100,      
    },    
    lightapi: {
      "https://testnet-lightapi.eosams.xeos.me": 100,
    },
    nfts: {
      simpleassets: null,
      atomicassets: null,
    },
  },
  socket: {
    url: process.env.REACT_APP_TESTNET_SOCKET_URL || "http://127.0.0.1:8080",
    path: "/socket",
  },
  api: {
    url: process.env.REACT_APP_TESTNET_API_URL || "http://127.0.0.1:8080",
    path: "/api/v1",
  },
};

export default config_testnet;
