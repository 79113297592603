import { useState } from "react";
import BlockchainApiManager from "../../../../app/services/blockchainApiManager";

const RequestedApprovals = ({ formData, setFormData }) => {
  const [autocompleteRequestedActors, setAutocompleteRequestedActors] =
    useState([[]]);
  const [requestedPermissions, setRequestedPermissions] = useState([[]]);

  const addRequested = () => {
    formData.requested.push({ actor: "", permission: "" });
    autocompleteRequestedActors.push([]);
    requestedPermissions.push([]);
    setFormData({ ...formData });
  };

  const removeRequested = (index) => {
    formData.requested.splice(index, 1);
    autocompleteRequestedActors.splice(index, 1);
    requestedPermissions.splice(index, 1);
    setFormData({ ...formData });
  };

  const autocompleteRequestedActor = (index) => {
    if (
      !new RegExp("^[a-z1-5.]{1,12}$", "i").test(
        formData.requested[index].actor
      )
    ) {
      autocompleteRequestedActors[index] = [];
      setAutocompleteRequestedActors([...autocompleteRequestedActors]);
      return;
    }

    var keywords = formData.requested[index].actor;

    if (keywords === "") {
      keywords = "eosio";
    }

    BlockchainApiManager.getTableByScope({
      code: "eosio",
      table: "userres",
      lower_bound: keywords,
      upper_bound: keywords.padEnd(12, "z"),
      limit: 10,
    }).then((res) => {
      autocompleteRequestedActors[index] = [];
      if (res && res.rows) {
        res.rows.forEach((item, key) => {
          autocompleteRequestedActors[index].push({ scope: item.scope });
        });
      }

      
      setAutocompleteRequestedActors([...autocompleteRequestedActors]);
    });
  };

  const selectRequestedActor = (index, requestedActor) => {
    if (requestedActor) {
      formData.requested[index].actor = requestedActor.scope;
    }
    setFormData({ ...formData });

    requestedPermissions[index] = [];

    var keywords =  formData.requested[index].actor;
    keywords = keywords.toLowerCase();
    BlockchainApiManager.getAccount(keywords).then(
      (res) => {
        //autocompleteRequestedActors[index] = [];
        requestedPermissions[index] = [];
        res.permissions.forEach((permission, key) => {
          requestedPermissions[index].push(permission);
        });

        setRequestedPermissions([...requestedPermissions]);
      }
    );
  };

  const loadRequestedActorPermissions = (index) => {
    var keywords =  formData.requested[index].actor;
    keywords = keywords.toLowerCase();

    BlockchainApiManager.getAccount(keywords).then(
      (res) => {
        requestedPermissions[index] = [];
        res.permissions.forEach((permission, key) => {
          requestedPermissions[index].push(permission);
        });

        setRequestedPermissions([...requestedPermissions]);
      }
    );
  }

  return (
    <>
      <div className="field_row req_appr">
        <label>Request Approvals</label>
        {formData.requested && formData.requested.map((requested, index) => (
          <div className="field_row_inner del_row" key={index}>
            <div className="field_col autocomplete">
              <input
                type="text"
                name
                placeholder="Actor"
                value={requested.actor}
                onChange={(event) => {
                  formData.requested[index].actor = event.target.value.toLowerCase();
                  setFormData({ ...formData });
                  //autocompleteRequestedActor(index);
                  //loadRequestedActorPermissions(index)
                }}
                // onBlur={() => {
                //   formData.requested[
                //     index
                //   ].showAutocompleteRequestedActors = false;
                //   setFormData({ ...formData });
                // }}
                // onFocus={() => {
                //   formData.requested[
                //     index
                //   ].showAutocompleteRequestedActors = true;
                //   setFormData({ ...formData });
                // }}
              />
              {/* {formData.requested[index].showAutocompleteRequestedActors ? (
                <div className="autocomplete-items">
                  {autocompleteRequestedActors[index] &&
                    autocompleteRequestedActors[index].map((item, key) => (
                      <div
                        key={key}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          selectRequestedActor(index, item);
                          formData.requested[
                            index
                          ].showAutocompleteRequestedActors = false;
                          setFormData({ ...formData });
                        }}
                      >
                        {item.scope}
                      </div>
                    ))}
                </div>
              ) : null} */}
            </div>
            <div className="field_col">
            <input
                type="text"
                name
                placeholder="Permission"
                value={requested.permission}
                onChange={(event) => {
                  formData.requested[index].permission = event.target.value;
                  setFormData({ ...formData });
                }}
                />
              {/* <select
                className="cars"
                onChange={(event) => {
                  formData.requested[index].permission = event.target.value;
                  setFormData({ ...formData });
                }}
              >
                <option value="">--Select--</option>
                {requestedPermissions[index] &&
                  requestedPermissions[index].map((permission, key) => (
                    <option key={key} value={permission.perm_name}>
                      {permission.perm_name}
                    </option>
                  ))}
              </select> */}
            </div>
            <div className="field_col del_col">
              <input
                type="button"
                className="delete_req"
                defaultValue="Delete"
                onClick={() => removeRequested(index)}
              />
            </div>
          </div>
        ))}
        <input
          type="button"
          className="clone_req"
          value="Add NEW +"
          onClick={addRequested}
        />
      </div>
    </>
  );
};

export default RequestedApprovals;
