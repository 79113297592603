import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect } from "react";
import { useRef } from "react";

import { useState } from "react";
import { tokenFormat } from "../../../app/utilities/format";

const AvgInflationChart = ({ inflationStat }) => {
  const chartRef = useRef(null);

  // let avgInflation =
  //   (inflationStat &&
  //     tokenFormat(inflationStat.avg_inflation / Math.pow(10, 4), 2)) ||
  //   0;

  const [options, setOptions] = useState({
    chart: {
      type: "gauge",
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      backgroundColor: "#0a0928",
    },
    plotOptions: {
      gauge: {
        dataLabels: {
          color: "#000000",
          borderColor: null,
          borderWidth: 0,
          padding: 0,
          borderRadius: 0,
          style: {
            fontWeight: "lighter",
            fontSize: "14px",
          },
        },
        dial: {
          backgroundColor: "#000000",
        },
        pivot: {
          backgroundColor: "#000000",
        },
      },
    },

    title: {
      text: "",
    },
    navigator: {
      enabled: false,
    },
    exporting: { enabled: false },
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, "#b2b5c1"],
              [1, "#e2e3e7"],
            ],
          },
          borderWidth: 0,
          outerRadius: "109%",
        },
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, "#e2e3e7"],
              [1, "#b2b5c1"],
            ],
          },
          borderWidth: 1,
          outerRadius: "107%",
        },
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 0 },
            stops: [[0, "#fdfdfd"]],
          },
        },
        {
          backgroundColor: "#fff",
          borderWidth: 0,
          outerRadius: "105%",
          innerRadius: "103%",
        },
      ],
    },

    yAxis: {
      min: -6,
      max: 6,

      minorTickInterval: "auto",
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: "inside",
      minorTickColor: "#222",

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: "inside",
      tickLength: 5,
      tickColor: "#222",
      labels: {
        step: 2,
        rotation: "auto",
      },
      title: {
        text: "",
      },
      plotBands: [
        {
          from: -6,
          to: 0,
          color: "#55BF3B",
        },
        // {
        //   from: 0,
        //   to: 5,
        //   color: "#DDDF0D",
        // },
        {
          from: 0,
          to: 6,
          color: "#DF5353",
        },
      ],
    },
    credits: {
      enabled: false
    },

    series: [
      {
        name: "",
        data: [0],
        tooltip: {
          valueSuffix: "%",
        },
        dataLabels: {
          enabled: false,
          format: '{y:.2f}%', // Set the number of decimals here
        },
      },
    ],
  });

  useEffect(() => {
    if (!inflationStat) return;
    let avgInflation = tokenFormat(
      inflationStat.avg_inflation / Math.pow(10, 4),
      2
    );
  
    // chartRef.current.chart.setTitle(avgInflation);
    chartRef.current.chart.series[0].setData([parseFloat(avgInflation)]);
    chartRef.current.chart.redraw();
  }, [inflationStat]);

  return (
    <>
      <figure>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          containerProps={{ id: "containerspeedo" }}
          ref={chartRef}
        />

        <figcaption>Average inflation</figcaption>
      </figure>
    </>
  );
};

export default AvgInflationChart;
