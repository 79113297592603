import AnchorLink from "anchor-link";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import config from "../../config";

const AnchorLinkService = {
  provider: "anchor-link",
  link: null,
  appName: config.anchorLink.app_name,
  session: null,
  init(endpoint) {
    this.link = new AnchorLink({
      transport: new AnchorLinkBrowserTransport(),
      chains: [
        {
          chainId:
            config.chain_id ||
            "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
          nodeUrl: endpoint || "https://wax.greymass.com",
        },
      ],
    });
  },
  connect() {
    const _self = this;
    return new Promise(function (resolve, reject) {
      if (_self.link) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  },
  login() {
    return this.link.login(this.appName);
  },
  restoreSession(session) {
    return this.link.restoreSession(
      this.appName,
      session?.auth,
      session?.chainId
    );
  },
  listSessions() {
    return this.link.listSessions(this.appName);
  },
  transaction(walletTransaction) {
    var actions = walletTransaction.actions;
    var account = walletTransaction.account;

    var authorization = [
      {
        actor: account.account,
        permission: account.permission,
      },
    ];

    actions.forEach(function (action) {
      //Check if owner permission needed
      if (action.name === "updateauth" && action.data.permission === "owner") {
        authorization = [
          {
            actor: account.account,
            permission: "owner",
          },
        ];
      }
      action.authorization = action.authorization
        ? action.authorization
        : authorization;
    });

    return (this.session || this.link).transact(
      { actions: actions },
      {
        broadcast: true,
        blocksBehind: 3,
        expireSeconds: 60 * 3,
      }
    );
  },
};

export default AnchorLinkService;
