import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import PageTransition from "../../../../app/animations/page-transition";
import config from "../../../../app/config";
import ApiManager from "../../../../app/services/apiManager";
import {
  randomNoImage,
  randomNoImageByName,
} from "../../../../app/utilities/random";

const Proxies = React.memo(() => {
  //const [proxies, setProxies] = useState(proxiesData);
  const [currentItems, setCurrentItems] = useState([]);

  const {
    //  isLoading,
    // error,
    data: proxies,
    refetch,
  } = useQuery([`proxies`], () => ApiManager.getProxies(), { enabled: false });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refetch();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  const itemsPerPage = 100;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (!proxies) return;
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
   
    //setCurrentItems(proxies.slice(itemOffset, endOffset));
    setCurrentItems((currentItems) => [
      ...currentItems,
      ...proxies.slice(itemOffset, endOffset),
    ]);
    setPageCount(Math.ceil(proxies.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, proxies]);

  // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % proxies.length;

  //   setItemOffset(newOffset);
  // };
  const handlePageClick = (page) => {
    setPage(page);
    const newOffset = ((page - 1) * itemsPerPage) % proxies.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <PageTransition>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Account</th>
              <th>Total WAX</th>
              <th>Proxied Accounts</th>
              <th>Voting For</th>
            </tr>
          </thead>
          <tbody>
            {!proxies &&
              Array(5)
                .fill(0)
                .map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
            {currentItems.map((proxy, index) => (
              <tr key={index}>
                <td>{proxy.rank}</td>
                <td>
                  <div style={{ display: "inline-flex" }}>
                    <img
                      src={`${
                        proxy.logo_256
                          ? `${config.imagekit.default_url_endpoint}/tr:w-50/${proxy.logo_256}`
                          : `/${randomNoImageByName(proxy.account)}`
                      }`}
                      alt=""
                      // width={30}
                      // height={30}
                      style={{ width: "30px" }}
                    />
                    <Link
                      to={`/account/${proxy.account}`}
                      style={{ position: "relative", left: "10px" }}
                    >
                      {proxy.account}
                    </Link>
                  </div>
                </td>
                <td>{proxy.proxied_eos.toLocaleString()}</td>
                <td>{proxy.vote_count.toLocaleString()}</td>
                <td>{proxy.producer_count.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {page + 1 <= pageCount ? (
          <div style={{ textAlign: "center" }}>
            <a
              href="#"
              className="more_btn"
              onClick={(e) => {
                e.preventDefault();
                handlePageClick(page + 1);
              }}
            >
              view more
            </a>
          </div>
        ) : null}
        {/* <ReactPaginate
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          className={"pagination"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
        /> */}
      </PageTransition>
    </>
  );
});

export default Proxies;
