import Helmet from "react-helmet";
import PageTransition from "../../../app/animations/page-transition";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useContext } from "react";
import { WalletContext } from "../../../app/contexts/wallet-context";

const CreateAccount = () => {
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>Create Account | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="form_box permission_form active">
          <ul className="form_tabs">            
            <li
              className={`nav-link ${
                ["/wallet/create-account","/wallet/create-account/advanced"].includes(location.pathname)
                  ? "active"
                  : ""
              }`}
            >
              <Link to="/wallet/create-account/advanced">Advanced</Link>
            </li>
          </ul>
          <Outlet />
        </div>
       
      </PageTransition>
    </>
  );
};

export default CreateAccount;
