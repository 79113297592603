import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";
import config from "../../../app/config";
import { WalletContext } from "../../../app/contexts/wallet-context";
import ApiManager from "../../../app/services/apiManager";
import {
  randomNoImage,
  randomNoImageByName,
} from "../../../app/utilities/random";
//import proxiesData from "../../../dummy-data/proxies.json";

const Proxies = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;
  //const [proxies, setProxies] = useState(proxiesData);
  const [voteProxy, setVoteProxy] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);

  const {
    //  isLoading,
    // error,
    data: proxies,
    refetch,
  } = useQuery([`proxies`], () => ApiManager.getProxies(), { enabled: false });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refetch();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    setVoteProxy(wallet.walletAccount?.account?.voter_info?.proxy || null);
  }, [wallet.walletAccount]);

  const handleClick = () => {
    var walletTransaction = {};
    walletTransaction.type = "voteproducer";

    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "voteproducer",
      data: {
        voter: user ? user.actor : "",
        proxy: voteProxy,
        producers: [],
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  const itemsPerPage = 100;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (!proxies) return;
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //setCurrentItems(proxies.slice(itemOffset, endOffset));
    setCurrentItems((currentItems) => [
      ...currentItems,
      ...proxies.slice(itemOffset, endOffset),
    ]);
    setPageCount(Math.ceil(proxies.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, proxies]);

  // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % proxies.length;

  //   setItemOffset(newOffset);
  // };
  const handlePageClick = (page) => {
    setPage(page);
    const newOffset = ((page - 1) * itemsPerPage) % proxies.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <Helmet>
        <title>Vote Proxies | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div id="proxies" className="forms_type active">
          <h3>
            Selected Proxy {voteProxy}
            <input
              type="button"
              className="side_btn"
              value="VOTE"
              onClick={() => handleClick()}
            />
            {/* <span class="select-ct">(1 Selected)</span> */}
          </h3>

          <div className="proxies_inner">
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Rank</th>
                  <th>Account</th>
                  <th>Total WAX</th>
                  <th>Proxied Accounts</th>
                  <th>Voting For</th>
                </tr>
              </thead>
              <tbody>
                {!proxies &&
                  Array(5)
                    .fill(0)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    ))}
                {currentItems.map((proxy, index) => (
                  <tr key={index}>
                    <td>
                      <span className="radiobtn">
                        <input
                          type="radio"
                          name="voterProxy"
                          id={proxy.account}
                          checked={voteProxy === proxy.account ? true : false}
                          onClick={(event) => {
                            setVoteProxy(proxy.account);
                          }}
                        />
                        <label for={proxy.account}>Proxy</label>
                      </span>
                    </td>
                    <td>{proxy.rank}</td>
                    <td>
                      <img
                        src={`${
                          proxy.logo_256
                            ? `${config.imagekit.default_url_endpoint}/tr:w-50/${proxy.logo_256}`
                            : `/${randomNoImageByName(proxy.account)}`
                        }`}
                        alt=""
                        // width={30}
                        // height={30}
                        style={{ width: "30px" }}
                      />
                      <Link
                        to={`/account/${proxy.account}`}
                        style={{ position: "relative", left: "10px" }}
                      >
                        {proxy.account}
                      </Link>
                    </td>
                    <td>{proxy.proxied_eos.toLocaleString()}</td>
                    <td>{proxy.vote_count.toLocaleString()}</td>
                    <td>{proxy.producer_count.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {page + 1 <= pageCount ? (
              <div style={{ textAlign: "center" }}>
                <a
                  href="#"
                  className="more_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePageClick(page + 1);
                  }}
                >
                  view more
                </a>
              </div>
            ) : null}
            {/* <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            className={"pagination"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          /> */}
          </div>
        </div>
      </PageTransition>
    </>
  );
};

export default Proxies;
